import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss']
})
export class ScheduleDialogComponent implements OnInit {
  classSchedule: any;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRefDialog: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('ddddddddddddddd', data);

  }

  ngOnInit() {
    this.classSchedule = JSON.parse(this.data.class_schedule || '[]');
    console.log('asaaa', this.classSchedule);

  }

}
