import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebPopupComponent } from './web-popup.component';

@NgModule({
    imports: [CommonModule],
    declarations: [WebPopupComponent],
    exports: [WebPopupComponent]
})
export class WebPopupModule { }