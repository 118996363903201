import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
// import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import { AlertComponent } from './_components';
import { UpdatepasswordComponent } from './updatepassword/updatepassword.component';
import { SupportComponent } from './support/support.component';
import { ForgerpasswordComponent } from './forgerpassword/forgerpassword.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalModule } from './_modal';
import { Buisness2Component } from './buisness2/buisness2.component';
import { SmartAdRollComponent } from './smart-ad-roll/smart-ad-roll.component';
import { ThanksYouComponent } from './thanks-you/thanks-you.component';
import { CancelledComponent } from './cancelled/cancelled.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { MatIconModule } from '@angular/material/icon';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebFromComponent } from './web-from/web-from.component';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // HeaderComponent,
    FooterComponent,
    HomeComponent,
    AlertComponent,
    UpdatepasswordComponent,
    SupportComponent,
    ForgerpasswordComponent,
    LoaderComponent,
    Buisness2Component,
    SmartAdRollComponent,
    ThanksYouComponent,
    CancelledComponent,
    PageNotFoundComponent,
    WebFromComponent,
  ],
  imports: [
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    ModalModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPayPalModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
