import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BuisnessComponent } from './buisness.component';
import { BannerkickComponent } from './bannerkick/bannerkick.component';
import { EmailkickComponent } from './emailkick/emailkick.component';
import { LinkkickComponent } from './linkkick/linkkick.component';

import { BuisnesstrackingComponent } from './buisnesstracking/buisnesstracking.component';
import { BuisnesspixelComponent } from './buisnesspixel/buisnesspixel.component';

import { BuisnessRoutingModule } from './buisness-routing.module';
@NgModule({
  declarations: [
    BuisnessComponent,
    BannerkickComponent,
    EmailkickComponent,
    LinkkickComponent,
    BuisnesstrackingComponent,
    BuisnesspixelComponent
  ],
  imports: [
    CommonModule,
    BuisnessRoutingModule
  ]
})
export class BuisnessModule { }
