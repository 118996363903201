import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bannerkick',
  templateUrl: './bannerkick.component.html',
  styleUrls: ['./bannerkick.component.scss']
})
export class BannerkickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
