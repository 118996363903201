import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../../auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { DashboardupdateComponent } from './dashboardupdate/dashboardupdate.component';
import { AccountledgerComponent } from './accountledger/accountledger.component';
import { AccountoffersComponent } from './accountoffers/accountoffers.component';
import { AccountfaqComponent } from './accountfaq/accountfaq.component';
import { LedgerComponent } from './ledger/ledger.component';
import { AccountlinkComponent } from './accountlink/accountlink.component';
import { OfferDataComponent } from './offer-data/offer-data.component'

const routes: Routes = [
    {
        path: '', component: AccountComponent, children: [
            { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
            { path: 'updatedashboard', component: DashboardupdateComponent, canActivate: [AuthGuard]  },
            { path: 'accountoffers', component: AccountoffersComponent , canActivate: [AuthGuard] },
            { path: 'accountfaq', component: AccountfaqComponent , canActivate: [AuthGuard] },
            { path: 'ledger', component: LedgerComponent, canActivate: [AuthGuard] },
            { path: 'payout', component: AccountlinkComponent, canActivate: [AuthGuard] },
            { path: 'offer_data/:id/:name', component: OfferDataComponent, canActivate: [AuthGuard] },
        ]
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]

})
export class AccountRoutingModule { }
