import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_modal';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../../_service';
import { Loading } from '../../../loader/Loading';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  

  model_id: any;
  cardLists: any;
  response: any;
  response_invoice: any;
  invLists: any;
  response_subscription: any;
  subcriptionAndPaymentLists: any;
  orderId: any;
  getOrder: any;
  invoiceId: any;
  ownerId: any;
  name: any;
  cc_Id: any;
  offer_id: any;
  price_id: any
  datepics: any;
  newFormat: any;
  unit: any;
  productId: any;
  orderPaymentLists: any;
  card_message:any;
  cvv_message:any;
  year_message:any;
  constructor(
    private UserServiceService: UserServiceService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    Loading.hide();
    this.loginForm = this.formBuilder.group({
      //cardNo: ['', Validators.required,Validators.minLength(3)],
      cardNo: ['', Validators.required],
      year:['',Validators.required],
      month:['',Validators.required],
      cvv: ['',Validators.required],
     
    });

    //this.get_cardlist();
//this.get_invoicelist();
    this.get_subscriptionpayment();
    //this.get_payment();

    /* this.response_subscription={"status_code":200,"message":"Your invoice list.","data":{"code":0,"data":{"products":[{"quantity":"1","minQuantity":"1","maxQuantity":"99","total":"0.00","shipping":false,"tax":false,"price":[{"price":"0.00","payment_count":"1","unit":"month","id":"854956341914"}],"type":"subscription","quantityEditable":false,"index":"0","name":"SAR Monthly","id":"18","uid":"5b972d96-c449-20fa-4847-812464e73d18","product_type":null}],"shipping":null,"delay":"0","invoice_template":"4","subTotal":"0","grandTotal":"0","hasTaxes":false,"hasShipping":false,"paypalValid":false,"offerCoupon":false,"coupon":null,"shipping_charge_reoccurring_orders":false,"resorted":null,"currencyDecimalPlaces":"2","cc_id":"368","send_recurring_invoice":"0","offer_id":"420","order_id":"20","payment_next_date":"1592478000","status":"0","gateway_id":"8","affiliate_id":"0"},"account_id":194250},"status":200}
 */


    //console.log(typeof this.response_subscription);
    /* console.log( 'Subscription data', this.response_subscription);
    this.invLists = this.response_subscription.data.data;
     */
    //this.subcriptionAndPaymentLists=this.response_subscription.data.data;
    //console.log('Status test of subscriotion', this.invLists);


    this.orderId = localStorage.getItem('orderId');
  }

  get_cardlist() {
    this.UserServiceService.get_cardlist().pipe(first()).subscribe((data: any) => {
      this.cardLists = data.data.data;
    });
  }

/*  get_invoicelist() {
    this.UserServiceService.get_invoiceList().pipe(first()).subscribe((data: any) => {

      this.invLists = data.data.data;


    });


  }*/

  get_subscriptionpayment() {
    let objData = {
      "type": 1
    }
    this.UserServiceService.getTransactionsData(objData).pipe(first()).subscribe((data: any) => {
      console.log("sajfknfkndf",data);
      if (data.status == 200) {
        this.invLists = data.data;
        //this.subcriptionAndPaymentLists = data.data.reverse();
      }
      else {
        alert('Empty data of subscription');
        //this.subcriptionAndPaymentLists = null;
      }

    });
/*    this.UserServiceService.get_subcriptionAndPayment().pipe(first()).subscribe((data: any) => {
      if (data.status == 200) {
        this.subcriptionAndPaymentLists = data.data.data;
      }
      else {
        alert('Empty data of subscription');
        this.subcriptionAndPaymentLists = null;
      }

    });*/

  }

  get_payment() {
    this.UserServiceService.get_payment().pipe(first()).subscribe((data: any) => {
      this.orderPaymentLists = data.data;

    });


  }




  activeDefault(id) {
    this.UserServiceService.activeDefault_card({ 'id': id }).pipe(first()).subscribe((data: any) => {

      if (data.status_code == 200) {
        this.get_cardlist();
      }
    });


  }

  orderCancelSubmit(orderId) {
    //orderCancel
    this.UserServiceService.orderCancel_card({
      'orderId': orderId
    }).pipe(first()).subscribe((data: any) => {
      this.get_cardlist();
      //this.get_invoicelist();
      //this.get_subscriptionpayment();
      /* if (data.status_code == 200) {
        
      }  */
    });

  }
  payInvoice1(invoiceId) {
    this.UserServiceService.getSubcriptionAndPayment_card({
      'invoiceId': invoiceId
    }).pipe(first()).subscribe((data: any) => {
      //console.log('data of subscriptipon',data.data.data);
      //alert(data.data.data.products[0].name);
      if (data.status_code == 200) {
        this.getOrder = data.data.data;
        /* this.name = data.data.data.products[0].name; */
        this.invoiceId = invoiceId;
        this.productId = data.data.data.products[0].id;

        this.ownerId = data.data.data.products[0].owner;
        //alert(this.ownerId);
        this.cc_Id = data.data.data.cc_id;
        this.offer_id = data.data.data.offer_id;
        this.price_id = data.data.data.products[0].price[0].id;
        this.newFormat = new Date(data.data.data.payment_next_date * 1000);

      }
    });


  }

  payInvoiceData(invoiceId, cc_id, offer_id, price_id, product_Id, owner_Id, unit) {
    this.unit = unit;
    this.UserServiceService.orderupdate_card({
      'cc_id': cc_id, 'order_id': invoiceId, 'offer_id': offer_id, 'price_id': price_id, 'productId': product_Id, 'unit': this.unit
    }).pipe(first()).subscribe((data: any) => {
      this.get_cardlist();
      //this.get_invoicelist();
      this.get_subscriptionpayment();
      /* if (data.status_code == 200) {
        
      }  */
    });

  }

  selectChangeHandler(event: any) {
    this.unit = event.target.value;
  }


  changeYear(e) {
    const year = new Date().getFullYear();
    if(year <= e.target.value){
      this.year_message='';
    }else{
      this.year_message='Not valid';
    }
  }
    changeMonth(e) {
    const month = new Date().getMonth();
    if(month <= e.target.value){
      this.year_message='';
    }else{
      this.year_message='Not valid';
    }
  }
  open_card() {

    this.model_id = 'custom-modal-2';
    this.modalService.open(this.model_id);
  }

  closeModal() {
    this.model_id = 'custom-modal-2';
    this.modalService.close(this.model_id);

  }

  keyup_cvv(event)
  {

    if(!isNaN(Number((event))) && event.length <= 3 && event.length > 2)
    {
      this.cvv_message='';

    }
    else
    {
      this.cvv_message='Not valid';   
    }
  }

  keyup(event) {

    if(!isNaN(Number((event))) && event.length <= 16 && event.length > 15)
    {
      this.card_message='';  
    }
    else
    {
      this.card_message='Not valid';
     
    }
}
  /* add_card(cardNo, month, year, cvv) {
    
    this.UserServiceService.change_card({ 'cardNo': cardNo, 'cvv': cvv, 'month': month, year: year }).pipe(first()).subscribe((data: any) => {
      console.log('data of card', data);
      this.get_cardlist();
      /* if (data.status_code == 200) {
        
      }  */
    //});

  //} */

    get f() { return this.loginForm.controls; }
     userDetails() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const formData = {
      "cardNo": this.loginForm.get('cardNo').value,
      "year": Number(this.loginForm.get('year').value),
      "month": Number(this.loginForm.get('month').value),
      "cvv": this.loginForm.get('cvv').value
    }
    this.UserServiceService.change_card(formData).pipe(first()).subscribe((data: any) => {

      this.get_cardlist();
      this.closeModal();
      /* if (data.status_code == 200) {
        
      }  */
    });
  }

}
