import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { Loading } from '../../../loader/Loading';

@Component({
  selector: 'app-offer-data',
  templateUrl: './offer-data.component.html',
  styleUrls: ['./offer-data.component.scss']
})
export class OfferDataComponent implements OnInit {
  id: any;
  id_data: any;
  title: "Links";
  name: '';
  email_list: any;
  link_list: any;
  banner_list: any;
  banner_data: any;
  first_name: string;
  link_data: string;
  email_data: string;
  optid: any;
  dangerousVideoUrl: string;
  tagId1: any;
  reffercode:any;
  user_id:any;
  //data: any;
  constructor(private route: ActivatedRoute, private router: Router, private UserServiceService: UserServiceService) { }

  ngOnInit() {

    this.tagId1 = localStorage.getItem('tagId');

     if (this.tagId1 == 'false') {
      this.router.navigate(['/dashboard/course']);
    }

    this.optid = localStorage.getItem('optId');

    let user_details = JSON.parse(localStorage.getItem('user_details'));
    this.user_id = user_details.id;
    console.log("hhh",user_details);
    this.reffercode = user_details.reffercode;
    this.first_name = user_details.first_name;

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.name = params['name'];
      this.title = "Links";
      this.UserServiceService.get_link_details({ offer_id: this.id }).pipe(first()).subscribe((data: any) => {

        Loading.hide();
        if (data.status == 200) {
          this.id_data = '3';

          this.link_list = data.data;
        }
        else {
          this.id_data = '4';
          this.link_data = 'No data found';
        }
      });
    });
  }

  show_offer(id, list, title) {
    this.title = title;
    if (list == 1) {
      this.id_data = '3';
      this.UserServiceService.get_link_details({ offer_id: id }).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        if (data.status == 200) {


          this.link_list = data.data;
        }
        else {
          this.id_data = '4';
          this.link_data = 'No data found';
        }

      });

    }

    else if (list == 2) {
      this.id_data = '2';
      this.UserServiceService.get_banner_details({ offer_id: id }).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.banner_data = data.data;

        }
        else {
          this.id_data = '5';
          this.banner_list = 'No data found';
        }

      });
    }


    else if (list == 3) {

      this.UserServiceService.get_offer_details({ offer_id: id }).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.id_data = '1';
          this.email_list = data.data;

        }
        else {
          this.id_data = '6';
          this.email_data = 'No data found';
        }
      });

    }
  }


}
