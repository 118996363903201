import { Component, OnInit } from '@angular/core';
import { smartAdRoll } from "../html-json/smartAdRoll";
import { FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserServiceService } from "../_service";
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { render } from 'creditcardpayments/creditCardPayments';
import { Loading } from '../loader/Loading';
// import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-smart-ad-roll',
  templateUrl: './smart-ad-roll.component.html',
  styleUrls: ['./smart-ad-roll.component.scss']
})
export class SmartAdRollComponent implements OnInit {

  submitForm: FormGroup;
  submitted = false;

  mainData: any;
  leftSectionData: any;
  rightSection: any
  headerImage: any;
  headerMail: any;
  headertitle: any;
  headersubtitle: any;
  headersubimage: any;
  leftSectionTitle: any;
  leftSectionUlLi_1: any;
  leftSectionUlLi_2: any;
  leftSectionUlLi_3: any;
  leftSectionUlLi_4: any;
  leftSectionUlLi_5: any;
  leftSectionUlLi_6: any;
  leftSectionUlLi_7: any;
  leftSectionImage_1: any;
  leftSectionImage_2: any;
  leftSectionImage_3: any;
  leftSection100: any;
  leftSectioncommentImg_1: any
  leftSectioncommentImg_2: any
  step_1_image: any
  step_2_image: any
  step_3_image: any
  step_4_image: any
  stripe_image: any
  paypal_image: any
  data_get: any
  offer_description: any
  offer_image: any
  offer_title: any
  offers_title:any;
  offers_description:any
  supportEmail:any
  body_title:any
  body_description:any
  bodyOtherInfo:any

/*--------------------------------start code---------------------------------*/
  payment_method: any;
  price: any;
  offer_id: any;
  reffer_code: any;
  user_id: any;
  email:any;
  token: any;
  amount: any;
  message:any;
  countries:any = [];
  states:any = [];
  paypalButtonActive:boolean = false;
  marginAdd:any = 0;
  myPaypalButtons:any = "none";
  succesful:string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
  cancel:string = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NWY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adRsscs";
  keystripe:string;
  keypaypal:string;
/*----------------------------------end code---------------------------------*/

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private UserServiceService: UserServiceService,
    private fb: FormBuilder,
    private router: Router,

  )
  {

    this.ActivatedRoute.queryParamMap.subscribe((data: any) => {
      this.offer_id = data.params.offer_id;
      this.reffer_code = data.params.reffer;
      if(this.offer_id == undefined){
        alert("Offer id is not valid");
        return;
      }else if(this.reffer_code == undefined){
        alert("Reffer code is not valid");
        return;
      }else{

      }
    });
  }


  handler: any = null;

  ngOnInit() {

    this.getCountries();
    this.submitForm = this.fb.group({
      firstName: ['',Validators.required],
      lastName: ['',Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneno: ['',Validators.required],
      billingaddress: ['',Validators.required],
      billingadd2: ['',Validators.required],
      billingcity: ['',Validators.required],
      billingstate: ['',Validators.required],
      billingcountry: ['',Validators.required],
      billingzipcode: ['',Validators.required],
      cardName: ['',Validators.required],
      cardNumber: ['',[ Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
      expMonth: ['',Validators.required],
      expYear: ['',Validators.required],
      cvv: ['',[ Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      method: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    })
    this.mainData = smartAdRoll.header[0]  // main Header
    this.leftSectionData = smartAdRoll.leftSection[0]
    this.rightSection = smartAdRoll.rightSection[0]
    this.headerImage = this.mainData.headerImage
    this.headerMail = this.mainData.headerMail
    this.headertitle = this.mainData.headertitle
    this.headersubtitle = this.mainData.headersubtitle
    this.headersubimage = this.mainData.headersubimage
    this.leftSectionTitle = this.leftSectionData.leftSectionTitle
    this.leftSectionUlLi_1 = this.leftSectionData.leftSectionUlLi_1
    this.leftSectionUlLi_2 = this.leftSectionData.leftSectionUlLi_2
    this.leftSectionUlLi_3 = this.leftSectionData.leftSectionUlLi_3
    this.leftSectionUlLi_4 = this.leftSectionData.leftSectionUlLi_4
    this.leftSectionUlLi_5 = this.leftSectionData.leftSectionUlLi_5
    this.leftSectionUlLi_6 = this.leftSectionData.leftSectionUlLi_6
    this.leftSectionUlLi_7 = this.leftSectionData.leftSectionUlLi_7
    this.leftSectionImage_1 = this.leftSectionData.leftSectionImage_1
    this.leftSectionImage_2 = this.leftSectionData.leftSectionImage_2
    this.leftSectionImage_3 = this.leftSectionData.leftSectionImage_3
    this.leftSection100 = this.leftSectionData.leftSection100
    this.leftSectioncommentImg_1 = this.leftSectionData.leftSectioncommentImg_1
    this.leftSectioncommentImg_2 = this.leftSectionData.leftSectioncommentImg_2
    this.step_1_image = this.rightSection.step_1_image
    this.step_2_image = this.rightSection.step_2_image
    this.step_3_image = this.rightSection.step_3_image
    this.step_4_image = this.rightSection.step_4_image
    this.stripe_image = this.rightSection.stripe_image
    this.paypal_image = this.rightSection.paypal_image
    this.getOfferById(this.offer_id);


  }
  getCountries(){
    this.UserServiceService.get_country_list().pipe(first()).subscribe((data: any) => {
      if(data.status == 200){
        this.countries = data.data;
      }else{
        this.countries = [];
      }
    });
  }
  getOfferById(id){
    this.UserServiceService.getOfferById(id).pipe(first()).subscribe((data: any) => {
      console.log("helloangel",data);
      this.offers_title = data.data.header_title;
      this.offers_description=data.data.header_description;
      this.supportEmail=data.data.supportEmail;
      this.body_title=data.data.body_title;
      this.body_description=data.data.body_description;
      this.bodyOtherInfo=data.data.bodyOtherInfo;
      this.payment_method = data.data.payment_method;
      this.price = data.data.price;
      if(data.data.stripe.id != 1 && data.data.stripe.payment_mode == "Test"){
        console.log("stripe");
        this.keystripe = data.data.stripe.payment_test_pb_key;
      }else{
        this.keystripe = data.data.stripe.payment_live_pb_key;
      }
      if(data.data.paypal.id != 1 && data.data.paypal.payment_mode == "Test"){
        console.log("paypal");
        this.keypaypal = data.data.paypal.payment_test_key;
      }else{
        this.keypaypal = data.data.paypal.payment_live_key;
      }
      this.loadStripe(this.keystripe);
    });
  }
  selectChangeHandler(event: any) {

    this.submitForm.setValue({
      firstName: "user",
      lastName: "user",
      email: "user@trafficexpertsacademy.com",
      phoneno: "9898990909",
      billingaddress: "10 Omgurudev plaza badi bhamori indore",
      billingadd2: "10 Omgurudev plaza badi bhamori indore",
      billingcity: "indore",
      billingstate: "1",
      billingcountry: event.target.value,
      billingzipcode: "989822",
      cardName: "visa",
      cardNumber: "4242424242424242",
      expMonth: "4",
      expYear: "22",
      cvv: "123",
      method: "stripe",
      acceptTerms: false

    });
    this.getStates(event.target.value);
  }

  getStates(id){
    this.UserServiceService.api_get_states(id).pipe(first()).subscribe((data: any) => {
      if(data.status == 200){
        this.states = data.data;
      }else{
        this.states = [];
      }
    });
  }

  get f() { return this.submitForm.controls; }

  getformdata(){

    this.submitted = true;
    if (this.submitForm.invalid) {
      return;
    }
    const ObjData = {
      firstname:this.submitForm.value.firstName,
      lastname : this.submitForm.value.lastName,
      email:this.submitForm.value.email,
      officephone:this.submitForm.value.phoneno,
      address1:this.submitForm.value.billingaddress,
      address2:this.submitForm.value.billingadd2,
      country:this.submitForm.value.billingcountry,
      city:this.submitForm.value.billingcity,
      state:this.submitForm.value.billingstate,
      zip:this.submitForm.value.billingzipcode,
      method: this.submitForm.value.method,
      reffercode: this.reffer_code,
      offer_id: this.offer_id
    }
    this.UserServiceService.postOfferForm(ObjData).pipe(first()).subscribe((data: any) => {
      console.log("data angel",data);
      Loading.show();
      if (data.status_code == 200) {
        this.email = data.data.email;
        this.user_id = data.data.id;
        if(data.data.method == "stripe"){
          this.amount = 20;
          this.pay();
        }
        else
        {
          this.amount = 20;
          this.myPaypalButtons = "block";
          this.paypalButtonActive = true;
          Loading.hide();
          this.paypalCall();
          this.marginAdd =  15;
        }
        //alert(data.message);
      }
    });

  }

  loadStripe(key) {
   console.log("this.keystripe",key);
    if(!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(key);
      }

      window.document.body.appendChild(s);
    }
  }

  /* stripe payment gateway method success finction*/
  pay() {
    if(!window['Stripe']) {
      alert('Oops! Stripe did not initialize properly.');
      return;
    }

    (<any>window).Stripe.card.createToken({
      name: this.submitForm.value.email,
      number: this.submitForm.value.cardNumber,
      exp_month: this.submitForm.value.expMonth,
      exp_year: this.submitForm.value.expYear,
      cvc: this.submitForm.value.cvc
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        console.log("response.card.id",response.card.id);
        this.transaction(response.id);
      } else {
        console.log("else response");
        this.message = response.error.message;
       // alert(this.message);
      }
    });
  }

  /* paypal payment gateway method success finction*/
  paypalCall(){
    render({
      id:"#myPaypalButtons",
      currency: "USD",
      value : "100.00",
      onApprove: (details)=>{
        console.log("transaction succesful",details);
        if(details.status == "COMPLETED"){
          this.transaction(details.purchase_units[0].payments.captures[0].id);
        }else{
          alert("transaction failed");
        }
       // alert("transaction succesful");
      }
    });
  }

  /* Backend server transaction and commission update api request */
  transaction(token) {
    const objectData = {
      token: token,
      reffercode: this.reffer_code,
      offer_id: this.offer_id,
      user_id: this.user_id,
      amount: this.price,
      method: this.submitForm.value.method
    }
    this.UserServiceService.transactionPostApi(objectData).pipe(first()).subscribe((data: any) => {
      console.log("data transaction succesful",data);
      if (data.status_code == 200) {
        Loading.hide();
        console.log("data succesful 200");
        this.router.navigate(['thanks']).then(() => {
          window.location.reload();
        });
        //alert(data.message);
      }else{
        this.router.navigate(['cancel']).then(() => {
          window.location.reload();
        });
        console.log("data else");
      }
    });
    return;
  }
}
