import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video.component';
import { EmailvideoComponent } from './emailvideo/emailvideo.component';
import { BannervideoComponent } from './bannervideo/bannervideo.component';
import { LinkvideoComponent } from './linkvideo/linkvideo.component';
import { VideotrackingComponent } from './videotracking/videotracking.component';
import { VideopixelComponent } from './videopixel/videopixel.component';

import { VideoRoutingModule } from './video-routing.module';

@NgModule({
  declarations: [
    VideoComponent,
    EmailvideoComponent,
    BannervideoComponent,
    LinkvideoComponent,
    VideotrackingComponent,
    VideopixelComponent
  ],
  imports: [
    CommonModule,
    VideoRoutingModule
  ]
})
export class VideoModule { }
