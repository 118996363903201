import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { DashboardupdateComponent } from './dashboardupdate/dashboardupdate.component';
import { AccountledgerComponent } from './accountledger/accountledger.component';
import { AccountoffersComponent } from './accountoffers/accountoffers.component';
//import { AccountlinkComponent } from './accountlink/accountlink.component';
import { AccountpaymentComponent } from './accountpayment/accountpayment.component';
import { AccountfaqComponent } from './accountfaq/accountfaq.component';
import { LedgerComponent } from './ledger/ledger.component';
import { AccountlinkComponent } from './accountlink/accountlink.component';
import { OfferDataComponent } from './offer-data/offer-data.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AccountComponent,
    DashboardupdateComponent,
    AccountoffersComponent,
    AccountledgerComponent,
    AccountpaymentComponent,
    AccountfaqComponent,
    LedgerComponent,
    AccountlinkComponent,
    OfferDataComponent

  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
   /* ReactiveFormsModule
 */  ]
})
export class AccountModule { }
