import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserServiceService, AlertService } from 'src/app/_service';

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss']
})
export class NewCourseComponent implements OnInit {
  courseList: any;
  contactId: any;
  classSort: string;

  constructor(
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.contactId = localStorage.getItem('contactId');
    console.log('this.userDetailsLocal', this.contactId);

    this.userDetails('');
  }

  classFilter(e: any) {
    this.classSort = e.value;
    this.userDetails(this.classSort)
  }
  userDetails(e) {
    this.classSort = e;
    if (this.classSort == undefined) {
      this.classSort = '';
    }
    else if (this.classSort == 'all') {
      this.classSort = '';
    }
    let keySend = {
      learning_type: 'Courses',
      sort_by: this.classSort,
    }

    this.UserServiceService.get_category(keySend).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.courseList = data.data;

      }
      else {

      }
    });
  }
  redirectLesson(data: any) {
    this.router.navigate(["/dashboard/course/" + data.id]);
    // this.router.navigate(['/dashboard/course-module/' + data.id]);
  }
}
