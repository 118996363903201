import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserServiceService, AlertService } from './_service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private UserServiceService: UserServiceService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.UserServiceService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        //return false;
        let data1 = localStorage.getItem('user_details');
        if (data1 == null) {
          {
            this.router.navigate(['/login']);
            return false;
          }
        }
        //this.isLoggedIn.next(true);
        return true;
      })
    );
  }
}


/* import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private routes: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let details = JSON.parse(localStorage.getItem('user_details'));
    console.log('userdetails', details);
    if (details != null) {
      console.log('Valid User');
      return false;
    }
    else {
      console.log('Invalid User');
      this.routes.navigate(['/register']);
      return false;
    }
  }
} */