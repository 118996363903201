import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PartnerComponent } from './partner.component';
import { BannerComponent } from './banner/banner.component';
import { EmailswipComponent } from './emailswip/emailswip.component';
import { PixelcodeComponent } from './pixelcode/pixelcode.component';
import { TrackingComponent } from './tracking/tracking.component';
/* import { LedgerComponent} from './ledger/ledger.component';
 */import { LinkComponent } from './link/link.component';

const routes: Routes = [
  {
    path: '', component: PartnerComponent, children:
      [
     /*    { path: 'partner', component: PartnerComponent },
        { path: 'banner', component: BannerComponent },
        { path: 'emailswipe', component: EmailswipComponent },
        { path: 'tracking', component: TrackingComponent },
        { path: 'pixelcode', component: PixelcodeComponent },
        { path: 'link', component: LinkComponent },
 */
      ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnerRoutingModule { }
