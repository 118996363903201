// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private assignmentSource = new BehaviorSubject<any>(null);
  assignment$ = this.assignmentSource.asObservable();

  setAssignment(assignment: any) {
    this.assignmentSource.next(assignment);
  }
}
