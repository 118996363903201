import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ProfileModule } from './profile/profile.module';
import { AccountModule } from './account/account.module';
import { PartnerModule } from './partner/partner.module';
import { BuisnessModule } from './buisness/buisness.module';
import { HtamModule } from './htam/htam.module';
import { VideoModule } from './video/video.module';
import { SidebarComponent } from './course/sidebar/sidebar.component';
import { ContentComponent } from './course/content/content.component';
import { DashboardComponent } from './dashboard.component';
import { CourseComponent } from './course/course.component';
import { ProductComponent } from './product/product.component';
import { CoursedetailsComponent } from './coursedetails/coursedetails.component';
import { CoachComponent } from './coach/coach.component';
import { AdvisorComponent } from './advisor/advisor.component';
import { OverviewComponent } from './overview/overview.component';
import { HeaderComponent } from "../header/header.component";
import { ScheduleCallModule } from "./scheduleCall/schedule-call.module";
// import { ModalModule } from '../_modal';

import { Buisness1Component } from './buisness1/buisness1.component';
import { PartnerdashboardComponent } from './partnerdashboard/partnerdashboard.component';
import { CoachVideo } from "./coach/coachVideo/coachVideo.component";
import { NotesComponent } from "./coach/Notes/notes.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebBuilder } from './web-builder/web-builder.component';
import { WebPopupModule } from "./web-builder-popup/web-popup.module";
import { Steps } from "./steps/steps.component";
import { NotesDescription } from "./coach/NotesDescription/notes-description.component";
import { NewCourseComponent } from './new-course/new-course.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatExpansionModule, MatGridListModule, MatInputModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatRadioModule, MatSelectModule, MatSortModule } from '@angular/material';
import { ModuleAndLessonComponent } from './new-course/module-and-lesson/module-and-lesson.component';
import { SharedService } from '../_service/shared.service';
import { NewClassComponent } from './new-class/new-class.component';
import { ClassComponent } from './class/class.component';
import { ContentClassComponent } from './class/content-class/content-class.component';
import { SidebarClassComponent } from './class/sidebar-class/sidebar-class.component';
import { ScheduleDialogComponent } from './class/schedule-dialog/schedule-dialog.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
// import { CalendarMonthViewComponent } from 'angular-calendar/modules/calendar.module';
@NgModule({
  declarations: [
    DashboardComponent,
    CourseComponent,
    WebBuilder,
    Steps,
    HeaderComponent,
    SidebarComponent,
    ContentComponent,
    ProductComponent,
    CoachVideo,
    CoachComponent,
    NotesComponent,
    CoursedetailsComponent,
    AdvisorComponent,
    OverviewComponent,
    Buisness1Component,
    PartnerdashboardComponent,
    NotesDescription,
    NewCourseComponent,
    ModuleAndLessonComponent,
    NewClassComponent,
    ClassComponent,
    ContentClassComponent,
    SidebarClassComponent,
    ScheduleDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    DashboardRoutingModule,
    MatTabsModule,
    MatButtonModule,
    ProfileModule,
    AccountModule,
    PartnerModule,
    BuisnessModule,
    HtamModule,
    // ModalModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    ScheduleCallModule,
    WebPopupModule,
    VideoModule,
    FlexLayoutModule,
    MatIconModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatSortModule,
    MatChipsModule,
    MatGridListModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    YouTubePlayerModule
  ],
  providers: [SharedService],
  entryComponents: [NotesDescription,ScheduleDialogComponent]
})
export class DashboardModule { }
