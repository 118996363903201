export class smartAdRoll {

  public static header = [
    {
      id: 'header',
      headerImage: "../../assets/images/SARLogoa.png",
      headerMail: "support@smartadroll.com",
      headertitle: "Smart Ad Roll - A Game Changing Tool For YouTube Advertisers",
      headersubtitle: "Smart Ad Roll Quickly extract extensive lists of highly relevant keywords and popular youtube videos with monetization enabled and start showing ads on all of those in minutes",
      headersubimage: "../../assets/images/money_back.png"
    }
  ]

  public static leftSection = [
    {
      id: "leftSection",
      leftSectionTitle: "Here's What You'll Get:",
      leftSectionUlLi_1: 'Powerful search options and settings',
      leftSectionUlLi_2: 'Fully Mobile Responsive Members Portal',
      leftSectionUlLi_3: 'Integrated Dual Source Keyword Tool',
      leftSectionUlLi_4: 'Built In Channel Search Functionality',
      leftSectionUlLi_5: 'Organise Videos Into placement Lists',
      leftSectionUlLi_6: 'Export Search Results In Full Details',
      leftSectionUlLi_7: 'Works On Windows And Mac',
      leftSectionImage_1: '../../assets/images/padlockicon.png',
      leftSectionImage_2: '../../assets/images/sslicon.png',
      leftSectionImage_3: '../../assets/images/satisfaction-img.png',
      leftSection100: '100% Secure Information, Privacy Guaranteed & 100% Satisfaction Guarenteed',
      leftSectioncommentImg_1: '../../assets/images/text-img.png',
      leftSectioncommentImg_2: '../../assets/images/text-img-2.png'
    }
  ]
  public static rightSection = [
    {
      id: "rightSection",
      step_1_image: '../../assets/images/step-1.jpeg',
      step_2_image: '../../assets/images/step-2.jpeg',
      step_3_image: '../../assets/images/step-3.jpeg',
      step_4_image: '../../assets/images/step-4.jpeg',
      stripe_image: '../../assets/images/stripe.png',
      paypal_image: '../../assets/images/paypal.png'
    }
  ]

}