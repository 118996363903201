import { Component, OnInit } from '@angular/core';
import { UserServiceService, AlertService } from '../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Loading } from '../../loader/Loading'

@Component({
  selector: 'app-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.scss']
})
export class AdvisorComponent implements OnInit {
  advisor_details: any;
  videoUrl: any;
  image_src: any;
  first_name: any;
  email: any;
  mobile: any;
  skype_id: any;
  about:any;
  title:any;
  schedule_link:any;
  isLoading: boolean = true;
  constructor(
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private router: Router) { }

  ngOnInit() {

    Loading.show();
    this.UserServiceService.advisor_detail().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.isLoading = false;
      if (data.status_code == 200) {
        this.advisor_details = data.data;

        this.image_src = this.advisor_details.url;
        this.first_name = this.advisor_details.first_name;
        this.email = this.advisor_details.email;
        this.mobile = this.advisor_details.mobile;
        this.skype_id = this.advisor_details.skype_id;
        this.about = this.advisor_details.description;
        this.title = this.advisor_details.title;
        this.schedule_link   =this.advisor_details.schedule_link;
       
        // return false;

        if (this.advisor_details.upload_video != null) {
          this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.advisor_details.upload_video);

        }
        else {
          this.videoUrl = '';
        }

      }
      else {

      }
    });
  }


  scheduleCall(){
    let callScheduleType = 'advisor';
    localStorage.setItem('callScheduleType', callScheduleType);
    this.router.navigate(['/dashboard/schedule-call']);
  }

}
