import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { ForgerpasswordComponent } from './forgerpassword/forgerpassword.component';
import { AuthGuard } from './auth.guard';
import { FooterComponent } from './footer/footer.component';
import { SmartAdRollComponent } from './smart-ad-roll/smart-ad-roll.component';
import { ThanksYouComponent } from './thanks-you/thanks-you.component';
import { CancelledComponent } from './cancelled/cancelled.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WebFromComponent } from './web-from/web-from.component';
const routes: Routes = [

        { path: 'login', component: LoginComponent/* canActivate: [AuthGuard] */ },
        { path: 'forget', component: ForgerpasswordComponent },
        { path: 'smartAdRoll', component: SmartAdRollComponent },
        { path: 'thanksYou', component: ThanksYouComponent },
        { path: 'cancelled', component: CancelledComponent },
        { path: 'page-not-found', component: PageNotFoundComponent },
        { path: 'form/:id', component: WebFromComponent },

        { path: 'thanks', redirectTo: '/thanksYou?successfully=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c', pathMatch: 'full' },
        { path: 'cancel', redirectTo: '/cancelled?cancel=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NWY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adRsscs', pathMatch: 'full' },
        { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
        //otherwise redirect to login
        { path: '**', redirectTo: 'login' }];

@NgModule({
        imports: [RouterModule.forRoot(routes, { useHash: true })],
        exports: [RouterModule]
})
export class AppRoutingModule { }