import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Loading } from '../../../loader/Loading';

import { environment } from 'src/environments/environment';
import { UpdateSidemenuSataService } from 'src/app/_service/update-sidemenu-data.service';
const API_URL = environment.apiUrl;

@Component({
  selector: 'app-updateprofile',
  templateUrl: './updateprofile.component.html',
  styleUrls: ['./updateprofile.component.scss']
})
export class UpdateprofileComponent implements OnInit {

  //mobnumPattern = "^((\\+91-?)|0)?((\\+44-?)|0)?[0-9]{9,10}$";
  loginForm: FormGroup;
  submitted = false;
  imageSrc: string;
  image: any;
  user_details: any;
  dummyPicture: string;
  country_list: any;
  statesList: any;
  statesShipList: any;
  format: string;
  url: string | ArrayBuffer;
  contactId: string;
  constructor(private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    public fb: FormBuilder,
    private updateSidemenuSataService: UpdateSidemenuSataService,
    private alertService: AlertService,
    private router: Router
  ) { }
  ngOnInit() {
    this.contactId = localStorage.getItem('contactId')
    this.dummyPicture = "assets/images/content-profile.png";
    //auth token api test
    this.getauthdetails();
    // this.getcountrydetails();
    this.loginForm = this.fb.group({
      first_name: ['', Validators.required],
      lastName: ['', Validators.required],
      file: [''],
      emailId: ['', [Validators.required, Validators.email]],
      mobile: [''],
      // skype_id: [''],
      // facebook_profile: [''],
      aboutMe: [''],
      country_id: [''],
      address: [''],
      address2: [''],
      addressship2: [''],
      city: [''],
      state: [''],
      zip: [''],
      // image: [''],
      landline_no: [''],
      phoneNo: ['', [Validators.required]],
      countryship: [''],
      addressship: [''],
      stateship: [''],
      zipship: [''],
      cityship: ['']
    });
  }
  onFileChange() {

  }
  fileChangeEvent(event: any): void {

    const file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        this.image = (<FileReader>event.target).result;

        var data = new FormData();
        data.append("contact_id", this.contactId);
        data.append("image", file, file.name);

        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            console.log(xhr.responseText);
          }
        };
        xhr.open("POST", `${API_URL}update_profile`);
        xhr.send(data);
        localStorage.setItem('profile_pic', this.image);
        this.updateSidemenuSataService.updateProfilePic(this.image);

      };
    }
  }

  // fileChangeEvent(event: any): void {
  //   let reader = new FileReader();
  //   const file = event.target.files[0];

  //   if (event.target.files && event.target.files[0]) {
  //     reader.readAsDataURL(file);
  //     if (file.type.indexOf('image') > -1) {
  //       this.format = 'image';
  //     }
  //     reader.onload = (event) => {
  //       this.image = (<FileReader>event.target).result;

  //       let keySend = {
  //         'contact_id': this.contactId,
  //         'image': reader.result,
  //       }
  //       this.UserServiceService.profileUpdate(keySend).pipe(first()).subscribe((data: any) => {
  //         if (data.error == false) {
  //           this.user_details = data.data;
  //           this.image = this.user_details.image;
  //           console.log('222222222222222', this.image);

  //           Loading.hide();
  //           this.alertService.success(data.message);
  //           this.UserServiceService.get_auth_details().pipe(first()).subscribe((data: any) => {
  //             if (data.status_code == 200) {
  //               this.user_details = data.data;
  //               this.image = this.user_details.image;
  //               console.log('qqqqqqqq', this.image);
  //             }
  //             else {
  //               Loading.hide();
  //             }
  //           });

  //         }

  //       });
  //     }
  //   }
  // }


  //Update user details
  get f() { return this.loginForm.controls; }
  userDetails() {
    this.submitted = true;
    if (this.loginForm.invalid) {

      return;
    }
    this.UserServiceService.update_user(this.loginForm.value).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        Loading.hide();
        this.alertService.success(data.message);
        //Get auth user details
        this.getauthdetails();
      }
      else {
        Loading.hide();
      }
    });
  }
  getauthdetails() {
    //function call to get all users details 
    this.UserServiceService.get_auth_details().pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        // alert(this.user_details);
        this.user_details = data.data;
        // if (this.user_details.country_id) {
        //   this.getStatesBycoutryids(this.user_details.country_id);
        // }
        // if (this.user_details.countryship) {
        //   this.getStatesBycoutryidsShip(this.user_details.countryship);
        // }
        this.image = this.user_details.image;
        this.loginForm.patchValue({ first_name: this.user_details.first_name });
        this.loginForm.patchValue({ lastName: this.user_details.last_name });
        this.loginForm.patchValue({ emailId: this.user_details.email });
        // this.loginForm.patchValue({ skype_id: this.user_details.skype_id });
        // this.loginForm.patchValue({ facebook_profile: this.user_details.facebook_profile });
        this.loginForm.patchValue({ landline_no: this.user_details.landline_no });
        this.loginForm.patchValue({ phoneNo: this.user_details.mobile });
        this.loginForm.patchValue({ aboutMe: this.user_details.about });
        this.loginForm.patchValue({ fileSource: this.user_details.url });
        this.loginForm.patchValue({ address: this.user_details.address });
        this.loginForm.patchValue({ address2: this.user_details.address2 });
        this.loginForm.patchValue({ country_id: this.user_details.country_id });
        this.loginForm.patchValue({ state: this.user_details.state });
        this.loginForm.patchValue({ city: this.user_details.city });
        this.loginForm.patchValue({ zip: this.user_details.zip });
        this.loginForm.patchValue({ addressship: this.user_details.addressship });
        this.loginForm.patchValue({ addressship2: this.user_details.addressship2 });
        this.loginForm.patchValue({ countryship: this.user_details.countryship });
        this.loginForm.patchValue({ stateship: this.user_details.stateship });
        this.loginForm.patchValue({ cityship: this.user_details.cityship });
        this.loginForm.patchValue({ zipship: this.user_details.zipship });

      }
      else {

      }
    });
  }

  //get all country details
  getcountrydetails() {
    this.UserServiceService.get_country_list().pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.country_list = data.data;
      }
    });
  }
  //billling country update
  selectChangeHandler(event: any) {
    this.loginForm.patchValue({
      country_id: event.target.value
    });

    this.getStatesBycoutryids(event.target.value);
  }
  //billling state update
  selectChangeHandlerstate(event: any) {
    this.loginForm.patchValue({
      state: event.target.value
    });

  }

  //shiping country update
  selectChangeHandlership(event: any) {

    this.loginForm.patchValue({
      countryship: event.target.value
    });
    // this.getStatesBycoutryidsShip(event.target.value);
  }

  //get states by country id
  getStatesBycoutryids(id) {
    this.UserServiceService.api_get_states(id).pipe(first()).subscribe((statedata: any) => {
      console.log("Helooooooooooooo", statedata);
      if (statedata.status == 200) {
        this.statesList = statedata.data;

      } else {
        this.statesList = [];
      }
    });
  }
  //get states by country id
  getStatesBycoutryidsShip(id) {
    this.UserServiceService.api_get_states(id).pipe(first()).subscribe((state: any) => {
      console.log("hiiiiiiiiiiiiiiii");
      if (state.status == 200) {
        this.statesShipList = state.data;

      } else {
        this.statesShipList = [];
      }
    });
  }
  //shiping states update
  selectChangeHandlerstateship(event: any) {
    this.loginForm.patchValue({
      stateship: event.target.value
    });
  }
  //Logout user and destroy authtoken
  logout() {

    this.UserServiceService.logout().pipe(first()).subscribe((data: any) => {
      if (data.status == 200) {
        localStorage.removeItem('user_details');
        localStorage.removeItem('tagId')
        localStorage.removeItem('orderId');
        localStorage.clear();
        this.router.navigate(['/']);
      }
    });
  }

}
