import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Loading } from '../loader/Loading';
import { map } from 'rxjs/operators';
import { environment as env } from '../../environments/environment'
const API_URL = env.apiUrl;
const apiUrlCintram = env.apiUrlCintram;
@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  private subject = new Subject<any>();
  data_test: any;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private loggedInUser: BehaviorSubject<any> = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user_info')));

  private messageSource_tag = new BehaviorSubject('default tag');
  currentMessagetag = this.messageSource_tag.asObservable();


  private messageSource1 = new BehaviorSubject('default message1');
  currentMessage1 = this.messageSource1.asObservable();



  private messageSource_membership = new BehaviorSubject('default message1');
  currentMessage_membership = this.messageSource_membership.asObservable();


  get isLoggedIn() {

    return this.loggedIn.asObservable();
  }
  get isLoggedInUser() {
    return this.loggedInUser.asObservable();
  }
  constructor(private http: HttpClient) {
  }
  sendOverviewData(data: any) {
    this.subject.next(data);
  }
  getOverviewData(): Observable<any> {
    return this.subject.asObservable();
  }
  private getArgHeaders(): any {


    let login_access_token = JSON.parse(localStorage.getItem('user_details'));

    if (login_access_token == null) {
      return false;
    }

    let orderId = localStorage.getItem('orderId');


    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json; charset=utf-8',
          'X-Auth-Token': login_access_token.access_token ? login_access_token.access_token : '',
          'Accept': 'application/json',
          "Cache-Control": "no-cache"
        },

      ),
    };


    return httpOptions;
  }
  // 'orderId': '1',

  private getArgHeaders_1(): any {

    let login_access_token = JSON.parse(localStorage.getItem('user_details'));

    let orderId = localStorage.getItem('orderId');


    const httpOptions =
    {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json; charset=utf-8',
          'X-Auth-Token': login_access_token.access_token ? login_access_token.access_token : '',
          'Accept': 'application/json',
          "Cache-Control": "no-cache"
        },

      ),
    };
    // 'orderId': '1',


    return httpOptions;
  }

  /*  changeMessage() {
       return this.http.post(`${API_URL}api_get_coach`, '', this.getArgHeaders())
     /* this.messageSource.next(message); */
  //}

  /* changeMessage(message: string) {
    this.messageSource.next(message)
  } */
  logoGet(data) {
    return this.http.post(`${API_URL}api_get_logo`, data)
  }
  changeMessage() {
    return this.http.post(`${API_URL}api_get_coach`, '', this.getArgHeaders());
    //this.messageSource.next(message)
  }
  availableDate(data: any) {
    return this.http.post(`${API_URL}availableDate`, data, this.getArgHeaders());
    //this.messageSource.next(message)
  }
  availableTime(data: any) {
    return this.http.post(`${API_URL}availableTime`, data, this.getArgHeaders());
    //this.messageSource.next(message)
  }
  makeAppointment(data: any) {
    return this.http.post(`${API_URL}makeAppointment`, data, this.getArgHeaders());
    //this.messageSource.next(message)
  }
  getAppointmentDetails(data: any) {
    return this.http.post(`${API_URL}getAppointmentDetails`, data, this.getArgHeaders());
    //this.messageSource.next(message)
  }

  changeMessage_data() {
    return this.http.post(`${API_URL}api_get_advisor`, '', this.getArgHeaders());
    //this.messageSource.next(message)
  }


  changeMessage_membership(membership_message: string) {
    this.messageSource_membership.next(membership_message);
  }

  changeMessage1(message: string) {

    //console.log();
    //return false;
    this.messageSource.next(message);
  }

  changeMessage_tag(message: string) {
    this.messageSource_tag.next(message);
  }

  changeMessage2(message1: string) {
    //console.log();
    //return false;
    this.messageSource1.next(message1);
  }

  //login function
  call_login(data: any) {
    let data1 = localStorage.getItem('user_details');

    Loading.show();
    if (data == '') {

      //this.loggedIn.next(false);
    }
    else {
      return this.http.post(`${API_URL}loginUser/`, data).
        pipe(
          map((data: any) => {
            if (data.status_code == 200) {
              localStorage.setItem("isLogdIn", "true")
              this.loggedIn.next(true);
              localStorage.setItem("user_info", JSON.stringify(data));
              this.loggedInUser.next(data);
              return data;
            }
            else {

              //return false;
              return data;
            }
          }),
        )
      //return  this.http.post(`${API_URL}loginUser`, data) 
    }
  }
  webFromPrivew(id: any) {
    return this.http.get(`${apiUrlCintram}/marketing/web_from/details/` + id + '/',)
  }
  webContact(data: any) {
    return this.http.post(`${apiUrlCintram}/api/contacts/webform/api_create_contact`, data, {
    })
  }
  //Get category details
  get_category(data) {
    //Loading.show();

    return this.http.post(`${API_URL}category/` + '?learning_type=' + data.learning_type + '&sort_by=' + data.sort_by, '', this.getArgHeaders())
  }

  get_trainer(data: any) {
    //Loading.show();

    return this.http.post(`${API_URL}api_get_course_trainer/`, data, this.getArgHeaders())
  }
  //Get child category details
  get_childcategory(data: any) {
    // console.log('check',data);

    // Loading.show();
    return this.http.post(`${API_URL}api_get_comments`, data, this.getArgHeaders())
  }

  //Get all subcategory 
  show_subcategory_data() {

    return this.http.get(`${API_URL}show_sub_category`)
  }

  //Mark as complete
  mark_as_complete(data: any) {

    return this.http.post(`${API_URL}api_mark_complete_status`,
      data, this.getArgHeaders())
  }
  check_as_complete(data: any) {

    return this.http.post(`${API_URL}api_check_complete_status`,
      data, this.getArgHeaders())
  }

  mark_as_complete_product(data: any) {

    return this.http.post(`${API_URL}api_mark_product`,
      data, this.getArgHeaders())
  }

  //Get complete status
  get_complete_status() {
    return this.http.post(`${API_URL}api_get_mark_status`,
      '', this.getArgHeaders())
  }


  //product
  get_complete_status_product() {
    return this.http.get(`${API_URL}api_get_product_status`,
      this.getArgHeaders())
  }

  //Add comment
  add_comment(data: any) {
    return this.http.post(`${API_URL}api_comments_insert`,
      data, this.getArgHeaders())
  }
  add_comment_product(data: any) {
    return this.http.post(`${API_URL}api_post_comments_product`,
      data, this.getArgHeaders())

  }

  //Get product details of course
  get_product_details() {
    Loading.show();
    return this.http.post(`${API_URL}api_products_list`,
      '', this.getArgHeaders())
  }
  //Get course details
  get_course_details(data: any) {
    return this.http.post(`${API_URL}api_course_details`,
      data, this.getArgHeaders())
  }

  get_products_details(data: any) {

    return this.http.post(`${API_URL}api_product_details`,
      data, this.getArgHeaders())
  }

  get_childcategory_product(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}api_get_products`,
      data, this.getArgHeaders())
  }


  //Get all users details api by authtoken
  get_auth_details() {
    return this.http.post(`${API_URL}authUser/`, '', this.getArgHeaders())
  }

  //logout function empty authtoken
  logout() {
    this.loggedIn.next(false);

    return this.http.post(`${API_URL}logoutUser/`, '',
      this.getArgHeaders())
  }

  //Update current user
  update_user(data: any) {
    Loading.show();

    return this.http.post(`${API_URL}updateUser`, data,
      this.getArgHeaders())
  }
  //Get country list
  get_country_list() {
    return this.http.post(`${API_URL}api_get_countries/`, '',
      this.getArgHeaders())
  }

  //Get states list
  api_get_states(id: any) {
    return this.http.get(`${API_URL}api_get_states/` + id,
      this.getArgHeaders())
  }
  //Get alloted coach detail
  coach_detail() {
    if (this.getArgHeaders())

      return this.http.post(`${API_URL}api_get_coach`, '', this.getArgHeaders())
  }

  //Get alloted advisor detail
  advisor_detail() {
    return this.http.post(`${API_URL}api_get_advisor`, '', this.getArgHeaders())
  }
  update_password(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}changePassword`, data, this.getArgHeaders())
  }

  add_support(data: any) {

    Loading.show();
    return this.http.post(`${API_URL}sendMessage`, data, this.getArgHeaders())
  }
  get_status(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}get_complete_data`, data, this.getArgHeaders())
  }
  call_forgetpassword(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}apiForgotPassword`, data)
  }

  update_user_image(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}photoUpdate`, data, this.getArgHeaders())
  }

  get_overview_details(data: any) {
    return this.http.post(`${API_URL}api_get_overviews`, data, this.getArgHeaders())
  }
  profileUpdate(data) {
    return this.http.post(`${API_URL}update_profile`, data, this.getArgHeaders())
  }

  get_schedule_call_details() {

    return this.http.get(`${API_URL}api_get_calls`, this.getArgHeaders())
  }
  add_schedule_call(data: any) {

    return this.http.post(`${API_URL}api_add_calls`, '',
      this.getArgHeaders())
  }

  get_cardlist() {



    return this.http.get(`${API_URL}cardsList`,
      this.getArgHeaders_1())
  }

  get_invoiceList() {

    return this.http.get(`${API_URL}invoiceList`,
      this.getArgHeaders_1())
  }

  /*  get_subcriptionAndPayment() {
      return this.http.get(`${API_URL}subcriptionAndPayment`,
        this.getArgHeaders_1())
    }*/
  getTransactionsData(data: any) {
    return this.http.post(`${API_URL}getTransactionsData`, data,
      this.getArgHeaders_1())
  }
  get_payment() {

    return this.http.get(`${API_URL}payment`,
      this.getArgHeaders_1())
  }
  change_card(data: any) {

    return this.http.post(`${API_URL}changeCard`, data,
      this.getArgHeaders())

  }
  activeDefault_card(data: any) {

    return this.http.post(`${API_URL}activeDefault`, data,
      this.getArgHeaders())


  }

  orderCancel_card(data: any) {

    return this.http.post(`${API_URL}orderCancel`, data,
      this.getArgHeaders())

  }

  getSubcriptionAndPayment_card(data: any) {

    return this.http.post(`${API_URL}getSubcriptionAndPayment`, data,
      this.getArgHeaders())


  }
  orderupdate_card(data: any) {


    return this.http.post(`${API_URL}orderUpdate`, data,
      this.getArgHeaders())

  }
  partner_program(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}partner_program`, data,
      this.getArgHeaders())
  }
  getCommissionsData(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}getCommissionsData`, data,
      this.getArgHeaders())
  }
  getCommissionsDataList(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}getCommissionsDataList`, data,
      this.getArgHeaders())
  }

  get_faq() {
    Loading.show();
    return this.http.get(`${API_URL}api_get_faq`,
      this.getArgHeaders())

  }

  get_offers() {
    Loading.show();
    return this.http.get(`${API_URL}api_get_offers`,
      this.getArgHeaders())

  }

  get_offer_details(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}api_email_details`, data,
      this.getArgHeaders())

  }

  get_link_details(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}api_link_details`, data,
      this.getArgHeaders())

  }

  get_banner_details(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}api_banner_details`, data,
      this.getArgHeaders())

  }

  get_dashboard_detail() {
    Loading.show();
    return this.http.get(`${API_URL}api_dashboard_details`,
      this.getArgHeaders())


  }
  add_bank_details(data: any) {
    Loading.show();
    return this.http.post(`${API_URL}add_bank_details`, data, this.getArgHeaders())
  }
  get_bank_detial() {
    return this.http.get(`${API_URL}get_bank_details`, this.getArgHeaders())
  }
  update_bank_details(data: any) {
    return this.http.post(`${API_URL}update_bank_details`, data, this.getArgHeaders())
  }

  getAppointmentDetailsAll(data: any) {
    return this.http.post(`${API_URL}getAppointmentDetails`, data, this.getArgHeaders())
  }

  getTrainingData(data: any) {
    return this.http.post(`${API_URL}apiTrainingStep`, data, this.getArgHeaders())
  }

  apiTrainigVideo(data: any) {
    return this.http.post(`${API_URL}apiTrainigVideo`, data, this.getArgHeaders())
  }

  getOfferById(id: any) {

    return this.http.get(`${API_URL}api_offer_id_details?id=` + id, this.getArgHeaders())
  }

  postOfferForm(data: any) {
    return this.http.post(`${API_URL}registerUserNew?contact_id=15`, data, this.getArgHeaders())

  }
  transactionPostApi(data: any) {
    return this.http.post(`${API_URL}userTransaction`, data, this.getArgHeaders())

  }
  newCourseList(data: any) {
    return this.http.get(`${API_URL}api_get_course` + '?contact_id=' + data.contact_id, this.getArgHeaders())
  }

  getModulesList(data: any) {
    return this.http.get(`${API_URL}api_get_modules` + '?course_id=' + data.course_id + '&contact_id=' + data.contact_id, this.getArgHeaders())
  }
  commentsReplyInsert(data: any) {
    return this.http.post(`${API_URL}api_comments_reply_insert`, data, this.getArgHeaders())
  }
  queSubmit(data) {
    return this.http.post(`${API_URL}student/assignment/question/answer/`, data, this.getArgHeaders())
  }

  queAnsPriview(data) {
    return this.http.post(`${API_URL}student/assignment/details/`, data, this.getArgHeaders())
  }
}
