import { Component, OnInit, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Loading } from '../../../loader/Loading';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.scss']
})
export class LedgerComponent implements OnInit {
  fileName = 'ExcelSheet.xlsx';
  loginForm: FormGroup;
  submitted = false;

  id: any;
  id_data: any;
  value: any;
  data_report: any;
  data_clawbacks: any;
  data_commissions: any;
  pandingStatusData: any;
  aproveStatusData: any;
  paymentMade:any;
  panding:any = [];
  data_payments: any;
  data_range: any;
  data_totals: any;
  id_data_subid: any;
  newToday: any;
  dateYesterday: any;
  dateLastMonthFirst: any;
  dateLastMonthEnd: any;
  dateCurrentMonthFirst: any;
  dateCurrentMonthEnd: any;
  start_date_report: any;
  end_date_report: any;
  start_date: any;
  end_date: any;
  tagId1:any;
  date:any;

  AllCommission:any;
  TodayCommission:any;
  YestarDayCommission: any;
  LastSevenDaysCommission: any;
  LastThirtyDaysCommission: any;
  CurrentMonthCommission: any;
  LastMonthCommission: any;

  allCommissionp : any;
  todayCommissionp : any;
  yestarDayCommissionp : any;
  lastSevenDaysCommissionp : any;
  lastThirtyDaysCommissionp : any;
  currentMonthCommissionp : any;
  lastMonthCommissionp : any;

  constructor(private UserServiceService: UserServiceService,
    private formBuilder: FormBuilder, private router: Router,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.tagId1 = localStorage.getItem('tagId');
    if(this.tagId1=='false')
    {
      this.router.navigate(['/dashboard/course']);
    }
    /* this.loginForm = this.formBuilder.group({
      //cardNo: ['', Validators.required,Validators.minLength(3)],
      startdate: ['', Validators.required],
      enddate:['',Validators.required
    }); */
    this.getCommissionsData();
    this.onchange('1', '');
  }
  onchange(event, type) {
    this.date = new Date();
    let dateToday = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    console.log("dateToday",dateToday);
    if (event == '2') {
      this.id_data = '';

      let dateToday = new Date();
      this.newToday = this.formatDate(dateToday);

      var yesterday = dateToday.setDate(dateToday.getDate() - 1);
      var dateString = new Date(yesterday);
      this.dateYesterday = this.formatDate(dateString);

      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event
        }

      }


/*      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });*/
      let objData = {
        "type": event
      }
      this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.paymentMade = this.data_commissions.filter(item => item.status == "Payout");
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);

          //this.panding = this.panding.concat(this.pandingStatusData, this.aproveStatusData);
/*          if (type === "subid") {
            this.data_report = data.data;
          } else {

            //this.data_clawbacks = data.data.clawbacks;
            

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }*/
        }
        //return false;

/*        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }*/

      });
    }


    //for today section
    else if (event == '3') {
      let objData = {
        "type": event
      }
      this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.paymentMade = this.data_commissions.filter(item => item.status == "Payout");
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);

          //this.panding = this.panding.concat(this.pandingStatusData, this.aproveStatusData);
  /*          if (type === "subid") {
            this.data_report = data.data;
          } else {

            //this.data_clawbacks = data.data.clawbacks;
            

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }*/
        }
      }); 
/*      this.id_data = '';
      let dateToday = new Date();
      this.newToday = this.formatDate(dateToday);
      var yesterday = dateToday.setDate(dateToday.getDate() - 1);
      var dateString = new Date(yesterday);
      this.dateYesterday = this.formatDate(dateString);


      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.newToday,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.newToday,
          "endDate": this.newToday,
          "selectedName": event
        }

      }


      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });*/
    }

    ////for last month  section
    else if (event == '4') {
      let objData = {
        "type": event
      }
      this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.paymentMade = this.data_commissions.filter(item => item.status == "Payout");
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);

          //this.panding = this.panding.concat(this.pandingStatusData, this.aproveStatusData);
  /*          if (type === "subid") {
            this.data_report = data.data;
          } else {

            //this.data_clawbacks = data.data.clawbacks;
            

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }*/
        }
      });
/*      this.id_data = '';
      let dateToday = new Date();
      var lastMonthFirst = new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1);
      var lastMonthdayEnd = new Date(dateToday.getFullYear(), dateToday.getMonth(), -1);
      this.dateLastMonthFirst = this.formatDate(lastMonthFirst);
      this.dateLastMonthEnd = this.formatDate(lastMonthdayEnd);
      this.newToday = this.dateLastMonthEnd;
      this.dateYesterday = this.dateLastMonthFirst;

      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.dateLastMonthFirst,
          "endDate": this.dateLastMonthEnd,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.dateLastMonthFirst,
          "endDate": this.dateLastMonthEnd,
          "selectedName": event
        }

      }


      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });*/
    }



    ////for last month  section
    else if (event == '5') {
      let objData = {
        "type": event
      }
      this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.paymentMade = this.data_commissions.filter(item => item.status == "Payout");
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);

          //this.panding = this.panding.concat(this.pandingStatusData, this.aproveStatusData);
  /*          if (type === "subid") {
            this.data_report = data.data;
          } else {

            //this.data_clawbacks = data.data.clawbacks;
            

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }*/
        }
      });
/*      this.id_data = '';

      let dateToday = new Date();


      var currentMonthStart = new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
      this.dateCurrentMonthFirst = this.formatDate(currentMonthStart);
      this.dateCurrentMonthEnd = this.formatDate(dateToday);
      this.newToday = this.dateCurrentMonthEnd;
      this.dateYesterday = this.dateCurrentMonthFirst;
      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.dateCurrentMonthFirst,
          "endDate": this.dateCurrentMonthEnd,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.dateCurrentMonthFirst,
          "endDate": this.dateCurrentMonthEnd,
          "selectedName": event
        }
      }

      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });*/
    }

    else if (event == '6') {
      if (type == 'subid') {
        this.id_data_subid = '4';

      }
      else {

        this.id_data = '3';

      }
    }

    else {


      //call for full time   
      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": 'All Time',
          "type": type
        }
        this.id = '2';

      }
      else {

        this.id_data = '';
        this.id = '1';
        


        let dateToday = new Date();
        this.newToday = this.formatDate(dateToday);

        var yesterday = dateToday.setDate(dateToday.getDate() - 1);
        var dateString = new Date(yesterday);
        this.dateYesterday = '1/1/2008';

        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event
        }



      }

      let objData = {
        "type": event
      }
      this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.paymentMade = this.data_commissions.filter(item => item.status == "Payout");
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);
          //this.panding = this.panding.concat(this.pandingStatusData, this.aproveStatusData);
/*          if (type === "subid") {
            this.data_report = data.data;
          } else {

            //this.data_clawbacks = data.data.clawbacks;
            

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }*/
        }
      });  
    }
  }
  formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return month + "/" + day + "/" + year;
  }


  customSubmit(startdate, enddate) {
    let dateToday = this.datepipe.transform(this.date, 'yyyy-MM-dd');
    if (startdate == undefined) {
      this.start_date = 'error_startdate';
      return false;
    }

    if (enddate == undefined) {

      this.end_date = 'error_enddate';
      return false;
    }
    let objData = {
      "start_date": startdate,
      "end_date": enddate,
      "type": 6
    }
    this.UserServiceService.getCommissionsDataList(objData).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      if (data.status == 200) {
        this.data_commissions = data.data;
          this.pandingStatusData = this.data_commissions.filter(item => (item.status == "Approve" || item.status == "Pending" || item.status == "Cancel" || item.status == "Delayed" || item.status == "Refunded"));

          this.aproveStatusData = this.data_commissions.filter(data =>  data.status == "Approve" && data.grace_period_date > dateToday);
/*          if (type === "subid") {
          this.data_report = data.data;
        } else {

          //this.data_clawbacks = data.data.clawbacks;
          

          this.data_payments = data.data.payments;
          this.data_range = data.data.range;
          this.data_totals = data.data.totals;
        }*/
      }
    });

/*    this.value = {
      "startDate": startdate,
      "endDate": enddate,
      "selectedName": 'custom',

    }


    // return false;


    this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
      Loading.hide();


      if (data.status == 200) {


        this.data_clawbacks = data.data.clawbacks;
        this.data_commissions = data.data.commissions;

        this.data_payments = data.data.payments;
        this.data_range = data.data.range;
        this.data_totals = data.data.totals;

      }

    });*/
  }

  //get commissions data 
  getCommissionsData(){
      let objData = {
        "type": 1
      }
      this.UserServiceService.getCommissionsData(objData).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        if (data.status == 200) {
          this.data_commissions = data.data;
          this.AllCommission = data.data.AllCommission;
          this.TodayCommission = data.data.TodayCommission;
          this.YestarDayCommission = data.data.YestarDayCommission; 
          this.LastSevenDaysCommission = data.data.LastSevenDaysCommission;
          this.LastThirtyDaysCommission = data.data.LastThirtyDaysCommission;
          this.CurrentMonthCommission = data.data.CurrentMonthCommission;
          this.LastMonthCommission = data.data.LastMonthCommission;

          //check All time commissions
          if(this.AllCommission.length > 0){
            this.allCommissionp = this.AllCommission[0].total_commissions;
            
          }else{
            this.allCommissionp = 0;
          }

          //check today commissions
          if(this.TodayCommission.length > 0){
            this.todayCommissionp = this.TodayCommission[0].total_commissions;
          }else{
            this.todayCommissionp = 0;
          }

          //check YestarDayCommission commissions
          if(this.YestarDayCommission.length > 0){
            this.yestarDayCommissionp = this.YestarDayCommission[0].total_commissions;
          }else{
            this.yestarDayCommissionp = 0;
          }
          
          //check LastSevenDaysCommission commissions
          if(this.LastSevenDaysCommission.length > 0){
            this.lastSevenDaysCommissionp = this.LastSevenDaysCommission[0].total_commissions;

          }else{
            this.lastSevenDaysCommissionp = 0;
          }

          //check LastThirtyDaysCommission commissions
          if(this.LastThirtyDaysCommission.length > 0){
            this.lastThirtyDaysCommissionp = this.LastThirtyDaysCommission[0].total_commissions;
          }else{
            this.lastThirtyDaysCommissionp = 0;
          }

          //check CurrentMonthCommission commissions
          if(this.CurrentMonthCommission.length > 0){
            this.currentMonthCommissionp = this.CurrentMonthCommission[0].total_commissions;
          }else{
            this.currentMonthCommissionp = 0;
          }

          //check LastMonthCommission commissions
          if(this.LastMonthCommission.length > 0){
            this.lastMonthCommissionp = this.LastMonthCommission[0].total_commissions;
          }else{
            this.lastMonthCommissionp = 0;
          }
        }
      }); 
  }
  customSubmitReport(startdate, enddate) {

    if (startdate == undefined) {

      this.start_date_report = 'error_startdate';
      return false;
    }

    if (enddate == undefined) {

      this.end_date_report = 'error_endate';
      return false;
    }
    this.value = {
      "startDate": startdate,
      "endDate": enddate,
      "selectedName": 'custom',
      "type": "subid"
    }


    this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
      Loading.hide();

      if (data.status == 200) {

        this.data_report = data.data;

      }

    });
  }

  //export file commission
  exportexcel_commision() {
    let element = document.getElementById('entry-grid');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);

  }

  //export file clawbacks
  exportexcel_clawbacks() {
    let element = document.getElementById('entry-grid2');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);

  }

  //export file payment
  exportexcel_payment() {
    let element = document.getElementById('entry-grid3');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);


  }


}

