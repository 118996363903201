import { Component, OnInit, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ModalService } from '../../../_modal';
import { UserServiceService } from '../../../_service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SharedService } from 'src/app/_service/shared.service';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  comment: string = '';
  commentReplySet: string = '';
  submissionData = [];
  dataArr: any[] = [
    {
      "title": "step1"
    },
    {
      "title": "step2"
    },
    {
      "title": "step3"
    },
    {
      "title": "step4"
    }
  ];

  @Output() markascompleteEvent = new EventEmitter<any>();

  @Output() closeModalEvent = new EventEmitter<any>();
  @Output() addCommentEvent = new EventEmitter<any>();
  @Output() addindexEvent = new EventEmitter<any>();
  @Output() removeindexEvent = new EventEmitter<any>();
  @Output() stepnextactive = new EventEmitter<any>();

  dangerousUrl: string;
  dangerousVideoUrl: string;
  @Input() childMessage: any;
  @Input() index_value: any;
  @Input() videoUrl: string;
  @Input() title: any;
  @Input() comment1: any;
  // @Input() assignment: any;
  @Input() text_description: any;

  @Input() questions: any;
  @Input() title_overview: any;
  @Input() description_overview: any;
  @Input() overview_id: any;

  @Input() videoUrl_overview: SafeResourceUrl;

  @Input() description: any;
  @Input() userComments: any;
  @Input() course_id: any;
  @Input() id: any;
  @Input() module_id: any;
  @Input() step_index_id1: any;
  @Input() step_index_id2: any;
  @Input() step_id: any;
  @Input() active_step_id: any;
  @Input() moduleindex_value: any;
  @Input() lastindexvalue: any;
  @Input() subCategories: Array<Number> = [];
  @Input() subCategories_module: Array<Number> = [];
  @Input() subCategories_course: Array<Number> = [];
  @Input() markascompletedata: any;
  @Input() module_msg: any;
  @Input() dbdstepActiveMarkAs: any;
  @Input() is_completed: any;
  currentIndex = 1;

  userLogin: any;
  child_category_data: any;
  model_id: string;
  dbCategoryName: any;
  current_id: any;
  next_id: any;
  numberValue_nextid: any;
  numberValue_currentid: any;
  parent1: any[];
  parent2: any[];
  parent3: any[];
  parent: any;
  listing: Array<any> = [];
  step1: Array<Number> = [];
  step2: Array<Number> = [];
  subCategories2: Array<Number> = [];
  assign_module: Array<string> = [];
  moduleSteps: Array<string> = [];
  moduleSteps_new: Array<string> = [];
  moduleSteps_update: Array<string> = [];
  module1: any;
  moduleSteps_data: any;
  c_module_id1: any;
  subscription: any;
  contactId: any;
  selectedOptions: any[] = [];
  selectedOption: any;
  // replyHideShow: any;
  /*  videoUrl_overview: SafeResourceUrl;
    title_overview:any;
    description_overview:any;
    overview_id: any;*/
  constructor(
    private modalService: ModalService,
    private route: ActivatedRoute,
    private UserServiceService: UserServiceService,
    private toastrService: ToastrService,
    private sanitizer: DomSanitizer,
    private sharedService: SharedService,
  ) {
    this.userLogin = true;
    this.subscription = this.UserServiceService.getOverviewData().subscribe(res => {
      this.description_overview = res.description;
      this.title_overview = res.title;
      this.overview_id = res.id;
      this.videoUrl_overview = this.sanitizer.bypassSecurityTrustHtml(res.overview_url);

    });
  }
  ngOnInit() {
    this.contactId = localStorage.getItem("contactId");
    this.route.params.subscribe(params => {
      this.dbCategoryName = params['id'];

    })
    // this.sharedService.assignment$.subscribe(assignment => {
    //   this.assignment = assignment;

    //   console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzz',this.assignment);

    // });
    this.getAllSteps();
    // console.log("id kya h????", window.location.href);
    // this.newReload()
    // window.location.reload();
  }

  markascomplete(id, course_id, module_id, index_value, current_index_value) {

    /* console.log('stepid',id);
    console.log('courseid',course_id);
    console.log('moduleid',module_id);
    return false; */
    this.markascompleteEvent.emit({ step_id: id, course_id: course_id, module_id: module_id, index: index_value, current_index_value: current_index_value })
  }

  closeModal() {
    this.model_id = 'custom-modal-1';
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    this.modalService.close(this.model_id);
    // this.closeModalEvent.emit();
  }
  add_comment(comment, id, course_id, module_id) {
    // console.log('ssssssssssssssss', comment, id, course_id, module_id);

    this.addCommentEvent.emit({ step_id: id, course_id: course_id, module_id: module_id, comment: comment })
    this.comment = '';
  }
  addCommentSet1(data1) {
    let keySend = {
      "comment": data1.comments_id,
      "message": this.commentReplySet,
      "contact": data1.contact,
      "lesson": data1.lesson
    }
    console.log('2222222222222222', data1);

    this.UserServiceService.commentsReplyInsert(keySend).pipe(first()).subscribe((data2: any) => {
      if (data2.error == false) {
        this.commentReplySet = ''

        this.UserServiceService.get_childcategory({ 'subcategory_id': data1.lesson, 'course_id': data2.parents_category, 'module_id': data1.category_id }).pipe(first()).subscribe((data: any) => {
          this.userComments = data.data.users_comments;
          this.title = data.data.video_description.title;
          this.comment1 = data.data.video_description.comment;
          this.is_completed = data.data.video_description.is_completed;
          this.description = data.data.video_description.description;

          this.text_description = this.description.replace(/<[^>]*>/g, '');
        });
      }
      else {
        this.toastrService.error(data2.message);
      }
    })
  }
  //over couse set click
  get_value_step_set(index_value, ids, course_id, module_id, moduleindex_value, current_index_value) {
    if (course_id == 1) {
      this.overview_id = '';
      this.dbdstepActiveMarkAs = 1;
      this.stepnextactive.emit({ index_value: index_value, id: ids, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value, current_index_value: current_index_value });
    } else {
      this.overview_id = '';
      this.dbdstepActiveMarkAs = 0;
      this.stepnextactive.emit({ index_value: index_value, id: ids, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value, current_index_value: current_index_value });
    }

    //this.stepNextActive.emit({index_value: index_value, id:ids, course_id:course_id, module_id:module_id, moduleindex_value:moduleindex_value,current_index_value:current_index_value});
  }


  get_value(index_value, ids, course_id, module_id, moduleindex_value, current_index_value) {

    this.checkcompletestatus(index_value, ids, course_id, module_id, moduleindex_value, current_index_value);
    // this.addindexEvent.emit({ index: index_value, step_id: id, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value })
  }

  checkcompletestatus(index_value, id, course_id, module_id, moduleindex_value, current_index_value) {
    console.log('aaaa', index_value, id, course_id, module_id, moduleindex_value, current_index_value);

    let str1_current = this.moduleSteps_new[current_index_value];
    console.log('sssssssssss',str1_current);
    

    let module_current_idss = str1_current.split("-", 4);
    this.current_id = module_current_idss[3];

    this.lastindexvalue = this.moduleSteps_new.length - 1;

    let str1 = this.moduleSteps_new[index_value];
    // console.log('ssssssxxxxxxxxxxxxxxxxxxx', str1);

    if (str1 != undefined) {
      let module_idss = str1.split("-", 4);
      this.next_id = module_idss[3];
      this.numberValue_nextid = Number(this.next_id);
    }
    else {
      this.numberValue_nextid = '';
    }

    this.numberValue_currentid = Number(this.current_id);

    this.UserServiceService.check_as_complete({
      'subcategory_id': id,
      'category_id': module_id,
      'parents_category': course_id,
      'next_id': this.numberValue_nextid,
      'current_id': this.numberValue_currentid,
      "currentIndex": current_index_value
    }).pipe(first()).subscribe((data: any) => {

      if (data.status_code == 200 && course_id == 1) {
        this.addindexEvent.emit({ index: index_value, step_id: id, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value })
        // this.toastrService.success(data, data.message);

      }
      else if (course_id == 1) {
        this.toastrService.error('Complete previous step');
      }
      else {
        this.addindexEvent.emit({ index: index_value, step_id: id, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value })
      }
    });
  }

  getAllSteps() {
    let keySend = {
      learning_type: 'Courses',
    }
    this.UserServiceService.get_category(keySend).pipe(first()).subscribe((data: any) => {


      //return false;

      //Loading.hide();
      this.dbdstepActiveMarkAs = 1;
      // this.listing = data.data;

      // console.log("listing", this.listing);

      let id = this.route.snapshot.params.id;

      // data.data.map(name => {

      //   if ('Essential' == name.name) {
      //     this.listing[0] = data.data[1]
      //     console.log("essi", this.listing);

      //   }
      //   else {
      //     return
      //   }

      // })

      let categoryData = data.data

      for (let index = 0; index < categoryData.length; index++) {
        let names = categoryData[index].id;
        if (id == names) {
          this.listing[0] = categoryData[index];
        } else {

        }

      }

      this.step1 = [];
      this.step2 = [];
      this.module1 = [];
      this.parent = [];
      this.subCategories2 = [];//All step index data
      this.assign_module = [];

      for (let module_data of data.assignModuleData) {

        this.assign_module.push(module_data.moduleId);
      }

      for (let item of this.listing) {
        // console.log("skdflsfj kdsfj dslkfj sld", this.listing);

        this.parent.push(item.id);
        for (let module1 of item.sub_categories) {
          this.module1.push(module1.cotegouries);
          for (let step of module1.child_category) {

            this.step1.push(step);
            //this.step2.push(step.contentid);
            this.moduleSteps.push(`${module1.cotegouries}-${step.id}-${item.id}`);
            this.moduleSteps_update.push(`${item.id}-${module1.cotegouries}-${step.id}`);

            this.moduleSteps_new.push(`${module1.cotegouries}-${step.id}-${item.id}-${step.contentid}`);


          }
        }
      }
      /*  console.log('Module with  step  id and parent id', this.moduleSteps)
       console.log('Module full data', this.module1);
       console.log('Module id data', this.module1[0]);
       console.log('Parent full data', this.parent[0]);
       console.log('Parent data', this.parent[0]);
       console.log('step data', this.step1[0]); */


      const result_step = this.step1;
      result_step.map((item: any) => {
        this.subCategories2.push(item.id);
      });



      /* console.log("SubCategory data", this.subCategories1); */

      //  this.module_steps=this.moduleSteps;
      /*   this.c_module_id = this.moduleSteps;
            console.log(this.c_module_id); */
      /* this.UserServiceService.get_complete_status().pipe(first()).subscribe((data: any) => {
        if (data.status_code == 200) {
          //get all completed steps
          this.compeleteindex = [];
          this.subCategories1 = [];
          this.subCategories_module = [];
          this.subCategories_course = [];

          const result = data.data;
          result.map((item) => {
            this.subCategories1.push(item.subcategory_id);
            this.subCategories_module.push(item.category_id);
            this.subCategories_course.push(item.parents_category);

          });
        }

        this.compeleteindex_array = (this.subCategories_module + "-" + this.subCategories1 + "-" + this.subCategories_course)
        this.c_module_id = this.compeleteindex_array;



        console.log("SubCategory data", this.subCategories1);





      }); */
    });



  }

  get_value_index(index_value, id, course_id, module_id, moduleindex_value) {
    this.removeindexEvent.emit({ index: index_value, step_id: id, course_id: course_id, module_id: module_id, moduleindex_value: moduleindex_value })

  }


  completestatuscheck(parantcategory, category, chiledcategory) {

    // console.log('eeeeeeeeee', parantcategory, category, chiledcategory);

    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;
    let indexReturn = this.markascompletedata.indexOf(indexArray);
    // console.log('eeeeeeeeee', indexReturn);

    if (indexReturn >= 0) {
      return false;
    } else {
      return true;
    }

  }

  completestatuscheck_button(parantcategory, category, chiledcategory) {


    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;
    let indexReturn = this.markascompletedata.indexOf(indexArray);
    // console.log('xxxxxxxxxxxxx', indexReturn);

    if (indexReturn >= 0) {
      return true;
    } else {
      return false;
    }
  }
  commentReply(request) {
    request.showReply = !request.showReply;

  }

  onNext() {
    if (this.currentIndex < this.questions.questions.length - 1) {
      this.currentIndex++;
    }
  }

  onPreview() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  onSubmit(que, ass) {
    let submissionData1 = {};

    // for (const que of this.questions.questions) {
    //   let selectedOption = que.options.find(opt => opt.selected);

    //   if (selectedOption) {
    //     submissionData1 = {
    //       assignment: que.assignment,
    //       contact: this.contactId,
    //       question: que.id,
    //       selected_option: [selectedOption.id]
    //     };
    //   }
    // }
    console.log('sssssssssssss', que);
    let keySend = {
      assignment: ass,
      contact: this.contactId,
      question: que.question,
      selected_options: [que.id]
    }

    this.UserServiceService.queSubmit(keySend).pipe(first()).subscribe((data: any) => {
    })
  }
  onRadioButtonChange(a, b) {
    console.log('swerwerew', a);
    this.onSubmit(a, b)
  }
}
