import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-emailswip',
  templateUrl: './emailswip.component.html',
  styleUrls: ['./emailswip.component.scss']
})
export class EmailswipComponent implements OnInit {
  first_name: string;

  constructor() { }
  ngOnInit() {
    let user_details = JSON.parse(localStorage.getItem('user_details'));
    this.first_name =user_details.first_name;
  }

}
