import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService, AlertService } from "../_service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Loading } from '../loader/Loading';
import { HttpClient } from '@angular/common/http';
import { env } from 'process';
const apiUrlCintram = env.apiUrlCintram;

@Component({
  selector: 'app-web-from',
  templateUrl: './web-from.component.html',
  styleUrls: ['./web-from.component.scss'],
})
export class WebFromComponent implements OnInit {

  receivedData: any;
  sanitizedHtmlCode: SafeHtml | undefined;
  routeSub: any;
  webFormId: any;
  webFormValue: any = '';
  showHeader: boolean = false;
  thankYouMessageShow: any;
  webFormHide!: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private router: Router,
    private renderer: Renderer2,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private https: HttpClient
  ) {
  }

  ngOnInit() {
    const navigationState = window.history.state;
    if (navigationState && navigationState.data) {
      this.receivedData = navigationState.data;
      this.showHeader = true;
    } else {
    }
    this.routeSub = this.route.params.subscribe(params => {
      this.webFormId = params['id']
    });
    this.webFromPrivew();
  }



  webFromPrivew() {
    this.UserServiceService.webFromPrivew(this.webFormId).pipe(first()).subscribe((data: any) => {
      if (data.error == false) {
        this.webFormValue = data.data[0];
        this.sanitizedHtmlCode = this.sanitizer.bypassSecurityTrustHtml(this.webFormValue.html_code_s3);
        this.applyCssStyles(this.webFormValue.css_code_s3);

        const testjs = `
                const countryInput = document.querySelector('input[field_name="country"]');
                if (countryInput) {
                    fetch('${apiUrlCintram}/api/admin/country/list')
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('HTTP error! status: ' + response.status);
                            }
                            return response.json();
                        })
                        .then(data => {
                            if (!data || !data.data || !Array.isArray(data.data)) {
                                throw new Error('API response is missing required data');
                            }
                            const selectElement = document.createElement('select');
                            selectElement.id = countryInput.id;
                            selectElement.setAttribute('field_name', countryInput.getAttribute('field_name'));
                            selectElement.setAttribute('required', 'required');
                            selectElement.className = countryInput.className;

                            const placeholderOption = document.createElement('option');
                            placeholderOption.value = "";
                            placeholderOption.textContent = "Select a Country";
                            placeholderOption.disabled = true;
                            placeholderOption.selected = true;
                            selectElement.appendChild(placeholderOption);

                            data.data.forEach(country => {
                                const option = document.createElement('option');
                                option.value = country.code;
                                option.textContent = country.name;
                                selectElement.appendChild(option);
                            });

                            countryInput.parentNode.replaceChild(selectElement, countryInput);
                        })
                        .catch(error => {
                        });
                } else {
                }

                 function populateCountries() {
        fetch('${apiUrlCintram}/api/admin/country/list')
          .then(response => response.json())
          .then(data => {
            const select = document.getElementById('country-list');
            select.innerHTML = '<option value="">Select Country</option>';
            data.data.forEach(country => {
              const option = document.createElement('option');
              option.value = country.code;
              option.textContent = country.name;
              select.appendChild(option);
              console.error('countryInputssssss',select);
            });

            select.addEventListener('change', () => {
              const countryCode = select.value;
              populateStates(countryCode);
            });
          })
          .catch(error => console.error('Error fetching countries:', error));
      }

      function populateStates(countryCode) {
        fetch('${apiUrlCintram}/api/admin/country/' + countryCode + '/states/')
          .then(response => response.json())
          .then(data => {
            const select = document.getElementById('state-list');
            select.innerHTML = '<option value="">Select State</option>';
            data.data.forEach(state => {
              const option = document.createElement('option');
              option.value = state.code;
              option.textContent = state.name;
              select.appendChild(option);
            });
          })
          .catch(error => console.error('Error fetching states:', error));
      }
          populateCountries();

            `;

        setTimeout(() => {
          const script = document.createElement('script');
          script.textContent = testjs;
          document.body.appendChild(script);
        }, 0);
      } else {
        this.alertService.error(data.message, true);
      }
    });
  }

  private applyCssStyles(cssCode: string): void {
    const style = this.renderer.createElement('style');
    this.renderer.appendChild(style, this.renderer.createText(cssCode));
    this.renderer.appendChild(document.head, style);
  }

  onContainerClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (target.tagName === 'BUTTON') {
      this.collectAndValidateFormData().then((formData) => {
        if (formData) {
          this.callApi(formData);
        }
      }).catch((error) => {
        this.alertService.error(error, true);
      });
    }
  }

  private async collectAndValidateFormData(): Promise<FormData | null> {
    const formData = new FormData();
    const inputs = this.el.nativeElement.querySelectorAll('input[field_name], select[field_name]');
    const requiredFields = this.getRequiredFields(inputs);

    let missingRequiredFields: string[] = [];

    inputs.forEach((input: HTMLInputElement) => {
      const fieldName = input.getAttribute('field_name');
      if (fieldName) {
        formData.append(fieldName, input.value);
        if (requiredFields.includes(fieldName) && !input.value) {
          missingRequiredFields.push(fieldName);
        }
      }
    });

    formData.append('webform_id', this.webFormId);

    if (missingRequiredFields.length > 0) {
      const missingFieldsMessage = `The following fields are required: ${missingRequiredFields.join(', ')}`;
      return Promise.reject(missingFieldsMessage);
    }

    return Promise.resolve(formData);
  }

  private getRequiredFields(inputs: NodeListOf<HTMLInputElement>): string[] {
    const requiredFields: string[] = [];
    inputs.forEach((input: HTMLInputElement) => {
      if (input.hasAttribute('required')) {
        const fieldName = input.getAttribute('field_name');
        if (fieldName) {
          requiredFields.push(fieldName);
        }
      }
    });
    return requiredFields;
  }

  private callApi(formData: FormData): void {
    this.UserServiceService.webContact(formData).pipe(first()).subscribe((data: any) => {
      if (data.error === false) {
        if (this.webFormValue.thank_you_pageurl == '') {
          if (this.webFormValue.thank_you_status == 'same_page') {
            if (this.webFormValue.thank_you_message == '') {
              this.alertService.success('Contact created successfully!', true);
            }
            else {
              this.thankYouMessageShow = this.webFormValue.thank_you_message;
            }
          }
          else {
            if (this.webFormValue.thank_you_message == '') {
              this.thankYouMessageShow = 'Contact created successfully!';
            }
            else {
              this.thankYouMessageShow = this.webFormValue.thank_you_message;
              this.webFormHide = true;
            }
          }
        }
        else if (this.webFormValue.thank_you_pageurl != '') {
          if (this.webFormValue.thank_you_status == 'same_page') {
            const url = this.webFormValue.thank_you_pageurl;
            window.location.href = url;
          }
          else {
            const url = this.webFormValue.thank_you_pageurl;
            window.open(url, '_blank');
          }
        }
        else {
          this.alertService.success('Contact created successfully!', true);
        }

      } else {
        this.alertService.error(data.message, true);
      }
    });
  }

}

// webFromPrivew() {
//   Loading.show();
//   this.UserServiceService.webFromPrivew(this.webFormId).pipe(first()).subscribe((data: any) => {
//     if (data.error == false) {
//       Loading.hide();
//       this.webFormValue = data.data[0]
//       this.sanitizedHtmlCode = this.sanitizer.bypassSecurityTrustHtml(this.webFormValue.html_code);
//       this.applyCssStyles(this.webFormValue.css_code);
//     }
//     else {
//       this.alertService.error(data.message, true);
//     }

//   })
// }


// private applyCssStyles(cssCode: string): void {
//   const style = this.renderer.createElement('style');
//   this.renderer.appendChild(style, this.renderer.createText(cssCode));
//   this.renderer.appendChild(document.head, style);
// }
// onContainerClick(event: Event): void {
//   const target = event.target as HTMLElement;
//   if (target.tagName === 'BUTTON') {

//     const first_name = this.getInputValue('input[placeholder="First Name"]');
//     const last_name = this.getInputValue('input[placeholder="Last Name"]');
//     const mobile_number = this.getInputValue('input[placeholder="Mobile Number"]');
//     const email = this.getInputValue('input[placeholder="Email Address"]');
//     const company_name = this.getInputValue('input[placeholder="Company Name"]');
//     this.callApi(first_name, last_name, mobile_number, email, company_name);
//   }
// }

// private getInputValue(selector: string): string {
//   const input = this.el.nativeElement.querySelector(selector) as HTMLInputElement;
//   return input ? input.value : '';
// }


// private callApi(first_name: string, last_name: string, mobile_number: string, email: string, company_name: string): void {
//   const formData = new FormData();
//   if (first_name) {
//     formData.append('first_name', first_name);
//     formData.append('last_name', last_name);
//     formData.append('mobile_number', mobile_number);
//     formData.append('primary_email', email);
//     formData.append('company_name', company_name);
//     formData.append('webform_id', this.webFormId);
//   } else {
//     this.alertService.error('First Name is required ', true);
//     return;
//   }
//   Loading.show();
//   this.UserServiceService.webContact(formData).pipe(first()).subscribe((data: any) => {
//     if (data.error == false) {
//       Loading.hide();
//       this.alertService.success('Contact Create successfully!', true);
//       const resData = { yesSubmit: "YesSubmit", }
//     }
//     else {
//       this.alertService.error(data.message, true);
//     }

//   })
// }