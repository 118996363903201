import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BuisnessComponent } from "./buisness.component";
import { BannerkickComponent } from "./bannerkick/bannerkick.component";
import { EmailkickComponent } from "./emailkick/emailkick.component";
import { LinkkickComponent } from "./linkkick/linkkick.component";
import { BuisnesstrackingComponent } from "./buisnesstracking/buisnesstracking.component";
import { BuisnesspixelComponent } from "./buisnesspixel/buisnesspixel.component";

const routes: Routes = [
 /*  {
    path: "",
    component: BuisnessComponent,
    children: [
      { path: "buisness", component: BuisnessComponent },
      { path: "banner", component: BannerkickComponent },
      { path: "emailswipe", component: EmailkickComponent },
      { path: "tracking", component: BuisnesstrackingComponent },
      { path: "pixelcode", component: BuisnesspixelComponent },
      { path: "link", component: LinkkickComponent },
    ],
  }, */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuisnessRoutingModule {}
