import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtamComponent } from './htam.component';
import { EmailhtamComponent } from './emailhtam/emailhtam.component';
import { BannerhtamComponent } from './bannerhtam/bannerhtam.component';
import { LinkhtamComponent } from './linkhtam/linkhtam.component';
import { HtamRoutingModule } from './htam-routing.module';

import { HtamtrackingComponent } from './htamtracking/htamtracking.component';

import { HtampixelComponent } from './htampixel/htampixel.component';

@NgModule({
  declarations: [
    HtamComponent,
    EmailhtamComponent,
    BannerhtamComponent,
    LinkhtamComponent,
    HtamtrackingComponent,
    HtampixelComponent

  ],
  imports: [
    CommonModule,
    HtamRoutingModule
  ]
})
export class HtamModule { }
