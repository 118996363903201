import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserServiceService, AlertService } from "../_service";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { map, take } from "rxjs/operators";
import { Loading } from "../loader/Loading";
//import { DOCUMENT } from '@angular/common';
import { Renderer2 } from "@angular/core";
import { FaviconService } from "./favicon.service";
//import { faEnvelopeSquare } from 'backend/public/fontawesome-free-5.0.13/fontawesome-free-5.0.13/advanced-options/use-with-node-js/fontawesome-free-solid';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  message: string;
  tagId: string;
  categoryName: any;

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  logoUpdate: any;
  faviconChange: any;
  userCheck: any;
  businessEmail: any;
  brandName: any;
  userInfo: string;
  profile_img: any;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  loginForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private router: Router,
    private renderer: Renderer2,
    // private document: Document,
    private alertService: AlertService,
    private faviconService: FaviconService, 
  ) {
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, "login");
  }
  ngOnInit() {
    this.logoGet();
    this.logoUpdate = localStorage.getItem("logoUpdate");
    this.renderer.addClass(document.body, "login");

    // this.document.body.classList.add('login');

    let data1 = localStorage.getItem("user_details");
    if (data1 != null) {
      {
        let keySend = {
          learning_type: 'Classes',
        }
        this.UserServiceService.get_category(keySend)
          .pipe(first())
          .subscribe((data: any) => {
            this.categoryName = data.data ? data.data[0].name : "";
            if (this.categoryName) {
              localStorage.setItem("categoryName", this.categoryName);
              // this.router.navigate(["/dashboard/course/" + this.categoryName]);
              this.router.navigate(["/dashboard/coaching"]);
            } else {
              // this.router.navigate(["/dashboard/product/"]);
              this.router.navigate(["/dashboard/coaching"]);

              localStorage.setItem("categoryName", "null");
            }

            Loading.hide();

            return this.categoryName;
          });
      }
    }
    //Loading.show();
    this.loginForm = this.formBuilder.group({
      emailId: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  logoGet(): void {
    this.faviconService.logoGet().subscribe(
      (data: any) => {
        const userCheck = data.data.user;
        if (userCheck === '') {
          this.router.navigate(["/page-not-found"]);
        }
        const faviconChange = data.data.favicon;
        const logoUpdate = data.data.logo;
        const businessEmail = data.data.business_email;
        const brandName = data.data.brand_name;

        localStorage.setItem("logoUpdate", logoUpdate);
        localStorage.setItem("brandName", brandName);
        localStorage.setItem('email_set', businessEmail);
        this.faviconService.changeFavicon(faviconChange);
      },
      (error: any) => {
        console.error('Error fetching logo data', error);
      }
    );
  }

  // logoGet() {
  //   let keySend = {
  //     'host': window.location.href,
  //   }
  //   this.UserServiceService.logoGet(keySend).subscribe(
  //     (data: any) => {
  //       this.userCheck = data.data.user;
  //       if (this.userCheck == '') {
  //         this.router.navigate(["/page-not-found"]);
  //       }
  //       this.logoUpdate = data.data.logo;
  //       this.faviconChange = data.data.favicon;
  //       this.businessEmail = data.data.business_email;
  //       this.brandName = data.data.brand_name;

  //       localStorage.setItem("logoUpdate", this.logoUpdate);
  //       localStorage.setItem("brandName", this.brandName)
  //       localStorage.setItem('email_set', this.businessEmail)
  //       this.changeFavicon(this.faviconChange);

  //     })
  // }
  // changeFavicon(faviconUrl: string) {
  //   const link = document.createElement('link');
  //   link.type = 'image/x-icon';
  //   link.rel = 'shortcut icon';
  //   link.href = faviconUrl;
  //   const existingFavicon = document.querySelector("link[rel='shortcut icon']");
  //   if (existingFavicon) {
  //     document.head.removeChild(existingFavicon);
  //   }
  //   document.head.appendChild(link);
  // }
  /* newMessage() {
    this.UserServiceService.changeMessage("Hello from Sibling")
  } */
  get f() {
    return this.loginForm.controls;
  }
  loginSubmit() {
    this.router.navigate(["/content"]);
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.UserServiceService.call_login(this.loginForm.value).subscribe(
      (data: any) => {
        //return false;
        if (data.message == "Invalid credentials") {
          Loading.hide();
          this.alertService.error("Invalid email or password");
          // this.router.navigate(["/login"]);
        }
        else {
          if (data.status_code == 200) {

            //this.UserServiceService.changeMessage("Hello from Sibling");
            //console.log('Login data check123 of order id',data.orderId);
            localStorage.setItem("user_details", JSON.stringify(data.data));
            localStorage.setItem("contactId", data.data.id);
            localStorage.setItem("orderId", data.orderId);
            localStorage.setItem("optId", data.data.on_contact_id);
            localStorage.setItem("tagId", data.tagId1);
            localStorage.setItem("tagId3", data.tagId3);
            localStorage.setItem("tagId88", data.tagId88);
            localStorage.setItem("tagId89", data.tagId89);
            localStorage.setItem("tagId219", data.tagId219);
            localStorage.setItem("tagId220", data.tagId220);
            this.tagId = data.tagId1;
            this.userInfo = localStorage.getItem('user_info');
            this.profile_img = JSON.parse(this.userInfo)
            console.log('sssss', this.profile_img.data.image);
            localStorage.setItem('profile_pic',this.profile_img.data.image);
            //this.UserServiceService.changeMessage("Hello from Sibling");
            // this.UserServiceService.changeMessage()
            //   .pipe(first())
            //   .subscribe((data: any) => {
            //     if (data.status_code == 200) {
            //       this.UserServiceService.changeMessage_tag(this.tagId);
            //       this.UserServiceService.changeMessage1("coach allot");
            //     } else {
            //       this.UserServiceService.changeMessage_tag(this.tagId);
            //       this.UserServiceService.changeMessage1("coach not allot");
            //     }
            //   });

            // this.UserServiceService.changeMessage_data()
            //   .pipe(first())
            //   .subscribe((data: any) => {
            //     if (data.status_code == 200) {
            //       this.UserServiceService.changeMessage_tag(this.tagId);
            //       this.UserServiceService.changeMessage2("Advisor allot");
            //     } else {
            //       this.UserServiceService.changeMessage_tag(this.tagId);
            //       this.UserServiceService.changeMessage2("Advisor not allot");
            //     }
            //   });
            let keySend = {
              learning_type: 'Classes',
            }
            this.UserServiceService.get_category(keySend)
              .pipe(first())
              .subscribe((data: any) => {
                //return false;
                if (data.status_code == 200) {
                  // console.log("avdsccsdc", data);
                  this.UserServiceService.changeMessage_tag(this.tagId);
                  this.UserServiceService.changeMessage_membership(
                    "Membership allot"
                  );
                  // console.log("avds", data);
                  // this.categoryName = data.data ? data.data[0].name : "";
                  // localStorage.setItem("categoryName", this.categoryName);
                  // this.router.navigate([
                  //   "/dashboard/course/" + this.categoryName,
                  // ]);
                  this.router.navigate(["/dashboard/coaching"]);

                } else {
                  // console.log("cvxvcv", data);
                  this.UserServiceService.changeMessage_tag(this.tagId);
                  this.UserServiceService.changeMessage_membership(
                    "Membership not allot"
                  );
                  localStorage.setItem("categoryName", "null");
                  // this.router.navigate(["/dashboard/product"]);
                  this.router.navigate(["/dashboard/coaching"]);

                }
                // this.categoryName = data.data?data.data[0].name:'';

                Loading.hide();
                // window.location.href = '/dashboard/course/' + this.categoryName;
                // window.location.reload();
                // this.router.navigate(['/dashboard/course/' + this.categoryName]);
                let redirectUrl = localStorage.getItem("redirectUrl");

                if (
                  redirectUrl != "" &&
                  redirectUrl != null &&
                  redirectUrl != undefined
                ) {
                  this.router.navigate([redirectUrl]);
                } else {

                  // this.router.navigate([
                  //   "/dashboard/course/" + this.categoryName,
                  // ]);
                  this.router.navigate(["/dashboard/coaching"]);

                }
              });
          } else {
            Loading.hide();
            this.alertService.error("Invalid email or password");
            this.router.navigate(["/login"]);
          }
        }
        // this.users = data.data;
      }
    );
    //console.log('Data of form',this.loginForm.value);
    /* this.UserServiceService.call_login(this.loginForm.value).subscribe((data: any) => {
      //  Loading.show();
      localStorage.setItem('user_details', JSON.stringify(data.data));
      if (data.status_code == 200) {
        Loading.hide();
        this.router.navigate(['/dashboard/course']);
        console.log('User login successfully');
      }
      else {
        Loading.hide();
        this.alertService.error('Invalid email or password');
        // alert('Invalid email or password');
        this.router.navigate(['/login']);
      }
    },
      error => { }
    ); */
  }
}
