import { Component, OnInit } from '@angular/core';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { Loading } from '../../../loader/Loading';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-accountfaq',
  templateUrl: './accountfaq.component.html',
  styleUrls: ['./accountfaq.component.scss']
})
export class AccountfaqComponent implements OnInit {
  faq_list: any;
  faq_data: any;
  id_data: any;
  tagId1:any;

  constructor(private UserServiceService: UserServiceService,
    private router: Router) { }

  ngOnInit() {

    this.tagId1 =   localStorage.getItem('tagId');
    
   /*  if(this.tagId1=='false')
    {
      this.router.navigate(['/dashboard/course']);
    } */
    //alert('test of data');

    this.UserServiceService.get_faq().pipe(first()).subscribe((data: any) => {
                Loading.hide();

      if (data.status == 200) {
        this.id_data = '1';

        this.faq_list = data.data;


      }
      else {
        this.id_data = '2';
        this.faq_data = 'No faqs found';
      }

    });
  }


}
