import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bannerhtam',
  templateUrl: './bannerhtam.component.html',
  styleUrls: ['./bannerhtam.component.scss']
})
export class BannerhtamComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
