import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  loginForm: FormGroup;
  submitted = false;
  imageSrc: string;
  user_details: any;
  dummyPicture: string;
  country_list: any;
  tagId1: any;
  constructor(private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router) { }
  ngOnInit() {

    this.tagId1 = localStorage.getItem('tagId');
      if (this.tagId1 == 'false') {
      this.router.navigate(['/dashboard/course']);
    } 

  }


  menuOpen() {
    
    var moduleMenu = document.getElementById("advertise-right-nav-next-open_1");
    var moduleContainer = document.getElementById("module-container2_1");

    if (moduleMenu.classList.contains("collapsed")) {
      moduleMenu.classList.remove("collapsed");
      moduleMenu.classList.add("collapse");
      moduleContainer.classList.add("expanded");
    } else {
      moduleMenu.classList.add("collapsed");
      moduleMenu.classList.remove("collapse");
      moduleContainer.classList.remove("expanded");
    }
  }
}
