import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  mobnumPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  loginForm: FormGroup;
  submitted = false;
  imageSrc: string;
  user_details: any;
  dummyPicture: string;
  country_list: any;
  constructor(private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router
  ) { }
  ngOnInit() {

    this.dummyPicture = "assets/images/content-profile.png";
    //auth token api test
    this.getauthdetails();
    this.getcountrydetails();
    this.loginForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      lastName: [''],
      file: [''],
      emailId: ['', [Validators.required, Validators.email]],
      mobile: [''],
      skype_id: [''],
      facebook_profile: [''],
      aboutMe: [''],
      country_id: [''],
      address: [''],
      city_id: [''],
      state: [''],
      zip: [''],
      fileSource: [''],
      landline_no: [''],
      /*phoneNo: ['',Validators.required]
      */
      phoneNo: ['', [Validators.required, Validators.pattern(this.mobnumPattern)]],

    });
  }
  onFileChange(event: any) {
    let reader = new FileReader();
    let file = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      //When file uploads set it to file formcontrol
      reader.onload = () => {

        this.loginForm.patchValue({
          fileSource: reader.result
        });
      }
    }
  }
  //Update user details
  get f() { return this.loginForm.controls; }
  userDetails() {
    this.submitted = true;
    if (this.loginForm.invalid) {

      return;
    }
    this.UserServiceService.update_user(this.loginForm.value).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.alertService.success(data.message);
        //Get auth user details
        this.getauthdetails();
      }
      else {

      }
    });
  }
  getauthdetails() {
    //function call to get all users details 
    this.UserServiceService.get_auth_details().pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.user_details = data.data;

        this.loginForm.patchValue({ first_name: this.user_details.first_name });
        this.loginForm.patchValue({ lastName: this.user_details.last_name });
        this.loginForm.patchValue({ emailId: this.user_details.email });
        this.loginForm.patchValue({ skype_id: this.user_details.skype_id });
        this.loginForm.patchValue({ facebook_profile: this.user_details.facebook_profile });
        this.loginForm.patchValue({ country_id: this.user_details.country_id });
        this.loginForm.patchValue({ city_id: this.user_details.city_id });
        this.loginForm.patchValue({ state: this.user_details.state });
        this.loginForm.patchValue({ zip: this.user_details.zip });
        this.loginForm.patchValue({ state: this.user_details.state });
        this.loginForm.patchValue({ landline_no: this.user_details.landline_no });
        this.loginForm.patchValue({ phoneNo: this.user_details.mobile });
        this.loginForm.patchValue({ address: this.user_details.address });
        this.loginForm.patchValue({ aboutMe: this.user_details.about });

      }
      else {

      }
    });
  }

  //get all country details
  getcountrydetails() {
    this.UserServiceService.get_country_list().pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {

        this.country_list = data.data;
      }
    });
  }
  selectChangeHandler(event: any) {
    this.loginForm.patchValue({
      country_id: event.target.value
    });
  }
  //Logout user and destroy authtoken
  logout() {
    this.UserServiceService.logout().pipe(first()).subscribe((data: any) => {
      if (data.status == 200) {
        localStorage.removeItem('user_details');
        this.router.navigate(['/']);
        window.location.reload();
        localStorage.clear();
      }
    });
  }
  menuOpen() {

    var moduleMenu = document.getElementById("advertise-right-nav-next-open_1");
    var moduleContainer = document.getElementById("module-container2_1");

    if (moduleMenu.classList.contains("collapsed")) {
      moduleMenu.classList.remove("collapsed");
      moduleMenu.classList.add("collapse");
      moduleContainer.classList.add("expanded");
    } else {
      moduleMenu.classList.add("collapsed");
      moduleMenu.classList.remove("collapse");
      moduleContainer.classList.remove("expanded");
    }
  }
}
