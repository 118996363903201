import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserServiceService } from "../_service";
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-thanks-you',
  templateUrl: './thanks-you.component.html',
  styleUrls: ['./thanks-you.component.scss']
})
export class ThanksYouComponent implements OnInit {

  successfully:any;
  constructor( 
    private ActivatedRoute: ActivatedRoute,
    private router: Router
    
    ) 
  {

    this.ActivatedRoute.queryParamMap.subscribe((data: any) => {
      this.successfully = data.params.successfully;
      if(this.successfully != "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"){
        this.router.navigate(['/']);
      }
      else if(this.successfully == undefined){
        this.router.navigate(['/']);
      }
      else{
        
      }
      
    });
  }

  ngOnInit() {
    setInterval(() => {
      this.router.navigate(['/']); 
    }, 10000);
  }
}
