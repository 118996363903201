import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { BillingComponent } from './billing/billing.component';
import { UpdateprofileComponent } from './updateprofile/updateprofile.component';
import { UpdatepasswordComponent } from './updatepassword/updatepassword.component';
import { AuthGuard } from '../../auth.guard';
import { SupportComponent } from './support/support.component';
const routes: Routes = [
    {
        path: '', component: ProfileComponent, children: [
            { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
            { path: 'bill', component: BillingComponent, canActivate: [AuthGuard] },
            { path: 'updateprofile', component: UpdateprofileComponent, canActivate: [AuthGuard] },
            { path: 'password', component: UpdatepasswordComponent, canActivate: [AuthGuard] },
            { path: 'support', component: SupportComponent ,canActivate: [AuthGuard] },
        ]
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]

})
export class ProfileRoutingModule { }
