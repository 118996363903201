import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnerdashboard',
  templateUrl: './partnerdashboard.component.html',
  styleUrls: ['./partnerdashboard.component.scss']
})
export class PartnerdashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
