import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../_service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Loading } from '../loader/Loading';
@Component({
  selector: 'app-forgerpassword',
  templateUrl: './forgerpassword.component.html',
  styleUrls: ['./forgerpassword.component.scss']
})
export class ForgerpasswordComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  logoUpdate: any;
  constructor(
    private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private router: Router,
    private alertService: AlertService
  ) { }
  ngOnInit() {
    this.logoUpdate = localStorage.getItem('logoUpdate');
    this.loginForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.email]],
    });
  }

  get f() { return this.loginForm.controls; }
  loginSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    //console.log('Data of form',this.loginForm.value);
    this.UserServiceService.call_forgetpassword(this.loginForm.value).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        Loading.hide();
        this.alertService.success('Password sent to mail');
      }
      else {
        Loading.hide();
        this.alertService.error('Invali email address');
      }
    },
      error => { }
    );
  }

}
