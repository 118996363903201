import { Component, ChangeDetectorRef, Attribute } from '@angular/core';
import grapesjs from 'grapesjs';
import { Grids } from '../../../jsons/Grid'
import { Styles } from '../../../jsons/StyleMngr'
import { WebPopupService } from "../web-builder-popup/web-popup.service";
// import 'grapesjs-blocks-basic';
// import "grapesjs-blocks-basic";

@Component({
  selector: 'web-builder',
  templateUrl: './web-builder.component.html',
  styleUrls: ['./web-builder.component.scss']
})
export class WebBuilder {
  editor: any;
  blockManager = true;
  stylmngr = false;
  title = 'SiteBuilder';
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: WebPopupService
  ) { }
  ngOnInit() {
    this.tro();
  }

  tro() {
    this.editor = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: '#gjs',
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: true,
      // Size of the editor
      height: '590px',
      width: 'auto',
      // Disable the storage manager for the moment
      storageManager: false,
      // Avoid any default panel
      deviceManager: {
        devices: [
          {
            name: 'Desktop',
            width: '', // default size
          }, {
            name: 'Mobile',
            width: '320px', // this value will be used on canvas width
            widthMedia: '480px', // this value will be used in CSS @media
          },
          {
            name: 'Tablet',
            width: '768px',
            widthMedia: '992px', // this value will be used in CSS @media
          }
        ]
      },
      panels: { defaults: [] },
      blockManager: {
        appendTo: '#blocks',
      },
      styleManager: {
        appendTo: '#stylemngr',
      }
    });

    // this.editor.getConfig().showDevices = 1;
    // this.editor.Commands.add('show-layers', {
    //   getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
    //   getLayersEl(row) { return row.querySelector('.layers-container') },

    //   run(editor, sender) {
    //     const lmEl = this.getLayersEl(this.getRowEl(editor));
    //     lmEl.style.display = '';
    //   },
    //   stop(editor, sender) {
    //     const lmEl = this.getLayersEl(this.getRowEl(editor));
    //     lmEl.style.display = 'none';
    //   },
    // });
    // this.editor.Commands.add('show-styles', {
    //   getRowEl(editor) { return editor.getContainer().closest('.editor-row'); },
    //   getStyleEl(row) { return row.querySelector('.styles-container') },

    //   run(editor, sender) {
    //     const smEl = this.getStyleEl(this.getRowEl(editor));
    //     smEl.style.display = '';
    //   },
    //   stop(editor, sender) {
    //     const smEl = this.getStyleEl(this.getRowEl(editor));
    //     smEl.style.display = 'none';
    //   },
    // });

    this.editor.on('component:selected', function (args) {
      if (args.attributes['custom-name'] === 'Body') {
        args.attributes.resizable = false;
      }
      else {
        args.attributes.resizable = true;

      }

    });

    this.editor.Commands.add('set-device-desktop', {
      run: editor => editor.setDevice('Desktop')
    });
    this.editor.Commands.add('set-device-mobile', {
      run: editor => editor.setDevice('Mobile')
    });
    this.editor.Commands.add('set-device-tablet', {
      run: editor => editor.setDevice('Tablet')
    });

    const _that = this;
    this.editor.Panels.addPanel({
      id: 'panel-top',
      el: '.panel__top',
    });
    this.editor.Panels.addPanel({
      id: 'basic-devices',
      el: '.panel__devices',
      buttons: [
        {
          id: 'device-desktop',
          attributes: {
            class: 'fa fa-desktop'
          },
          command: 'set-device-desktop',
          active: true,
          togglable: false,
        },
        {
          id: 'device-tablet',
          attributes: {
            class: 'fa fa-tablet'
          },
          command: 'set-device-tablet',
          togglable: false,
        },
        {
          id: 'device-mobile',
          attributes: {
            class: 'fa fa-mobile'
          },
          command: 'set-device-mobile',
          togglable: false,
        },
        // {
        //   id: 'show-toggle',
        //   className: 'btn-show-toggle',
        //   label: 'Toggle',
        //   context: 'show-toggle',
        //   command(editor) {
        //     console.log("tru", _that.editor.StyleManager);

        //     _that.stylmngr = !_that.stylmngr
        //   }
        // },
      ],
    });

    this.editor.Panels.addPanel({
      id: 'basic-actions',
      el: '.panel__basic-actions',
      buttons: [
        {
          id: 'visibility',
          active: true, // active by default
          className: 'btn-toggle-borders',
          attributes: {
            class: 'fa fa-square-o '
          },
          command: 'sw-visibility', // Built-in command
        },
        {
          id: 'preview',
          active: false, // active by default
          className: 'btn-preview',
          attributes: {
            class: 'fa fa-eye'
          },
          command: 'preview', // Built-in command
        },
      ]
    });

    // this.editor.onclick = e => {
    //   console.log(e.target);  // to get the element
    //   console.log(e.target.tagName);  // to get the element tag name alone
    // }
    // wrapper.set('style', { 'background-color': 'red' });

    // this.editor.Panels.removeButton('options', ['fullscreen', 'preview', 'export-template', 'open-sm', 'sw-visibility', 'open-tm', 'open-layers', 'open-blocks'])

    // this.editor.Panels.getPanels().remove('views-container')

    // this.tr2();
    this.blkfn();
    this.fnStylMngr();
    this.fnWraperMngr();
  }

  fnWraperMngr() {
    var _that = this;
    this.editor.DomComponents.addType('wrapper', {
      model: {
        defaults: {
          // other model properties
          toolbar: [
            {
              attributes: {
                class: 'fa fa-arrow-up'
              },
              command: function command(ed) {
                return ed.runCommand('core:component-exit', {
                  force: 1
                });
              }
            },
            {
              attributes: {
                class: 'fa fa-plus'
              },
              command: (ed) => {
                _that.blockManager = true;
                _that.stylmngr = false;
                _that.changeDetectorRef.detectChanges();
              }
            },
            {
              attributes: { class: 'fa fa-arrows' },
              command: 'tlb-move',
            }, {
              attributes: { class: 'fa fa-cog' },
              command: (ed) => {
                _that.blockManager = false;
                _that.stylmngr = true;
                _that.changeDetectorRef.detectChanges();

              }
            }, {
              attributes: { class: 'fa fa-trash-o' },
              command: 'tlb-delete'
            }, {
              attributes: { class: 'fa fa-clone' },
              command: 'tlb-clone',
            }],
        }
      }
    });
  }

  fnStylMngr() {
    var _that = this;
    this.editor.DomComponents.addType('default', {
      model: {
        defaults: {
          // other model properties
          toolbar: [
            {
              attributes: {
                class: 'fa fa-arrow-up'
              },
              command: function command(ed) {
                return ed.runCommand('core:component-exit', {
                  force: 1
                });
              }
            },
            {
              attributes: {
                class: 'fa fa-cog'
              },
              command: (ed) => {
                // this.mdlFn();
                // return;
                _that.stylmngr = true;
                _that.blockManager = false;
                _that.openModal('custom-modal-1');
                _that.changeDetectorRef.detectChanges();
              }
            }, {
              attributes: { class: 'fa fa-trash-o' },
              command: 'tlb-delete'
            }],
        }
      }
    });
  }

  forDivfn() {
    this.fntmgrfn();
    this.stylmgrFn();
    this.bordrmgrFn();
    this.positionmgrFn();
    this.marginmgrFn();
    this.paddingmgrFn();
    this.transitionmgrFn();
    this.transformmgrFn();
    this.flexmgrFn();
    this.ExtramgrFn();
  }

  fntmgrfn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.backgroundmngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }

  stylmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.fontMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }

  bordrmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.bordrMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }

  positionmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.positionMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  marginmgrFn() {
    // alert("hey");
    // return;
    let sm = this.editor.StyleManager;
    var smm = Styles.marginMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  paddingmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.paddingMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  transitionmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.transitionMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  transformmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.transformMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  flexmgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.flexMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }
  ExtramgrFn() {
    let sm = this.editor.StyleManager;
    var smm = Styles.extraMngr;
    for (let i = 0; i < smm.length; i++) {
      sm.addSector(smm[i].name, {
        name: smm[i].name,
        open: smm[i].open,
        buildProps:
          smm[i].buildProps
      });
    }
  }

  blkfn() {
    let bm = this.editor.BlockManager;
    var components = Grids.components;
    for (var i = 0; i < components.length; i++) {
      if (components[i].id && components[i].data) {
        bm.add(components[i].id, components[i].data, components[i].data.category)
      }
    }
  }

  tr2() {
    var bm = this.editor;
    bm.Panels.addPanel({
      id: 'panel-switcher',
      el: '.panel__switcher',
      buttons: [
        {
          id: 'show-style',
          active: true,
          label: 'Grids',
          command: 'show-styles',
          togglable: false,
        }],
      selectorManager: {
        appendTo: '.styles-container'
      },
    });
  }

  // tstng() {
  //   var edr = this.editor.StyleManager;
  //   var apnd = {
  //     appendTo: '#gjs-stylemanager'
  //     // defaults:[
  //     // ]
  //   }
  //   this.forDivfn();
  //   console.log("anupam", edr);

  // }

  // mdlFn() {
  //   let _that = this;
  //   const pfx = this.editor.getConfig().stylePrefix;
  //   console.log("style", pfx);
  //   const modal = this.editor.Modal;
  //   const container = document.createElement('div');
  //   // return;
  //   this.tstng();
  //   const inputHtml = `<div id= '${pfx}stylemanager'></div>`;

  //   const btnEdit = document.createElement('button');
  //   btnEdit.innerHTML = 'Submit';
  //   btnEdit.className = pfx + 'btn-prim ' + pfx + 'btn-import';
  //   btnEdit.onclick = function () {
  //     _that.marginmgrFn();
  //     // modal.close();
  //   };

  //   modal.setTitle('Create Identity');
  //   container.innerHTML = inputHtml;
  //   container.appendChild(btnEdit);
  //   modal.setContent(container);
  //   modal.open();
  // }

  openModal(id: string) {
    this.modalService.open(id);
    this.fntmgrfn();
  }
}
