import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService, UserServiceService } from 'src/app/_service';

@Component({
  selector: 'app-module-and-lesson',
  templateUrl: './module-and-lesson.component.html',
  styleUrls: ['./module-and-lesson.component.scss']
})
export class ModuleAndLessonComponent implements OnInit {
  receivedData: any;
  modulesData: any;
  contactId: string;
  courseId: any;

  constructor(
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log('-======================',this.router.url); 
    this.contactId = localStorage.getItem('contactId');
    this.courseListApi();
  }
  menuOpen() {

    var moduleMenu = document.getElementById("advertise-right-nav-next-open_1");
    var moduleContainer = document.getElementById("module-container2_1");

    if (moduleMenu.classList.contains("collapsed")) {
      moduleMenu.classList.remove("collapsed");
      moduleMenu.classList.add("collapse");
      moduleContainer.classList.add("expanded");
    } else {
      moduleMenu.classList.add("collapsed");
      moduleMenu.classList.remove("collapse");
      moduleContainer.classList.remove("expanded");
    }
  }
  courseListApi() {
    let keySend = {
      // this.courseId
      course_id: '6',
      contact_id: this.contactId
    }
    this.UserServiceService.getModulesList(keySend).pipe(first()).subscribe((data: any) => {
      this.modulesData = data.data;
    })
  }
}
