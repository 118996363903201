import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-linkvideo',
  templateUrl: './linkvideo.component.html',
  styleUrls: ['./linkvideo.component.scss']
})
export class LinkvideoComponent implements OnInit {
  id: any;
  constructor(private route: ActivatedRoute, private router: Router, private UserServiceService: UserServiceService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      /*  this.UserServiceService.get_products_details({ course_id: id }).pipe(first()).subscribe((data: any) => {
       }); */
    });

  }

}
