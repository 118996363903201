import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CourseComponent } from './course/course.component';
import { ProductComponent } from './product/product.component';
import { CoursedetailsComponent } from './coursedetails/coursedetails.component';
import { CoachComponent } from './coach/coach.component';
import { WebBuilder } from './web-builder/web-builder.component';
import { AuthGuard } from '../auth.guard';
import { AdvisorComponent } from './advisor/advisor.component';
import { OverviewComponent } from './overview/overview.component';
import { Steps } from './steps/steps.component';
import { ScheduleCall } from "./scheduleCall/schedule-call.component";
import { NewCourseComponent } from './new-course/new-course.component';
import { ModuleAndLessonComponent } from './new-course/module-and-lesson/module-and-lesson.component';
import { NewClassComponent } from './new-class/new-class.component';
import { ClassComponent } from './class/class.component';


const routes: Routes = [
    {
        path: '', component: DashboardComponent, children: [

            /* New code  */
            { path: 'course', component: NewCourseComponent, canActivate: [AuthGuard] },
            { path: 'course-module/:id', component: ModuleAndLessonComponent, canActivate: [AuthGuard] },
            { path: 'coaching', component: NewClassComponent, canActivate: [AuthGuard] },
            { path: 'coaching/:id', component: ClassComponent, canActivate: [AuthGuard] },
            
            /* end */
            { path: 'course/:id', component: CourseComponent, canActivate: [AuthGuard] },
            { path: 'steps/:id', component: Steps, canActivate: [AuthGuard] },
            { path: 'web-builder', component: WebBuilder, canActivate: [AuthGuard] },
            { path: 'product', component: ProductComponent, canActivate: [AuthGuard] },
            { path: 'course_details/:id', component: CoursedetailsComponent, canActivate: [AuthGuard] },
            { path: 'coach', component: CoachComponent, canActivate: [AuthGuard] },
            { path: 'overview/:id', component: OverviewComponent, canActivate: [AuthGuard] },
            { path: 'advisor', component: AdvisorComponent, canActivate: [AuthGuard] },
            { path: 'schedule-call', component: ScheduleCall, canActivate: [AuthGuard] },


            { path: 'profile', loadChildren: './profile/profile.module#ProfileModule', canActivate: [AuthGuard] },

            { path: 'account', loadChildren: './account/account.module#AccountModule', canActivate: [AuthGuard] },

        ]
    },

]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }