import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { first, map } from 'rxjs/operators';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
} from 'date-fns';
import { Observable } from 'rxjs';
import { colors } from './colors';
import { UserServiceService, AlertService } from '../../_service';
import { Router } from '@angular/router';
import { SuccessCallSchedule } from "./SuccessSchedule/SuccessCallSchedule.component";

interface Film {
  id: number;
  title: string;
  release_date: string;
}

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'schedule-call.component.html',
})
export class ScheduleCall implements OnInit {

  displayedColumns: string[] = ['time'];
  dataSource: any = [];

  isLinear = false;
  firstFormGroup: FormGroup;
  view: CalendarView = CalendarView.Month;
  locale: string = 'en';
  viewDate: Date = new Date();

  events$: Observable<CalendarEvent<{ film: Film }>[]>;

  activeDayIsOpen: boolean = false;
  dataSc: any;
  ViewProjectData: any;
  timeschedule: boolean = false;
  userInfo: any;
  dateselected: any;
  timeselected: any;
  isCompleted: boolean = true;
  appointmentTypeID: string;
  firstName: string;
  lastName: string;
  email: string;
  coachEmail: string;
  coach_details: any;
  image_src: any;
  first_name: any;
  last_name: any;
  mobile: any;
  skype_id: any;
  schedule_link: any;
  videoUrl: any;
  calendarID: any;
  callScheduleType: any;
  timeZone: any;
  to_uid: any;
  from_uid: any;
  call_data: any;
  isDisabled: boolean = false;
  ClickedRow: any;
  HighlightRow: Number;
  coachType: string;
  constructor(
    private http: HttpClient,
    private UserServiceService: UserServiceService,
    private _formBuilder: FormBuilder,
    public datepipe: DatePipe,
    public cd: ChangeDetectorRef,
    public toastrService: ToastrService,
    private router: Router,
    private dialog: MatDialog,
  ) { }
  ngOnInit(): void {
    let userData = localStorage.getItem('user_info');
    this.userInfo = JSON.parse(userData);
    this.firstName = this.userInfo.data.first_name;
    this.lastName = this.userInfo.data.last_name;
    this.email = this.userInfo.data.email;
    this.from_uid = this.userInfo.data.user_id

    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.UserServiceService.get_schedule_call_details().pipe(first()).subscribe((data: any) => {

      if (data.status_code == '200') {

        if (data.data != undefined) {
          this.call_data = data.data.call.split(",");
          if (this.call_data.length === 8) {
            return this.isDisabled = true;
          }

        }
        else {

        }
      }

    });
    this.firstFormGroup = this._formBuilder.group({
      first_name: [this.firstName],
      last_name: [this.lastName],
      email: [this.email],
      dateSelected: [''],
      timeSelected: [''],
      datetime: [''],
      appointmentTypeID: [''],
      selectorType: [''],
      from_uid: [''],
      to_uid: [''],
      timezone: [''],
      userForName: [''],
      userByName: ['']
    });
    this.callScheduleType = localStorage.getItem('callScheduleType');
    // if (this.callScheduleType === 'coach') {
    //   this.appointmentTypeID = '19436017';
    //   this.calendarID = '5037481'
    // } else if (this.callScheduleType === 'advisor') {
    //   this.appointmentTypeID = '19436834';
    //   this.calendarID = '4924920';
    // }

    if (this.callScheduleType === 'coach') {

      this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {
        if (data.status_code == 200) {
          this.coach_details = data.data;
          this.image_src = this.coach_details.url;
          this.first_name = this.coach_details.first_name;
          this.last_name = this.coach_details.last_name;
          this.coachEmail = this.coach_details.email;
          this.to_uid = this.coach_details.id;
          this.firstFormGroup.value.userByName = this.first_name + ' ' + this.last_name;
          this.coachType = 'Coach'
        }
      });
    }
    else if (this.callScheduleType === 'advisor') {

      this.UserServiceService.advisor_detail().pipe(first()).subscribe((data: any) => {
        if (data.status_code == 200) {

          this.coach_details = data.data;
          this.image_src = this.coach_details.url;
          this.first_name = this.coach_details.first_name;
          this.last_name = this.coach_details.last_name;
          this.coachEmail = this.coach_details.email;
          this.to_uid = this.coach_details.id;
          this.firstFormGroup.value.userByName = this.first_name + ' ' + this.last_name;
          this.coachType = 'Advisor'
        }
      });

    }


    // this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {
    //   // Loading.hide();
    //   // this.isLoading = false;

    //   //return false;
    //   if (data.status_code == 200) {
    //     this.coach_details = data.data;
    //     this.image_src = this.coach_details.url;
    //     this.first_name = this.coach_details.first_name;
    //     this.last_name = this.coach_details.last_name;
    //     this.coachEmail = this.coach_details.email;
    //     this.mobile = this.coach_details.mobile;
    //     this.skype_id = this.coach_details.skype_id;
    //     this.schedule_link = this.coach_details.schedule_link;


    //     // if (this.coach_details.upload_video != null) {
    //     //   this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.coach_details.upload_video);

    //     // }
    //     // else {
    //     //   this.videoUrl = '';
    //     // }

    //     //   this.UserServiceService.get_schedule_call_details().pipe(first()).subscribe((data: any) => {

    //     //     if (data.status_code == '200') {

    //     //       if (data.data != undefined) {
    //     //         this.call_data = data.data.call.split(",");

    //     //       }
    //     //       else {

    //     //       }
    //     //     }

    //     //   });


    //     // }
    //     // else {
    //     //   //alert('not found');
    //     //   this.alertService.success('No coach alloted');
    //     //   // this.router.navigate(['/dashboard/product']);

    //   }
    // });





    this.firstFormGroup.controls['first_name'].disable();
    this.firstFormGroup.controls['last_name'].disable();
    this.firstFormGroup.controls['email'].disable();
    this.firstFormGroup.controls['dateSelected'].disable();
    this.firstFormGroup.controls['timeSelected'].disable();
    this.firstFormGroup.controls['datetime'].disable();
    this.fetchEvents();
  }

  fetchEvents(): void {
    // const getStart: any = {
    //   month: startOfMonth,
    //   week: startOfWeek,
    //   day: startOfDay,
    // }[this.view];

    // const getEnd: any = {
    //   month: endOfMonth,
    //   week: endOfWeek,
    //   day: endOfDay,
    // }[this.view];


    // const params = new HttpParams()
    //   .set(
    //     'primary_release_date.gte',
    //     format(getStart(this.viewDate), 'yyyy-MM-dd')
    //   )
    //   .set(
    //     'primary_release_date.lte',
    //     format(getEnd(this.viewDate), 'yyyy-MM-dd')
    //   )
    //   .set('api_key', '0ec33936a68018857d727958dca1424f');

    let apicalls;
    apicalls = {
      // "appointmentTypeID": this.appointmentTypeID,
      "month": this.viewDate,
      // "calendarID": this.calendarID,
      "selectorType": this.callScheduleType,
      "timezone": this.timeZone,
    };

    this.events$ = this.UserServiceService.availableDate(apicalls)
      .pipe(
        map((results: any) => {

          return results.map((film: any) => {
            return {
              title: 'DBS',
              start: new Date(
                film.date
              ),
              color: colors.green,
              allDay: true,
              meta: {
                film,
              }
            }
          })
        })
      )
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent<{ film: Film }>[];
  }): void {
    this.HighlightRow = undefined;
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }

    this.getTime(date);
  }

  eventClicked(event: CalendarEvent<{ film: Film }>): void {
    // window.open(
    //   `https://www.themoviedb.org/movie/${event.meta.film.id}`,
    //   '_blank'
    // );
  }


  getTime(date) {
    let latest_date = this.datepipe.transform(date, 'yyyy-MM-dd');

    let apical;
    apical = {
      // "appointmentTypeID": this.appointmentTypeID,
      // "calendarID": this.calendarID,
      "selectorType": this.callScheduleType,
      "date": latest_date,
      "timezone": this.timeZone,
    }
    this.UserServiceService.availableTime(apical).pipe(first()).subscribe((data: any) => {
      this.ViewProjectData = data;
      if (this.ViewProjectData.length === 0) {
        this.timeschedule = false;
        this.cd.detectChanges();
      } else {
        this.dateselected = latest_date;
        this.firstFormGroup.value.dateSelected = this.dateselected;
        // this.dataSource = this.ViewProjectData;
        this.dataSource = Object.values(this.ViewProjectData);

        this.timeschedule = true;
        this.cd.detectChanges();
      }
      this.isCompleted = false;
      // const ELEMENT_DATA: PeriodicElement[] = this.ViewProjectData;
    });

  }

  selectTime(time, index) {
    this.ClickedRow = function (index) {
      this.HighlightRow = index;
    };
    this.timeselected = time;
    this.firstFormGroup.value.timeSelected = time;
    this.firstFormGroup.value.datetime = time;
    this.isCompleted = false;
    this.firstFormGroup.value.first_name = this.firstName;
    this.firstFormGroup.value.last_name = this.lastName;
    this.firstFormGroup.value.email = this.email;
    // this.firstFormGroup.value.timeSelected = time;

  }
  // toggleShow() {
  //   this.timeschedule = true;
  // }

  getSchedule() {
    this.firstFormGroup.value.selectorType = this.callScheduleType;
    this.firstFormGroup.value.to_uid = this.to_uid;
    this.firstFormGroup.value.from_uid = this.from_uid;
    this.firstFormGroup.value.timezone = this.timeZone;
    this.firstFormGroup.value.userForName = this.firstName + ' ' + this.lastName;
    // let apicalls;
    // apicalls = {
    //   "appointmentTypeID": "19436017",
    //   "date": "2021-01-26T13:30:00+0000",
    //   "calendarID": "4924920",
    //   "firstName": "anupam",
    //   "lastName": "Kushwaha",
    //   "email": "anu@gm.co"
    // }

    this.UserServiceService.makeAppointment(this.firstFormGroup.value).pipe(first()).subscribe((data: any) => {
      // this.dataSc = data;
      if (data.status_code != 400) {
        this.toastrService.success('Appointment Scheduled Successfully');
        // setTimeout(() => {
        //   this.router.navigate(['/dashboard/course']);
        // }, 3000);
        this.timeselected = '';
        this.dateselected = '';
        this.timeschedule = false;
        this.isCompleted = true;
        data.scheduledBy = this.firstName + ' ' + this.lastName;
        data.scheduledwith = this.first_name + ' ' + this.last_name;
        data.callScheduleType = this.callScheduleType;
        this.openDialog(data);
        this.cd.detectChanges();
      }
      else {
        this.toastrService.error(data.message)
      }
    })
  }

  openDialog(data) {
    const dialogref = this.dialog.open(SuccessCallSchedule, {
      width: '40%',
      height: 'auto%',
      panelClass: 'myClass',

      data: data
    });
    dialogref.afterClosed().subscribe(result => {
      if (result == "YesSubmit") {
        // this.singleViewProjects();
      }
    });
  }

  resetAllData() {
    // this.openDialog();
    this.timeselected = '';
    this.dateselected = '';
    this.timeschedule = false;
    this.isCompleted = true;
    this.cd.detectChanges();
  }
}


export interface PeriodicElement {
  // slotsAvailable: any;
  time: any;
}
