import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoComponent } from './video.component';


import { EmailvideoComponent } from './emailvideo/emailvideo.component';
import { BannervideoComponent } from './bannervideo/bannervideo.component';
import { LinkvideoComponent } from './linkvideo/linkvideo.component';
import { VideotrackingComponent } from './videotracking/videotracking.component';

import { VideopixelComponent } from './videopixel/videopixel.component';



const routes: Routes = [
  {
    path: '', component: VideoComponent, children:
      [
        { path: 'video/:id', component: VideoComponent },
        { path: 'banner', component: BannervideoComponent },
        { path: 'emailswipe', component: EmailvideoComponent },
        { path: 'tracking', component: VideotrackingComponent },
        { path: 'pixelcode', component: VideopixelComponent },
        { path: 'link/:id', component: LinkvideoComponent },

      ]
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VideoRoutingModule { }
