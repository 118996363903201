import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Loading } from '../../../loader/Loading';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;


  constructor(private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      subject: ['', Validators.required],
      message: ['', Validators.required],

    });
  }
  get f() { return this.loginForm.controls; }
  addSupport() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.UserServiceService.add_support(this.loginForm.value).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.alertService.success(data.message);
      /*  else {
         console.log('Invalid token');
       } */
    });
  }
}
