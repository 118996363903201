import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buisnesspixel',
  templateUrl: './buisnesspixel.component.html',
  styleUrls: ['./buisnesspixel.component.scss']
})
export class BuisnesspixelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
