import { Component, OnInit } from '@angular/core';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { Loading } from '../../../loader/Loading';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-accountoffers',
  templateUrl: './accountoffers.component.html',
  styleUrls: ['./accountoffers.component.scss']
})
export class AccountoffersComponent implements OnInit {
  offer_list: any;
  offer_description: any;
  id_data: any;
  offer_message: any;
  tagId1:any;

  constructor(private UserServiceService: UserServiceService,private router: Router
    ) { }

  ngOnInit() {
      this.tagId1 = localStorage.getItem('tagId');
     // alert(this.tagId1);
    /* if(this.tagId1=='false')
    {
      this.router.navigate(['/dashboard/course']);
    } */ 
    
    this.UserServiceService.get_offers().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.offer_list = data.data;
      if (data.status == 200) {
        this.id_data = '1';

      }
      else {
        this.id_data = '2';
        this.offer_message = "No offers found";

      }

    });
  }

}
