import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateSidemenuSataService {
  private profilePicSubject = new BehaviorSubject<string>('');
  profile_pic$ = this.profilePicSubject.asObservable();

  updateProfilePic(profile_pic: string) {
    this.profilePicSubject.next(profile_pic);
  }
}
