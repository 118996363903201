import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailkick',
  templateUrl: './emailkick.component.html',
  styleUrls: ['./emailkick.component.scss']
})
export class EmailkickComponent implements OnInit {
  first_name:string;
  constructor() { }

  ngOnInit() {
    let user_details = JSON.parse(localStorage.getItem('user_details'));

    this.first_name =user_details.first_name;
  }

}
