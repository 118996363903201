import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";


@Component({
    selector: 'notes-description',
    templateUrl: 'notes-description.component.html',
})

export class NotesDescription {
    scheduledBy: string;
    callScheduleType: string;
    scheduledwith: string;
    dateTime: any;
    date: any;
    time: any;
    type: string;
    day: any;
    note: any;
    constructor(
        public dialogRef: MatDialogRef<NotesDescription>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit(): void {
        this.scheduledBy = this.data.scheduledBy;
        this.scheduledwith = this.data.scheduledwith;
        this.type = this.data.type;
        this.callScheduleType = this.data.callScheduleType;
        this.dateTime = this.data.datetime;
        this.day = new Date(this.data.date);
        this.note = this.data.notes
        

        this.date = this.data.date;
        this.time = this.data.time;

    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}