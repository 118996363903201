import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserServiceService } from "./_service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = 'my-first-project';
  logoUpdate: any;
  faviconChange: any;
  userCheck: any;
  brandName: any;
  businessEmail: any;
  constructor(public router: Router,
    private UserServiceService: UserServiceService,
    private titleService: Title,) {

  }
  ngOnInit() {
    let rlocation = location.hash.split("#", -1);
    let redirecturl = rlocation[1];
    if (rlocation[1] === "/dashboard/coach") {
      localStorage.setItem("redirectUrl", redirecturl);
    } else if (rlocation[1] === "/dashboard/advisor") {
      localStorage.setItem("redirectUrl", redirecturl);
    }
    else if (rlocation[1] === '/dashboard/advisor') {
      localStorage.setItem('redirectUrl', redirecturl);
    }
    this.logoGet();
  }

  logoGet() {
    let keySend = {
      'host': window.location.href,
    }
    this.UserServiceService.logoGet(keySend).subscribe(
      (data: any) => {
        this.userCheck = data.data.user;
        if (this.userCheck == '') {
          this.router.navigate(["/page-not-found"]);
        }
        this.logoUpdate = data.data.logo;
        this.faviconChange = data.data.favicon;
        this.brandName = data.data.brand_name;
        this.businessEmail = data.data.business_email;
        this.titleService.setTitle(this.brandName);
        // this.titleService.getTitle().subscribe(title => {
        //   this.brandName = title;
        // });
        localStorage.setItem("brandName", this.brandName)
        localStorage.setItem('email_set', this.businessEmail)
        localStorage.setItem("logoUpdate", this.logoUpdate);
        this.changeFavicon(this.faviconChange);

      })
  }
  changeFavicon(faviconUrl: string) {
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;

    const existingFavicon = document.querySelector("link[rel='shortcut icon']");
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    document.head.appendChild(link);
  }
}
