import { Component, OnInit } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Observable } from 'rxjs';
import { UserServiceService, AlertService } from '../_service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoggedIn$: Observable<boolean>;
  data1: any;
  constructor(private UserServiceService: UserServiceService,
  ) { }
  ngOnInit() {
    this.data1 = localStorage.getItem('user_details');
    this.isLoggedIn$ = this.UserServiceService.isLoggedIn;
    //}
  }

}



/* import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
 */