import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../../_service';
import { first } from 'rxjs/operators';
import { Loading } from '../../loader/Loading';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  product_list: any;
  product_id: any;
  subCategories1: any;
  assignId: any;
  token: any;
  name: string;
  constructor(private UserServiceService: UserServiceService,
  ) { }
  ngOnInit() {

    this.UserServiceService.get_product_details().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.product_list = data.data;
      //this.product_id = typeof(data.data1);
      this.product_id = data.data1;
      var tokn = JSON.parse(localStorage.getItem('user_details'));
      this.token = tokn.access_token;
      this.name = data.data;
      this.subCategories1 = [];
      const result = data.data1;
      result.map((item) => {
        this.subCategories1.push(item);
      });
      this.assignId = [];
      for (let i = 0; i < this.product_id.length; i++) {
        this.assignId = this.product_id[i].split(",");

      }
      this.product_list.forEach(o => {
        o.visible = false;
        if (this.assignId.some(r => r.includes(o.id)))
          o.visible = true;
      });
      return false;
    });
  }
  tro(id) {
    
      localStorage.setItem("product_id", id)
  }
}
