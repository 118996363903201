export class Styles {

    public static fontMngr = [
        {
            name: 'Text',
            open: false,
            buildProps: [
                'font-family',
                'font-weight',
                'font-size',
                'text-align',
                'letter-spacing',
                'line-height',
                'text-shadow',
                'color',
            ]
        }
    ]
    public static backgroundmngr = [
        {
            name: 'Background Styling',
            open: true,
            buildProps: [
                'background-color',
                'background',
                'background-image',
                'background-repeat',
                'background-position',
                'background-attachment',
                'background-size',
            ]
        },
    ]
    public static bordrMngr = [
        {
            name: 'Border Styling',
            open: false,
            buildProps: [
                'border-style',
                'border-color',
                'border-width',
                'border-top-left-radius',
                'border-top-right-radius',
                'border-bottom-left-radius',
                'border-bottom-right-radius',
            ]
        }
    ]
    public static marginMngr = [
        {
            name: 'Margin Styling',
            open: false,
            buildProps: [
                'margin-top',
                'margin-right',
                'margin-bottom',
                'margin-left',
            ]
        }
    ]
    public static positionMngr = [
        {
            name: 'Position Styling',
            open: false,
            buildProps: [
                'float',
                'top',
                'right',
                'bottom',
                'left',
                'position',
                'display',
                'width',
                'height',
                'min-width',
                'min-height',
                'max-width',
                'max-height',
            ]
        }
    ]
    public static paddingMngr = [
        {
            name: 'Padding Styling',
            open: false,
            buildProps: [
                'padding-top',
                'padding-right',
                'padding-bottom',
                'padding-left',
            ]
        }
    ]
    public static transitionMngr = [
        {
            name: ' Transition Styling',
            open: false,
            buildProps: [
                'box-shadow',
                'transition',
                'transition-duration',
                'transition-property',
                'transition-timing-function',
            ]
        }
    ]

    public static transformMngr = [
        {
            name: 'Transform',
            open: false,
            buildProps: [
                'transform-rotate-x',
                'transform-rotate-y',
                'transform-rotate-z',
                'transform-scale-x',
                'transform-scale-y',
                'transform-scale-z',
                'perspective',
            ]
        }
    ]
    public static flexMngr = [
        {

            name: 'Flex',
            open: false,
            buildProps: [
                'flex-direction',
                'flex-wrap',
                'flex-basis',
                'flex-grow',
                'flex-shrink',
            ]
        }
    ]
    public static extraMngr = [
        {
            name: 'Extra',
            open: false,
            buildProps: [
                'cursor',
                'justify-content',
                'order',
                'align-items',
                'align-content',
                'align-self',
                'overflow',
                'overflow-x',
                'overflow-y'
            ]
        }
    ]

}