import { Component, OnInit, NgModule } from '@angular/core';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Loading } from '../../../loader/Loading';
import * as XLSX from 'xlsx';
import { ModalService } from '../../../_modal';
import { ToastrService } from 'ngx-toastr';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-accountlink',
  templateUrl: './accountlink.component.html',
  styleUrls: ['./accountlink.component.scss']
})
export class AccountlinkComponent implements OnInit {
  fileName = 'ExcelSheet.xlsx';
  loginForm: FormGroup;
  submitted = false;

  id: any;
  id_data: any;
  value: any;
  data_report: any;
  data_clawbacks: any;
  data_commissions: any;
  data_payments: any;
  data_range: any;
  data_totals: any;
  id_data_subid: any;
  newToday: any;
  dateYesterday: any;
  dateLastMonthFirst: any;
  dateLastMonthEnd: any;
  dateCurrentMonthFirst: any;
  dateCurrentMonthEnd: any;
  start_date_report: any;
  end_date_report: any;
  start_date: any;
  end_date: any;
  bank_detail:any;
  payout:any;
  account_number:any;
  update_bank_data:any;
  payout_deatail:any;
  account_number_update:any;
  tagId1:any;
  active_id:any;

  AllCommissionSum: any;
  AllCommissionList: any;
  delayed_commissions: any;
  owed: any;
  paid_commissions: any;
  pending_commissions: any;
  constructor(private UserServiceService: UserServiceService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
    private toastrService: ToastrService) { }

  ngOnInit() {
      this.tagId1 =   localStorage.getItem('tagId');
       
      if(this.tagId1=='false')
    {
      this.router.navigate(['/dashboard/course']);
    }  


    this.UserServiceService.get_bank_detial().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      if (data.status == 200) {
         this.update_bank_data='1';
         this.payout_deatail=data.data.paypal_detail;
         this.bank_detail=data.data.bank_detail;
         this.account_number_update=data.data.account_number;
      }

    });

    this.loginForm = this.formBuilder.group({
      cardNo: ['', Validators.required, Validators.minLength(3)],

    });
    this.onchange('All Time', '');

  }

  onchange(event, type) {
    if (event == 'yesterday') {
      this.id_data = '';

      let dateToday = new Date();
      this.newToday = this.formatDate(dateToday);

      var yesterday = dateToday.setDate(dateToday.getDate() - 1);
      var dateString = new Date(yesterday);
      this.dateYesterday = this.formatDate(dateString);

      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event
        }

      }


      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });
    }


    //for today section
    else if (event == 'Today') {
      this.id_data = '';
      let dateToday = new Date();
      this.newToday = this.formatDate(dateToday);
      var yesterday = dateToday.setDate(dateToday.getDate() - 1);
      var dateString = new Date(yesterday);
      this.dateYesterday = this.formatDate(dateString);


      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.newToday,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.newToday,
          "endDate": this.newToday,
          "selectedName": event
        }

      }


      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });
    }

    ////for last month  section
    else if (event == 'Last Month') {
      this.id_data = '';
      let dateToday = new Date();
      var lastMonthFirst = new Date(dateToday.getFullYear(), dateToday.getMonth() - 1, 1);
      var lastMonthdayEnd = new Date(dateToday.getFullYear(), dateToday.getMonth(), -1);
      this.dateLastMonthFirst = this.formatDate(lastMonthFirst);
      this.dateLastMonthEnd = this.formatDate(lastMonthdayEnd);
      this.newToday = this.dateLastMonthEnd;
      this.dateYesterday = this.dateLastMonthFirst;

      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.dateLastMonthFirst,
          "endDate": this.dateLastMonthEnd,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.dateLastMonthFirst,
          "endDate": this.dateLastMonthEnd,
          "selectedName": event
        }

      }


      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });
    }



    ////for last month  section
    else if (event == 'Month To Date') {
      this.id_data = '';

      let dateToday = new Date();


      var currentMonthStart = new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
      this.dateCurrentMonthFirst = this.formatDate(currentMonthStart);
      this.dateCurrentMonthEnd = this.formatDate(dateToday);
      this.newToday = this.dateCurrentMonthEnd;
      this.dateYesterday = this.dateCurrentMonthFirst;
      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.dateCurrentMonthFirst,
          "endDate": this.dateCurrentMonthEnd,
          "selectedName": event,
          "type": type
        }

      }
      else {
        this.value = {
          "startDate": this.dateCurrentMonthFirst,
          "endDate": this.dateCurrentMonthEnd,
          "selectedName": event
        }
      }

      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        if (data.status == 200) {
          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });
    }

    else if (event == 'custom') {
      if (type == 'subid') {
        this.id_data_subid = '4';

      }
      else {

        this.id_data = '3';

      }
    }

    else {


      //call for full time   
      if (type == 'subid') {
        this.id_data_subid = '';
        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": 'All Time',
          "type": type
        }
        this.id = '2';

      }
      else {

        this.id_data = '';
        this.id = '1';



        let dateToday = new Date();
        this.newToday = this.formatDate(dateToday);

        var yesterday = dateToday.setDate(dateToday.getDate() - 1);
        var dateString = new Date(yesterday);
        this.dateYesterday = '1/1/2008';

        this.value = {
          "startDate": this.newToday,
          "endDate": this.dateYesterday,
          "selectedName": event
        }



      }

      this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
        Loading.hide();
        //return false;

        if (data.status == 200) {

          this.AllCommissionSum = data.data.AllCommissionSum;

          if(this.AllCommissionSum.length > 0){
            this.delayed_commissions = this.AllCommissionSum[0].delayed_commissions ? this.AllCommissionSum[0].delayed_commissions : 0;
            this.owed = this.AllCommissionSum[0].owed ? this.AllCommissionSum[0].owed : 0;
            this.paid_commissions = this.AllCommissionSum[0].paid_commissions ? this.AllCommissionSum[0].paid_commissions : 0;
            this.pending_commissions = this.AllCommissionSum[0].pending_commissions ? this.AllCommissionSum[0].pending_commissions : 0;
          }else{

          }  
          this.AllCommissionList = data.data.AllCommissionList;
          if(this.AllCommissionList){
            
          }

          if (type === "subid") {
            this.data_report = data.data;
          } else {

            this.data_clawbacks = data.data.clawbacks;
            this.data_commissions = data.data.commissions;

            this.data_payments = data.data.payments;
            this.data_range = data.data.range;
            this.data_totals = data.data.totals;
          }
        }

      });

    }
  }

  get f() { return this.loginForm.controls; }
  userDetails() {
    this.submitted = true;
    if (this.loginForm.invalid) {

      return;
    }
    this.UserServiceService.change_card(this.loginForm.value).pipe(first()).subscribe((data: any) => {

      // this.get_cardlist();
      /* if (data.status_code == 200) {
        
      }  */
    });
  }

  formatDate(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return month + "/" + day + "/" + year;
  }


  customSubmit(startdate, enddate) {

    if (startdate == undefined) {
      this.start_date = 'error_startdate';
      return false;
    }

    if (enddate == undefined) {

      this.end_date = 'error_enddate';
      return false;
    }

    this.value = {
      "startDate": startdate,
      "endDate": enddate,
      "selectedName": 'custom',

    }


    // return false;


    this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
      Loading.hide();


      if (data.status == 200) {


        this.data_clawbacks = data.data.clawbacks;
        this.data_commissions = data.data.commissions;

        this.data_payments = data.data.payments;
        this.data_range = data.data.range;
        this.data_totals = data.data.totals;

      }

    });





  }


  customSubmitReport(startdate, enddate) {

    if (startdate == undefined) {

      this.start_date_report = 'error_startdate';
      return false;
    }

    if (enddate == undefined) {

      this.end_date_report = 'error_endate';
      return false;
    }
    this.value = {
      "startDate": startdate,
      "endDate": enddate,
      "selectedName": 'custom',
      "type": "subid"
    }


    this.UserServiceService.partner_program(this.value).pipe(first()).subscribe((data: any) => {
      Loading.hide();


      if (data.status == 200) {


        this.data_report = data.data;

      }

    });




  }

  //export file commission
  exportexcel_commision() {
    let element = document.getElementById('entry-grid');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);

  }

  //export file clawbacks
  exportexcel_clawbacks() {
    let element = document.getElementById('entry-grid2');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);

  }

  //export file payment
  exportexcel_payment() {
    let element = document.getElementById('entry-grid3');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, this.fileName);


  }

  update_details(payout_deatail, bank_detail)
  {
    this.UserServiceService.update_bank_details({
      'payout_deatail': payout_deatail,
      'bank_detail': bank_detail,
     
    }).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      if (data.status_code == 200) {
        this.toastrService.success(data.message);
      }
      else {
        this.toastrService.error(data.message);
      }

    });

  }

  add_details(payout, bank_detail) {
    this.UserServiceService.add_bank_details({
      'payout': payout,
      'bank': bank_detail,
   
    }).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      if (data.status_code == 200) {
        this.toastrService.success(data.message);
        this.UserServiceService.get_bank_detial().pipe(first()).subscribe((data: any) => {
          Loading.hide();
          if (data.status == 200) {
 
             this.update_bank_data='1';
             this.payout_deatail=data.data.paypal_detail;
             this.bank_detail=data.data.bank_detail;
             this.account_number_update=data.data.account_number;
          }
    
        });
    
      }
      else {
        this.toastrService.error(data.message);
      }

    });
  }


}
