import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService, AlertService } from '../../_service';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  overview_details: any;
  url: any;
  description: any;
  title: any;
  videoUrl: SafeResourceUrl;
  constructor(
    private route: ActivatedRoute, private router: Router, private UserServiceService: UserServiceService, private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      let id = params['id'];

      this.UserServiceService.get_overview_details({ id: id }).pipe(first()).subscribe((data: any) => {
        if (data.status_code == 200) {
          this.description = data.data.description;
          this.title = data.data.title;
          this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(data.data.overview_url);

        }
      });
    });


  }

}
