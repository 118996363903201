import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailhtam',
  templateUrl: './emailhtam.component.html',
  styleUrls: ['./emailhtam.component.scss']
})
export class EmailhtamComponent implements OnInit {
  first_name:string;
  constructor() { }

  ngOnInit() {

    let user_details = JSON.parse(localStorage.getItem('user_details'));
    this.first_name =user_details.first_name;
  }

}
