let gridCat = {
  label: 'Grid',
  open: false
}

var basicCat = {
  label: 'Basics',
  open: false,
}

export class Grids {

  public static components = [
    {
      id: 'section', // id is mandatory
      data: {
        category: {
          label: 'Para',
          open: false
        },
        label: '<h6 style="font-size:13px !important">Section</h6>', // You can use HTML/SVG inside labels
        attributes: { class: 'gjs-block-section, secClass' },
        content: `<section>
                        <h1>This is a simple title</h1>
                        <div>This is just a Lorem text: Lorem ipsum dolor sit amet</div>
                      </section>`,
      }
    },
    {
      id: 'block-001',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/1.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container flex-container" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item1" data-gjs-draggable=".row"></div>
                            <div class="cell item2" data-gjs-draggable=".row"></div>
                            <div class="cell item3" data-gjs-draggable=".row"></div>  
                            <div class="cell item4" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>

                          .grid-container 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item1 
                          {
                            grid-row-start: 1;
                            grid-row-end: 3;
                          }
      
                          .item4
                          {
                          grid-column-end:4;
                            grid-column-start:2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-002',
      data: {
        category: gridCat,
        label: `<div>
                      <img src="../../../assets/s-images/2.jpg" style="width:55px">
                    </div>`,
        content:
          `
                          <div class="row grid-container" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell grd1" data-gjs-draggable=".row"></div>
                            <div class="cell grd2" data-gjs-draggable=".row"></div>
                            <div class="cell grd3" data-gjs-draggable=".row"></div>  
                            <div class="cell grd4" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .grd2 {
                            grid-column: 3;
                            grid-row: 1 / span 2;
                          }
      
                          .grd3 {
                            grid-column: 1 / span 2;
                            grid-row: 2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-003',
      data: {
        category: gridCat,
        label: `<div>
                      <img src="../../../assets/s-images/3.jpg" style="width:55px">
                    </div>`,
        content:
          `
                          <div class="row grid-container" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell grd-top-1" data-gjs-draggable=".row"></div>
                            <div class="cell grd-top-2" data-gjs-draggable=".row"></div>
                            <div class="cell grd-top-3" data-gjs-draggable=".row"></div>  
                            <div class="cell grd-top-4" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .grd-top-1 {
                            grid-column: 1 / span 2;
                            grid-row: 1;
                          }
      
                          .grd-top-2 {
                            grid-column: 3;
                            grid-row: 1 / span 2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-004',
      data: {
        category: gridCat,
        label: `<div>
                      <img src="../../../assets/s-images/4.jpg" style="width:55px">
                    </div>`,
        content:
          `
                          <div class="row grid-container" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell grd-bottom-1" data-gjs-draggable=".row"></div>
                            <div class="cell grd-bottom-2" data-gjs-draggable=".row"></div>
                            <div class="cell grd-bottom-3" data-gjs-draggable=".row"></div>  
                            <div class="cell grd-bottom-4" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .grd-bottom-1 {
                            grid-column: 3;
                            grid-row: 1 / span 2;
                          }
      
                          .grd-bottom-2 {
                            grid-column: 1;
                            grid-row: 1 / span 2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-005',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/5.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container005" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0051" data-gjs-draggable=".row"></div>
                            <div class="cell item0052" data-gjs-draggable=".row"></div>
                            <div class="cell item0053" data-gjs-draggable=".row"></div>  
                            <div class="cell item0054" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container005 
                          {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container005 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0054 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-006',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/6.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container006" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0061" data-gjs-draggable=".row"></div>
                            <div class="cell item0062" data-gjs-draggable=".row"></div>
                            <div class="cell item0063" data-gjs-draggable=".row"></div>  
                            <div class="cell item0064" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container006 
                          {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container006 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0061 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-07',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/7.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container07" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item071" data-gjs-draggable=".row"></div>
                            <div class="cell item072" data-gjs-draggable=".row"></div>
                            <div class="cell item073" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container07 
                          {
                            display: grid;
                            grid-template-columns: repeat(3,1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container07 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item071 
                          {
                            grid-column-start: 1;
                            grid-column-end: 2;
                          }
                          .item072 
                          {
                            grid-column-start: 2;
                            grid-column-end: -1;
                            grid-row-start: 1;
                            grid-row-end: 3;
                          }
                          .item073
                          {
                          grid-column-end:1;
                            grid-column-start:2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-08',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/8.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container08" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item081" data-gjs-draggable=".row"></div>
                            <div class="cell item082" data-gjs-draggable=".row"></div>
                            <div class="cell item083" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container08 
                          {
                            display: grid;
                            grid-template-columns: repeat(3,1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container08 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item081 
                          {
                            grid-column-start: 1;
                            grid-column-end: -2;
                            grid-row-start: 1;
                            grid-row-end: 3;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-09',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/9.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container09" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item091" data-gjs-draggable=".row"></div>
                            <div class="cell item092" data-gjs-draggable=".row"></div>
                            <div class="cell item093" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container09 
                          {
                            display: grid;
                            grid-template-columns: repeat(4,1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container09 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item091 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
                          .item092 
                          {
                            grid-column-start: 1;
                            grid-column-end: 3;
                          }
                          .item093
                          {
                          grid-column-end:3;
                            grid-column-start:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-010',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/10.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container010" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0101" data-gjs-draggable=".row"></div>
                            <div class="cell item0102" data-gjs-draggable=".row"></div>
                            <div class="cell item0103" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container010 
                          {
                            display: grid;
                            grid-template-columns: repeat(4,1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container010 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0101 
                          {
                            grid-column-start: 1;
                            grid-column-end: 3;
                          }
                          .item0102 
                          {
                            grid-column-start: 3;
                            grid-column-end: -1;
                          }
                          .item0103
                          {
                          grid-column-end:1;
                            grid-column-start:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-011',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/11.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container011" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0111" data-gjs-draggable=".row"></div>
                            <div class="cell item0112" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container011 
                          {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container011 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0111 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
      
                          .item0112
                          {
                            grid-column-start:1;
                            grid-column-end:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-012',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/12.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container012" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0121" data-gjs-draggable=".row"></div>
                            <div class="cell item0122" data-gjs-draggable=".row"></div>
                            <div class="cell item0123" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container012 
                          {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container012 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0121 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
      
                          .item0122
                          {
                            grid-column-start:1;
                            grid-column-end:-2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-013',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/13.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container013" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0131" data-gjs-draggable=".row"></div>
                            <div class="cell item0132" data-gjs-draggable=".row"></div>
                            <div class="cell item0133" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container013 
                          {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container013 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0131 
                          {
                            grid-column-start: 1;
                            grid-column-end: -1;
                          }
      
                          .item0133
                          {
                            grid-column-start:2;
                            grid-column-end:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-014',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/14.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container015" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item1" data-gjs-draggable=".row"></div>
                            <div class="cell item2" data-gjs-draggable=".row"></div>
                            <div class="cell item3" data-gjs-draggable=".row"></div>  
                            <div class="cell item4" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container015 
                          {
                            display: grid;
                            grid-template-columns: auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container015 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-015',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/15.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container015" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0151" data-gjs-draggable=".row"></div>
                            <div class="cell item0152" data-gjs-draggable=".row"></div>
                            <div class="cell item0153" data-gjs-draggable=".row"></div>  
                          </div>
                        <style>
                          .grid-container015 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container015 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-016',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/16.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container016" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0161" data-gjs-draggable=".row"></div>
                            <div class="cell item0162" data-gjs-draggable=".row"></div>
                            <div class="cell item0163" data-gjs-draggable=".row"></div>   
                          </div>
                        <style>
                          .grid-container016 
                          {
                            display: grid;
                            grid-template-columns: repeat(4, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container016 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0162 
                          {
                            grid-column-start: 2;
                            grid-column-end: -2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-017',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/17.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container017" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0171" data-gjs-draggable=".row"></div>
                            <div class="cell item0172" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container017 
                          {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container017 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0172
                          {
                            grid-column-start:2;
                            grid-column-end:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-018',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/18.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container018" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0191" data-gjs-draggable=".row"></div>
                            <div class="cell item0192" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container018 
                          {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container018 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0191
                          {
                            grid-column-start:1;
                            grid-column-end:-2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-019',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/19.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container019" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0191" data-gjs-draggable=".row"></div>
                            <div class="cell item0192" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container019 
                          {
                            display: grid;
                            grid-template-columns: repeat(6, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container019 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0191
                          {
                            grid-column-start:1;
                            grid-column-end:-2;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-020',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/20.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container020" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0201" data-gjs-draggable=".row"></div>
                            <div class="cell item0202" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container020 
                          {
                            display: grid;
                            grid-template-columns: repeat(6, 1fr);
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container020 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0202
                          {
                            grid-column-start:2;
                            grid-column-end:-1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-021',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/21.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container021" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0211" data-gjs-draggable=".row"></div>
                            <div class="cell item0212" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container021 
                          {
                            display: grid;
                            grid-template-columns: auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container021 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0211 
                          {
                            grid-row-start: 1;
                            grid-row-end: 1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-022',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/22.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container022" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0221" data-gjs-draggable=".row"></div>
                            <div class="cell item0222" data-gjs-draggable=".row"></div> 
                          </div>
                        <style>
                          .grid-container022 
                          {
                            display: grid;
                            grid-template-columns: auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container022 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0221 
                          {
                            grid-row-start: 1;
                            grid-row-end: 1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-023',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/23.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="row grid-container023" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="cell item0231" data-gjs-draggable=".row"></div>
                            <div class="cell item232" data-gjs-draggable=".row"></div>
                            <div class="cell item0233" data-gjs-draggable=".row"></div>  
                            <div class="cell item0234" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container023 
                          {
                            display: grid;
                            grid-template-columns: auto auto auto auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container023 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item0231 
                          {
                            grid-row-start: 1;
                            grid-row-end: 1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'block-024',
      data: {
        category: gridCat,
        label: `<div>
                                <img src="../../../assets/s-images/24.jpg" style="width:55px">
                              </div>`,
        attributes: {},
        content:
          `
                          <div class="grid-container024" data-gjs-type="wrapper" data-gjs-droppable=".row-cell" data-gjs-custom-name="Row" style="min-height:200px;">
                            <div class="row item024" data-gjs-draggable=".row"></div>
                          </div>
                        <style>
                          .grid-container024 
                          {
                            display: grid;
                            grid-template-columns: auto;
                            grid-gap: 5px;
                            padding: 5px;
                          }
      
                          .grid-container024 > div 
                          {
                            background-color: rgba(255, 255, 255, 0.8);
                            text-align: center;
                            padding: 20px 0;
                            font-size: 10px;
                          }
      
                          .item024 
                          {
                            grid-row-start: 1;
                            grid-row-end: 1;
                          }
                        </style>
            `,
      }
    },
    {
      id: 'basics-image',
      data: {
        category: basicCat,
        attributes: { class: "fa fa-image" },
        label: 'Image',
        content: {
          style: { color: "black" },
          type: "image",
          classes: ['class1', 'class2'],
          activeOnRender: 1,
        },
      }
    },
    {
      id: 'basics-Video',
      data: {
        category: basicCat,
        attributes: { class: "fa fa-youtube-play" },
        label: 'Video',
        content: {
          style: {
            color: "black",
            width: '50%',
            height: '50%'
          },
          type: "video",
          classes: ['website-media', 'website-video'],
          activeOnRender: 1,
          // draggable: '.website-cell, .website-block, .video-container, .media-container',

        },
      }
    },
    {
      id: 'basics-Map',
      data: {
        category: basicCat,
        attributes: { class: "fa fa-map-o" },
        label: 'Map',
        content: {
          style: {
            height: '50%',
            width: '50%'
          },
          type: "map",
          address: 'Delhi',
          // classes: ['website-media', 'website-video'],
          zoom: 1
          // activeOnRender: 1,
          // draggable: '.website-cell, .website-block, .video-container, .media-container',

        },
      }
    },
    {
      id: 'basics-Text',
      data: {
        category: basicCat,
        attributes: { class: "fa fa-font" },
        label: 'Text',
        content: {
          style: {
            margin: '30px',
            height: '50%',
            width: '50%',
          },
          type: "text",
          // classes: ['website-media', 'website-video'],

        },
      }
    }
  ]
}
