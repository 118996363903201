import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';
import { BillingComponent } from './billing/billing.component';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateprofileComponent } from './updateprofile/updateprofile.component';
import { UpdatepasswordComponent } from './updatepassword/updatepassword.component';
import { SupportComponent } from './support/support.component';

import { ModalModule } from '../../_modal';
import { MatAutocompleteModule, MatButtonModule, MatDialogModule, MatExpansionModule, MatIconModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatRadioModule, MatSelectModule, MatTabsModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProfileComponent,
    BillingComponent,
    UpdateprofileComponent,
    SupportComponent,
    UpdatepasswordComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatRadioModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatSelectModule,
    MatAutocompleteModule,
  ]
})
export class ProfileModule { }
