import { Component, OnInit } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Observable } from 'rxjs';
import { UserServiceService, AlertService } from '../_service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UpdateSidemenuSataService } from '../_service/update-sidemenu-data.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  message: string;
  message1: string;
  tagid: any;
  isLoggedIn$: Observable<boolean>;
  data1: any;
  coach_details: string;
  advisor_details: string;
  membership_message: string;
  membership_details: string;
  message_tag: string;
  isCollapsed = true;
  token: string;
  logedInuserData: any;
  dbsAlloted: [];
  membersArea = true;
  categoryName: any;
  logoUpdate: any;
  userInfo: any;
  profile_img: any;
  profile_pic: string;

  constructor(private UserServiceService: UserServiceService, private router: Router, private updateSidemenuSataService: UpdateSidemenuSataService) {
    this.UserServiceService.isLoggedInUser.subscribe((res: any) => {
      if (res) {
        this.logedInuserData = res;
        this.token = res.data.access_token;
      }
    })
  }
  ngOnInit(): void {
    this.updateSidemenuSataService.profile_pic$.subscribe(profile_pic => {
      this.profile_pic = profile_pic;
    });
    this.logoUpdate = localStorage.getItem('logoUpdate');
    this.userInfo = localStorage.getItem('user_info');
    this.profile_pic = localStorage.getItem('profile_pic');
    this.profile_img = JSON.parse(this.userInfo)
    console.log('sssss', this.profile_img.data.image);

    this.UserServiceService.currentMessage.subscribe(message => this.message = message)

    this.UserServiceService.currentMessage1.subscribe(message1 => this.message1 = message1)

    this.UserServiceService.currentMessage_membership.subscribe(membership_message => this.membership_message = membership_message)


    this.UserServiceService.currentMessagetag.subscribe(message_tag => this.message_tag = message_tag)

    //console.log('Test of header1234')
    this.data1 = localStorage.getItem('user_details');
    // console.log("data", this.data1);
    setTimeout(() => {
      // this.router.navigate(['/dashboard/course/' + this.categoryName]);
      this.isLoggedIn$ = this.UserServiceService.isLoggedIn;
    }, 8000);
    // this.token = JSON.parse(this.data1)['access_token'];
    if (this.data1 != null) {

      this.tagid = localStorage.getItem('tagId');

      // this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {
      //   //  console.log('Alloted coach', data.status_code);
      //   //return false;
      //   if (data.status_code == 200) {
      //     this.coach_details = 'coach allot'
      //   }
      //   else {
      //     this.coach_details = 'coach not allot'
      //   }
      // });

      // this.UserServiceService.advisor_detail().pipe(first()).subscribe((data: any) => {
      //   if (data.status_code == 200) {
      //     this.advisor_details = 'Advisor allot';
      //   }
      //   else {
      //     this.advisor_details = 'Advisor not allot';
      //   }
      // });
      let keySend = {
        learning_type: 'Classes',
      }
      this.UserServiceService.get_category(keySend).pipe(first()).subscribe((data: any) => {
        this.categoryName = localStorage.getItem('categoryName')
        this.dbsAlloted = data.data;

        if (this.categoryName == 'null') {
          this.membersArea = false;
        }
        // else{
        //   this.membersArea = true;
        // }

        if (data.status_code == 200) {
          this.membership_details = 'Membership allot';
        }
        else {
          this.membership_details = 'Membership not allot';
        }
      });

    } else {
      // window.location.reload();
      // console.log("in else of ?????????");

    }
  }
}
