import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { UserServiceService } from '../../../_service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  list: any;
  test: any;
  test1: any;
  name: any;
  status_data: any;
  isDisabled = true;
  dbCategoryName: string;

  child_category: any;
  completedSteps: Array<Number>;
  subCategories: any;
  //Get child category list
  @Output() messageEvent = new EventEmitter<any>();
  @Output() queTestShow = new EventEmitter<any>();
  @Output() messageEvent_overview = new EventEmitter<any>();
  @Output() callindexEvent = new EventEmitter<any>();
  @Input() subCategories1: any;
  @Input() subCategories2: any;
  @Input() subCategories_course: any;
  @Input() module_steps: any;
  @Input() step_id: any;
  @Input() course_id: any;
  @Input() moduleSteps_data: any;
  @Input() c_module_id1: Array<Number> = [];
  @Input() c_step_id: any;
  @Input() c_module_id: any;
  @Input() compeleteindex: any;
  @Input() c_course_id: any;
  @Input() subCategories_module: any;
  @Input() listing: any;
  @Input() active_step_id: any;
  @Input() active_course_id: any;
  @Input() active_module_id: any;
  @Input() module_id: any;
  @Input() module_update_id: any;
  @Input() active_module_id1: any;
  @Input() markascompletedata: any;
  @Input() videoUrl: any;
  @Input() lastindexvalue: any;
  @Input() dbdstepActiveMarkAs: any;
  @Input() index_value: any;

  current_id: any;
  next_id: any;
  numberValue_nextid: any;
  numberValue_currentid: any;
  step1: Array<Number> = [];
  step2: Array<Number> = [];
  module1: any;
  parent: any
  moduleSteps: Array<string> = [];
  moduleSteps_new: Array<string> = [];
  moduleSteps_update: Array<string> = [];

  @Input() assign_module: any;
  constructor(
    private route: ActivatedRoute,
    private UserServiceService: UserServiceService,
    private router: Router,
    private toastrService: ToastrService
  ) { }
  ngOnInit() {
    // this.UserServiceService.get_complete_status().pipe(first()).subscribe((data: any) => {
    //   // console.log("tried", data);

    //   this.status_data = data.data;
    // });
    this.route.params.subscribe(params => {
      this.dbCategoryName = params['id'];
      // console.log("id kya h????", id);

    })

  }
  completestatuscheck(parantcategory, category, chiledcategory) {

    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;

    let indexReturn = this.markascompletedata.indexOf(indexArray);
    // console.log('wwwwwwwwwwwwwwwwwwww',indexReturn);

    if (indexReturn >= 0) {
      this.onDisabled();
      return false;
    } else {
      return true;
    }


  }

  lockcheck(parantcategory, category) {

    if (parantcategory == 1) {
      // console.log('Test of parent', category)
      let indexReturn = this.assign_module.indexOf(category);
      //console.log('dataa', indexReturn);
      if (indexReturn == -1) {
        //console.log('no permission');
        return false;
      }
      else {
        //console.log('permission');
        return true;
      }
    }
    else {
      return true;
    }
  }
  onDisabled() {
    this.isDisabled = true;
  }

  completestatuscheck_button(parantcategory, category, chiledcategory) {

    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;
    let indexReturn = this.markascompletedata.indexOf(indexArray);
    if (indexReturn >= 0) {
      return true;
    } else {
      return false;
    }
  }

  show_list(id, course_id, module_id, next_id) {
    // console.log('sssssssssssssssssssssssssssss',id, course_id, module_id, next_id);

    // this.checkcompletestatus(index_value, id, course_id, module_id, moduleindex_value, current_index_value)
    this.messageEvent.emit({ step_id: id, course_id: course_id, module_id: module_id, active_id: id, next_id: next_id })
  }

  show_overview(id) {
    console.log("overview_id dbs working fine", id);
    this.messageEvent_overview.emit({ overview_id: id })
  }
  get_index(i) {
    this.callindexEvent.emit({ index: i })
  }

  showQue(data) {
    this.queTestShow.emit(data)
  }
}