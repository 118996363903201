import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-videotracking',
  templateUrl: './videotracking.component.html',
  styleUrls: ['./videotracking.component.scss']
})
export class VideotrackingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
