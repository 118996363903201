import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators} from "@angular/forms";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserServiceService } from "../_service";
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.scss']
})
export class CancelledComponent implements OnInit {

  cancel:any;
  constructor( 
    private ActivatedRoute: ActivatedRoute,
    private router: Router
    
    ) 
  {

    this.ActivatedRoute.queryParamMap.subscribe((data: any) => {
      this.cancel = data.params.cancel;
      if(this.cancel != "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NWY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adRsscs"){
        this.router.navigate(['/']);
      }
      else if(this.cancel == undefined){
        this.router.navigate(['/']);
      }
      else{
        
      }
      
    });
  }

  ngOnInit() {
    setInterval(() => {
      this.router.navigate(['/']); 
    }, 10000);
  }
}
