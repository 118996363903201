import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-emailvideo',
  templateUrl: './emailvideo.component.html',
  styleUrls: ['./emailvideo.component.scss']
})
export class EmailvideoComponent implements OnInit {
  first_name: string;
  constructor() { }

  ngOnInit() {
    let user_details = JSON.parse(localStorage.getItem('user_details'));

    this.first_name = user_details.first_name;
  }

}
