import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerRoutingModule } from './partner-routing.module';
import { PartnerComponent } from './partner.component';
import { BannerComponent } from './banner/banner.component';
import { EmailswipComponent } from './emailswip/emailswip.component';
import { PixelcodeComponent } from './pixelcode/pixelcode.component';
import { TrackingComponent } from './tracking/tracking.component';
import { LinkComponent } from './link/link.component';

@NgModule({
  declarations: [
    PartnerComponent,
    BannerComponent,
    EmailswipComponent,
    TrackingComponent,
    PixelcodeComponent,
    LinkComponent
  ],
  imports: [
    CommonModule,
    PartnerRoutingModule
  ]
})
export class PartnerModule { }
