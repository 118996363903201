import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserServiceService, AlertService } from '../../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Loading } from '../../../loader/Loading';
@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.scss']
})
export class UpdatepasswordComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  emailSet: any;
  brandName: any;


  constructor(private formBuilder: FormBuilder,
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.emailSet = localStorage.getItem('email_set');
    this.brandName = localStorage.getItem('brandName');
    this.loginForm = this.formBuilder.group({
      oldPwd: ['', Validators.required],
      newPwd: ['', Validators.required],
      confirm_password: ['', Validators.required],
      brand_name: [this.brandName],
      email_address: [this.emailSet],
    });
  }

  get f() { return this.loginForm.controls; }
  updatePassword() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.UserServiceService.update_password(this.loginForm.value).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        Loading.hide();
        this.alertService.success(data.message);
        this.submitted = false;
        this.loginForm.reset();
      }
      else{
        this.alertService.success(data.message);
      }

      // this.f.clear();

    });
  }

}
