import { Component, OnInit, HostListener } from '@angular/core';
import { UserServiceService, AlertService } from '../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Loading } from '../../loader/Loading'

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.scss']
})
export class CoachComponent implements OnInit {
  coach_details: any;
  videoUrl: any;
  image_src: any;
  first_name: any;
  email: any;
  mobile: any;
  skype_id: any;
  schedule_link: any;
  session_available: any;
  session_completed: any;
  call_data: Array<number> = [];
  id_1: number;
  id_2: number;
  id_3: number;
  id_4: number;
  id_5: number;
  id_6: number;
  id_7: number;
  id_8: number;
  id_string_1: any;
  id_string_2: any;
  id_string_3: any;
  id_string_4: any;
  id_string_5: any;
  id_string_6: any;
  id_string_7: any;
  id_string_8: any;

  /* 
    id_1_string: number;
    id_2_string: string;
    id_3_string: string;
    id_4_string: string;
    id_5_string: string;
    id_6_string: string;
    id_7_string: string;
    id_8_string: string; */
  isLoading: boolean = true;
  isDisabled: boolean = false;

  constructor(
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private router: Router) { }
  ngOnInit() {
    this.id_1 = 1;
    this.id_2 = 2;
    this.id_3 = 3;
    this.id_4 = 4;
    this.id_5 = 5;
    this.id_6 = 6;
    this.id_7 = 7;
    this.id_8 = 8;
    this.id_string_1 = "1";

    this.id_string_2 = "2";

    this.id_string_3 = "3";

    this.id_string_4 = "4";

    this.id_string_5 = "5";

    this.id_string_6 = "6";

    this.id_string_7 = "7";

    this.id_string_8 = "8";

    Loading.show();
    this.isLoading = true;
    this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {

      Loading.hide();
      this.isLoading = false;

      //return false;
      if (data.status_code == 200) {
        this.coach_details = data.data;
        this.image_src = this.coach_details.url;
        this.first_name = this.coach_details.first_name;
        this.email = this.coach_details.email;
        this.mobile = this.coach_details.mobile;
        this.skype_id = this.coach_details.skype_id;
        this.schedule_link = this.coach_details.schedule_link;
        if (data.data.calldetail == null) {
          this.session_available = 8
          this.session_completed = 0
        }
        else {
          this.session_available = data.data.calldetail.total_calls;
          this.session_completed = data.data.calldetail.remaining_calls;
        }


        if (this.coach_details.upload_video != null) {
          this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.coach_details.upload_video);

        }
        else {
          this.videoUrl = '';
        }

        this.UserServiceService.get_schedule_call_details().pipe(first()).subscribe((data: any) => {

          if (data.status_code == '200') {

            if (data.data != undefined) {
              this.call_data = data.data.call.split(",");
              if (this.call_data.length === 8) {
                return this.isDisabled = true;
              }

            }
            else {

            }
          }

        });


      }
      else {
        //alert('not found');
        this.alertService.success('No coach alloted');
        // this.router.navigate(['/dashboard/product']);

      }
    });
  }

  scheduleCall() {
    let callScheduleType = 'coach';
    localStorage.setItem('callScheduleType', callScheduleType);
    this.router.navigate(['/dashboard/schedule-call']);
  }

  add_call(call_id) {

    window.scroll({
      top: 10,
      left: 10,
      behavior: 'smooth'
    });
    // alert('test of data');
    // return false;
    //console.log('id of call', call_id);
    /*  this.UserServiceService.add_schedule_call({
       'call': call_id
     }).pipe(first()).subscribe((data: any) => {
       console.log('All data', data);
       if (data.status_code == '200') {
         this.toastrService.success('Calls updated');
         this.call_data.push(call_id);
         console.log(this.call_data);
       }
     }); */


  }
}
