import { Component, OnInit } from '@angular/core';
import { UserServiceService, AlertService } from '../../../_service'
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Loading } from '../../../loader/Loading'

@Component({
  selector: 'app-dashboardupdate',
  templateUrl: './dashboardupdate.component.html',
  styleUrls: ['./dashboardupdate.component.scss']
})
export class DashboardupdateComponent implements OnInit {
  dashboard_details: any;
  title: any;
  description: SafeResourceUrl;
  videoiframe: SafeResourceUrl;
  tip_title: any;
  tip_description: any;
  news_date: any;
  news_description: any;
  tagId3:any;
  tagId88:any;
  tagId89:any;
  tagId219:any;
  tagId220:any;
  tagId1:any;

  constructor(private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private router: Router) { }

  ngOnInit() {
    /* this.tagId1 =   localStorage.getItem('tagId');
     if(this.tagId1!='false')
    {
      this.router.navigate(['/dashboard/course']);
    } */

    let tagId3 =   localStorage.getItem('tagId3');
    let tagId88 =  localStorage.getItem('tagId88');
    let tagId89 =  localStorage.getItem('tagId89');
    let tagId219 =  localStorage.getItem('tagId219');
    let tagId220 =  localStorage.getItem('tagId220');

    this.tagId3   =  false;  
    this.tagId88  =  false;
    this.tagId89  =  false;
    this.tagId219 =  false;
    this.tagId220 =  false;
    if (tagId220 === 'true') {
      this.tagId220 = true;
    } else if (tagId219 === 'true') {
      this.tagId219 = true;
    } else if (tagId89 === 'true') {
      this.tagId89  =  true;
    } else if (tagId88 === 'true') {
      this.tagId88  =  true;
    } else if (tagId3 === 'true') {
      this.tagId3   =  true;
    }
    this.UserServiceService.get_dashboard_detail().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.dashboard_details = data.data;
      this.title = this.dashboard_details.title;
      this.videoiframe = this.sanitizer.bypassSecurityTrustHtml(this.dashboard_details.videoiframe);
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.dashboard_details.description);
      
      this.tip_title = this.dashboard_details.tip_title;
      this.tip_description = this.dashboard_details.tip_description;
      this.news_date = this.dashboard_details.news_date;
      this.news_description = this.dashboard_details.news_description;
    });
  }


}
