import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-htamtracking',
  templateUrl: './htamtracking.component.html',
  styleUrls: ['./htamtracking.component.scss']
})
export class HtamtrackingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
