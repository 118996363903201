import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HtamComponent } from './htam.component';

import { EmailhtamComponent } from './emailhtam/emailhtam.component';
import { BannerhtamComponent } from './bannerhtam/bannerhtam.component';
import { LinkhtamComponent } from './linkhtam/linkhtam.component';

import { HtamtrackingComponent } from './htamtracking/htamtracking.component';

import { HtampixelComponent } from './htampixel/htampixel.component';

const routes: Routes = [
 /*  {
    path: '', component: HtamComponent, children:
      [
        { path: 'htam', component: HtamComponent },
     { path: 'banner', component: BannerhtamComponent },
        { path: 'emailswipe', component: EmailhtamComponent },
       { path: 'tracking', component: HtamtrackingComponent },
        { path: 'pixelcode', component: HtampixelComponent },
        { path: 'link', component: LinkhtamComponent },
      ]
  }, */
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HtamRoutingModule { }
