import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService, AlertService } from '../../../_service';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Loading } from '../../../loader/Loading';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'coach-video',
  templateUrl: './coachVideo.component.html',
  styleUrls: ['./coachVideo.component.scss']
})

export class CoachVideo implements OnInit {
  comment: string = '';
  list: any;
  parentMessage: any;
  active_step_id: any;
  active_module_id: any;
  active_course_id: any;
  module_id: any;
  course_id: any;
  modules_list: any;
  subCategories: Array<Number> = [];
  videoUrl: SafeResourceUrl; //video url
  userComments: any;
  child_category: any;
  id: any; //step id
  step_index_id: any;
  module1: any;
  step_index_id1: any;
  step_index_id2: any;
  subCategories1: Array<Number> = [];
  subCategories2: Array<Number> = [];
  subCategories_module: Array<Number> = [];
  step1: Array<Number> = [];
  listing: any; //show all categories an sub categories
  step_id: any; //push step ids
  index_value: any;
  parent: any;
  moduleindex_value: any;
  module_update_id: any;
  title: any;
  description: any;
  text_description: any;
  lastindexvalue: any;
  index_value_inc: any;
  index_value_dec: any;
  list_module: any;
  parent1: any;
  parent2: any;
  parent3: any;
  complete: any;
  markascompletedata: Array<string> = [];
  moduleSteps_data: Array<string> = [];
  moduleSteps: Array<string> = [];
  moduleSteps_new: Array<string> = [];
  current_id: any;
  next_id: any;
  numberValue_nextid: any;
  numberValue_currentid: any;
  course_title: any;
  isLoading: boolean = true;
  status_details: any;
  status_list: any;
  url: any;
  overview_title: any;

  overview_url: any;
  overview_title_data: any;
  overview_description_data: any;
  overview_id: any;
  coach_id: any;
  coach_details: any;
  dat: any;
  indexArrayData: any = [];
  training_data: any;
  training_list: any;
  parents: any;
  coach_title: any;
  coach_video: any;
  novid: boolean = false;
  constructor(private route: ActivatedRoute, private router: Router, private UserServiceService: UserServiceService, private sanitizer: DomSanitizer, private alertService: AlertService, private toastrService: ToastrService) {
  }
  ngOnInit() {
    let user_info: any = localStorage.getItem('user_info');
    let user_id = JSON.parse(user_info);
    let from_user_id = user_id.data.id;

    this.getCompletedSteps();
    this.route.params.subscribe(params => {
      let id: any;
      let rlocation = location.hash.split('#', -1)
      let redirecturl = rlocation[1]

      if (redirecturl === '/dashboard/coach') {
        id = 4;
      }
      else {
        id = params['id'];
      }

      this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {

        this.coach_id = data.data.id

        if (data.status_code == 200) {
          this.coach_details = data.data;
          this.dat = {
            coach_id: this.coach_id
          }
          this.getDataTraining()
        }
      });
    });
  }

  getDataTraining() {
    this.UserServiceService.getTrainingData(this.dat).pipe(first()).subscribe((data: any) => {

      this.status_details = data.status_code;

      if (this.status_details == 200) {
        //alert(this.videoUrl);
        this.list = data.data;
        this.list_module = this.list.sub_categories;

        this.step1 = [];
        this.module1 = [];
        this.parent = [];
        this.subCategories2 = [];
        for (let item of this.list) {
          this.parent.push(item.id);
          this.parent.push(item.title);
          this.parent.push(item.overview);
          this.parent.push(item.overview_title);
          this.parent.push(item.overview_description);
          this.parent.push(item.overview_url);

          var index = 0;
          for (let module1 of item.sub_categories) {
            this.module1.push(module1.cotegouries);
            for (let step of module1.child_category) {
              index += 1;
              this.indexArrayData.push(index);

              this.step1.push(step);
              this.moduleSteps.push(`${module1.cotegouries}-${step.id}-${item.id}`);

              this.moduleSteps_new.push(`${module1.cotegouries}-${step.id}-${item.id}-${step.productId}`);

            }
          }
        }

        const result_step = this.step1;
        result_step.map((item: any) => {
          this.subCategories2.push(item.id);

        });

        const result_step1 = this.module1;
        result_step1.map((item: any) => {
          this.subCategories_module.push(item);
        });

        this.active_step_id = this.subCategories2[0];
        this.modules_list = this.subCategories_module;
        this.module_id = this.module1[0];
        this.course_id = this.parent[0];
        this.course_title = this.parent[1];
        this.overview_title = this.parent[2];
        this.index_value = this.subCategories2.indexOf(this.subCategories2[0]);

        this.UserServiceService.getTrainingData(this.dat).pipe(first()).subscribe((data: any) => {

          Loading.hide();
          if (data.data[0].sub_categories[0].child_category == '') {
            this.novid = true;
          }
          else {
            this.status_list = data.status_code;


            // this.show_overview(id);

            this.parentMessage = data.data;


            this.url = this.parentMessage[0].sub_categories[0].child_category[0].video;
            this.title = this.parentMessage[0].sub_categories[0].child_category[0].title;
            this.description = this.parentMessage[0].sub_categories[0].child_category[0].description;
            //this.text_description = this.description.replace(/<[^>]*>/g, '');


            this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.url);


            /*   if(this.videoUrl!==undefined)
              {
              
              } */
            this.userComments = data.data.users_comments;
            this.active_step_id = this.subCategories2[0];
            this.module_id = this.module1[0];
            //console.log('Curerent Module id', this.module_id);
            this.course_id = this.parent[0];
            //console.log('Current Course id', this.course_id);
            this.index_value = this.subCategories2.indexOf(this.subCategories2[0]);
            this.overview_id = '';
            //console.log('index value test', this.index_value);
            this.lastindexvalue = this.subCategories2.length - 1;
            //console.log('Last index value of current course ', this.lastindexvalue);


          }


        });
      }
    });
  }

  show_list(step_id, course_id, module_id) {

    // window.scroll({
    //   top: 10,
    //   left: 10,
    //   behavior: 'smooth'
    // });
    /* console.log('Step id', step_id);
    console.log('module_id', module_id);
    console.log('course_id', course_id);
   */
    let videodata = {
      coach_id: this.coach_id,
      id: step_id,


    }
    this.UserServiceService.apiTrainigVideo(videodata).pipe(first()).subscribe((data: any) => {

      Loading.hide();
      this.parentMessage = data.data[0];
      this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.video);
      this.title = this.parentMessage.title;

      this.description = this.parentMessage.description;
      //this.text_description = this.description.replace(/<[^>]*>/g, '');
      this.userComments = data.data.users_comments;
      this.active_step_id = step_id;
      this.active_module_id = module_id;
      this.active_course_id = course_id;
      this.module_id = module_id;
      this.course_id = course_id;
      this.overview_id = '';

      //this.index_value = this.subCategories2.indexOf(step_id);

      this.index_value = this.moduleSteps.indexOf(module_id + "-" + step_id + "-" + course_id);

    });
  }

  addcomment(comment, active_step_id, module_id, course_id) {
    this.UserServiceService.add_comment_product({ 'subcategory_id': active_step_id, 'category_id': module_id, 'parents_category': course_id, comments_description: comment }).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        Loading.hide();
        /*   this.alertService.success(data.message); */
        this.toastrService.success('Thanks, Your Comment Has Been Submitted And It Is On It’s Way For Approval'
        );
        this.UserServiceService.get_childcategory_product({ 'subcategory_id': active_step_id, 'course_id': course_id, 'module_id': module_id }).pipe(first()).subscribe((data: any) => {
          Loading.hide();
          this.userComments = data.data.users_comments;
          this.comment = '';
        });
      }
      else {
        Loading.hide();
        this.toastrService.error(data.message);
      }
    });
  }

  //Call mark as complete api
  completestatus(active_step_id, module_id, course_id, index_value, inc_index_value) {

    let str1_current = this.moduleSteps_new[index_value];
    let module_current_idss = str1_current.split("-", 4);
    this.current_id = module_current_idss[3];


    this.lastindexvalue = this.moduleSteps_new.length - 1;

    let str1 = this.moduleSteps_new[inc_index_value];

    if (str1 != undefined) {
      let module_idss = str1.split("-", 4);
      this.next_id = module_idss[3];
      this.numberValue_nextid = Number(this.next_id);
    }
    else {
      this.numberValue_nextid = '';
      //console.log('id next',this.numberValue_nextid); 
    }
    //return false;
    /*  console.log('current_id', this.current_id);
     console.log('next_id', this.next_id); */

    this.numberValue_currentid = Number(this.current_id);

    //return false;
    this.UserServiceService.mark_as_complete_product({
      'subcategory_id': active_step_id, 'category_id': module_id, 'parents_category': course_id, 'next_id': this.numberValue_nextid,
      'current_id': this.numberValue_currentid
    }).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.UserServiceService.get_complete_status_product().pipe(first()).subscribe((data: any) => {
          //console.log('All product data',data);
          // return false; 
          if (data.status_code == 200) {
            this.parent1 = [];
            this.parent2 = [];
            this.parent3 = [];
            this.markascompletedata = [];
            for (let item of data.data) {
              this.parent1.push(item.parents_category);
              this.parent2.push(item.category_id);
              this.parent3.push(item.subcategory_id);
              this.markascompletedata.push(`${item.parents_category}-${item.category_id}-${item.subcategory_id}`);
              this.moduleSteps_data.push(`${item.category_id}-${item.subcategory_id}-${item.parents_category}`);

            }
            // this.c_module_id1 = this.moduleSteps_data;
            //console.log('Data of completed', this.c_module_id1);

          }

        });


        this.alertService.success("Step completed");
        this.subCategories.push(active_step_id);
        this.active_step_id = active_step_id;
      }
      else {
        this.alertService.error("Complete previous step");
      }
    });
  }

  getCompletedSteps() {
    //function call to get all complete steps api
    this.isLoading = true;
    Loading.show();
    this.UserServiceService.get_complete_status_product().pipe(first()).subscribe((data: any) => {
      Loading.hide();
      this.isLoading = false;
      if (data.status_code == 200) {
        /*  this.subCategories = [];
         const result = data.data;
         result.map((item) => {
           this.subCategories.push(item.subcategory_id);
         }); */
        this.markascompletedata = [];
        const result = data.data;
        result.map((item) => {
          this.markascompletedata.push(`${item.parents_category}-${item.category_id}-${item.subcategory_id}`);
          // this.subCategories1.push(item.subcategory_id);
          //this.subCategories_module.push(item.category_id);
          //this.subCategories_course.push(item.parents_category);

        });

      }
      //console.log("SubCategory data", this.subCategories);
    });
  }



  addindex(index_value, id, course_id, module_id) {
    //console.log('Test of data', $event);
    this.index_value_inc = this.moduleSteps.indexOf(module_id + "-" + id + "-" + course_id);


    this.index_value = this.index_value_inc + 1;
    let str = this.moduleSteps[this.index_value];

    let module_ids = str.split("-", 3);

    this.module_update_id = module_ids[0];
    this.course_id = module_ids[2];
    //return false;
    this.active_step_id = this.subCategories2[index_value];
    let step_index_id = this.subCategories2[index_value];
    this.UserServiceService.get_childcategory_product({
      'subcategory_id': step_index_id,
      'course_id': this.course_id, 'module_id': this.module_update_id
    }).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      // console.log(' current index Steps data', this.subCategories2.indexOf($event.step_id));

      this.parentMessage = data.data.video_description;
      this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
      this.title = this.parentMessage.title;

      this.description = this.parentMessage.description;
      //this.text_description = this.description.replace(/<[^>]*>/g, '');
      //console.log('plain text', this.text_description);
      this.userComments = data.data.users_comments;

      /* this.parentMessage = data.data.video_description;
      this.videoUrl =
        this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
      this.userComments = data.data.users_comments; */
      //step id as id
      /* this.id = this.subCategories2[$event.index];
      this.step_index_id2 = $event.step_id;
      this.module_id = $event.module_id;
      this.course_id = $event.course_id;
      this.subCategories = this.subCategories1; */

      this.module_id = module_ids[0];
      this.course_id = module_ids[2];

      this.id = module_ids[1];

      this.active_step_id = module_ids[1];
      this.active_module_id = module_ids[0];
      this.active_course_id = module_ids[2];
      let step_index_id = this.subCategories2[index_value];


    });
  }


  removeindex(index_value, id, course_id, module_id) {


    // return false;


    this.index_value_dec = this.moduleSteps.indexOf(module_id + "-" + id + "-" + course_id);

    //return false;

    this.index_value = this.index_value_dec - 1;

    //console.log('Index value', this.index_value_inc + 1);
    let str = this.moduleSteps[this.index_value];

    //console.log('test of all steps', str);

    let module_ids = str.split("-", 3);
    //console.log('All ids check', module_ids);

    //console.log(module_ids[1]);

    this.module_update_id = module_ids[0];
    this.course_id = module_ids[2];


    this.active_step_id = this.subCategories2[index_value];
    let step_index_id = this.subCategories2[index_value];

    //  console.log('Module id', this.module_update_id);
    //console.log('Step id', step_index_id);
    let videodata = {
      coach_id: this.coach_id,
      // id: step_id

    }
    this.UserServiceService.apiTrainigVideo(videodata).pipe(first()).subscribe((data: any) => {
      Loading.hide();
      //console.log(' current index Steps data', this.subCategories2.indexOf($event.step_id));

      this.parentMessage = data.data.video_description;
      this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
      this.title = this.parentMessage.title;

      this.description = this.parentMessage.description;
      this.text_description = this.description.replace(/<[^>]*>/g, '');
      this.userComments = data.data.users_comments;

      /*   this.parentMessage = data.data.video_description;
        this.videoUrl =
          this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
        this.userComments = data.data.users_comments; */

      this.module_id = module_ids[0];
      this.course_id = module_ids[2];

      this.id = module_ids[1];

      this.active_step_id = module_ids[1];
      this.active_module_id = module_ids[0];
      this.active_course_id = module_ids[2];
      let step_index_id = this.subCategories2[index_value];
    });

  }


  completestatuscheck(parantcategory, category, chiledcategory) {
    // console.log('Test of function of completed');
    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;
    let indexReturn = this.markascompletedata.indexOf(indexArray);
    if (indexReturn >= 0) {
      return false;
    } else {
      return true;
    }
  }

  completestatuscheck_button(parantcategory, category, chiledcategory) {
    // console.log('Test of function of completed in mark as compplete');
    let indexArray = `${parantcategory}-${category}-${chiledcategory}`;
    let indexReturn = this.markascompletedata.indexOf(indexArray);
    if (indexReturn >= 0) {
      return true;
    } else {
      return false;
    }
  }

  // show_overview(id) {

  //   this.UserServiceService.get_products_details({ course_id: id }).pipe(first()).subscribe((data: any) => {
  //     if (data.status_code == 200) {
  //       // this.overview_data = data.data;
  //       this.overview_description_data = data.data[0].overview_description;
  //       this.overview_title_data = data.data[0].overview_title;
  //       //  console.log('title', this.overview_title);

  //       this.overview_id = data.data[0].id;
  //       this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(data.data[0].overview_url);

  //     }
  //     else {
  //       this.overview_id = '';
  //     }

  //   });


  // }


  menuOpen() {
    var moduleMenu = document.getElementById("advertise-right-nav-next-open");
    var moduleContainer = document.getElementById("module-container2");
    if (moduleMenu.classList.contains("collapsed")) {
      moduleMenu.classList.remove("collapsed");
      moduleMenu.classList.add("collapse");
      moduleContainer.classList.add("expanded");
    } else {
      moduleMenu.classList.add("collapsed");
      moduleMenu.classList.remove("collapse");
      moduleContainer.classList.remove("expanded");
    }
  }
}