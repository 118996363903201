import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  data: string;
  snapshotParam: string;
  subscribedParam: string;
  constructor(private readonly route: ActivatedRoute,
    private readonly router: Router) { }

  ngOnInit() {
    this.snapshotParam = this.route.snapshot.paramMap.get("partner");
    // Subscribed
    this.route.paramMap.subscribe(params => {
      this.subscribedParam = params.get("partner");
    });


  }
}
