import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { UserServiceService, AlertService } from '../../_service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Loading } from '../../loader/Loading';
import { ToastrService } from 'ngx-toastr';
import { empty } from 'rxjs';
import { ModalService } from '../../_modal';
import { SharedService } from 'src/app/_service/shared.service';
import { ScheduleDialogComponent } from './schedule-dialog/schedule-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.scss']
})
export class ClassComponent implements OnInit {
  child_category: any;
  parentMessage: any;
  userComments: any;
  id: any; //step id
  active_module_id: any;
  step_index_id: any;
  module1: any;
  module_steps: any;
  step_index_id1: any;
  step_index_id2: any;
  module_id: any;
  course_id: any;
  videoUrl: SafeResourceUrl; //video url
  subCategories1: Array<Number> = [];
  subCategories: Array<Number> = [];
  subCategories2: Array<Number> = [];
  step1: Array<Number> = [];
  step2: Array<Number> = [];
  listing: Array<any> = []; //show all categories an sub categories
  step_id: any; //push step ids
  index_value: any;
  active_step_id: any; //Current active id
  active_course_id: any;
  parent: any;
  parent1: any;
  parent2: any;
  parent3: any;
  model_id: string;
  overview_data: Array<string> = [];
  moduleindex_value: any;
  moduleindex_value1: any;
  module_update_id: any;
  lastindexvalue: any;
  moduleSteps: Array<string> = [];
  update_course_id: any;
  lastindexvalue1: any;
  lastindexvalue2: any;
  active_module_id1: any;
  subCategories_module: any;
  subCategories_course: any;
  index_value_inc: any;
  index_value_dec: any;
  c_step_id: any;
  c_course_id: any;
  c_module_id: any;
  c_module_id1: Array<string> = [];
  compeleteindex: any;
  compeleteindex_array: any;
  moduleSteps_data: Array<string> = [];
  moduleSteps_update: Array<string> = [];
  markascompletedata: Array<string> = [];
  assign_module: Array<string> = [];
  numberValue: any;
  title: any;
  description: any;
  text_description: any;
  module_msg: any;
  moduleSteps_new: Array<string> = [];
  content_id: any;
  current_id: any;
  next_id: any;
  numberValue_nextid: any;
  numberValue_currentid: any;
  last_index_value: any;
  inc_value: any;
  description_overview: any;
  title_overview: any;
  overview_id: any;
  videoUrl_overview: SafeResourceUrl; //video url
  isLoading: boolean = true;
  over_id: any = 1;
  dbdstepActiveMarkAs: any = 1;
  dbCatgoryName: string;
  comment1: any;
  questions: any;
  courseName: any;
  completeLessionPer: any;
  is_completed: any;
  trainerInfoGet: any;
  trainerEmail: any;
  assignment: any;
  tranierImage: any;
  classSchedule: any;
  classStartDate: any;
  classEndDate: any;
  constructor(
    private UserServiceService: UserServiceService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private toastrService: ToastrService,
    private sharedService: SharedService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    //first load all completed steps
    this.route.params.subscribe(params => {
      this.dbCatgoryName = params['id'];
    })

    this.getCompletedSteps();
    this.trainerInfo();
  }
  get_index($event) {
    this.index_value = $event.index;
  }

  scheduleDialog() {
    const dialogRef = this.dialog.open(ScheduleDialogComponent, {
      data: this.classSchedule
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.yesSubmit == 'YesSubmit') {
      }
    });
  }

  getstepnextactive($event) {
    if ($event.course_id == 1) {
      this.index_value = $event.current_index_value;
      this.active_step_id = $event.id;
      this.active_course_id = $event.course_id;
      this.active_module_id = $event.module_id;
      this.moduleindex_value = $event.moduleindex_value;
      this.UserServiceService.get_childcategory({
        'subcategory_id': $event.id, 'course_id': $event.course_id, 'module_id': $event.module_id
      }).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;

        this.parentMessage = data.data.video_description;
        this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);

        this.userComments = data.data.users_comments;
        this.title = data.data.video_description.title;
        this.overview_id = '';
        this.questions = data.data.video_description;
        //return false;
        this.description = data.data.video_description.description;
        this.text_description = this.description.replace(/<[^>]*>/g, '');
        //console.log('plain text', this.text_description);
      });
    } else {
      this.index_value = 0;
      this.active_step_id = $event.id;
      this.active_course_id = $event.course_id;
      this.active_module_id = $event.module_id;
      this.moduleindex_value = -1;
      this.UserServiceService.get_childcategory({
        'subcategory_id': $event.id, 'course_id': $event.course_id, 'module_id': $event.module_id
      }).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        //return false;


        this.parentMessage = data.data.video_description;
        this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);

        this.userComments = data.data.users_comments;
        this.title = data.data.video_description.title;
        this.questions = data.data.video_description;
        this.overview_id = '';

        //return false;
        this.description = data.data.video_description.description;
        this.text_description = this.description.replace(/<[^>]*>/g, '');
        //console.log('plain text', this.text_description);

      });
    }

  }

  recieve_overview($event) {

    this.UserServiceService.get_overview_details({ id: $event.overview_id }).pipe(first()).subscribe((data: any) => {
      console.log('111111111111111111111111', data);

      // if (data.data == '') {
      if (data.status_code == 200) {
        this.UserServiceService.sendOverviewData(this.overview_data);
        this.overview_data = data.data;
        this.description_overview = data.data.description;
        this.title_overview = data.data.title;
        this.overview_id = data.data.id;
        this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(data.data.overview_url);
        this.active_step_id = '';
        return false;

      }
      else {
        this.overview_id = '';
      }
      // }
      // else {
      //   this.overview_id = '';
      // }
    });


  }
  recievqueTestShow($event) {
    this.sharedService.setAssignment($event);
    this.assignment = $event
  }
  //Function call to get content and comment api
  recievemessage($event) {
    console.log('222222222222222222', $event);
    this.sharedService.setAssignment($event);

    this.index_value = this.moduleSteps.indexOf($event.module_id + "-" + $event.step_id + "-" + $event.course_id);

    let current_index_value = this.index_value;
    if (this.index_value > 0) {
      current_index_value = this.index_value - 1
    }
    this.UserServiceService.check_as_complete({
      'subcategory_id': $event.active_id,
      'category_id': $event.module_id,
      'parents_category': $event.course_id,
      'next_id': $event.next_id,
      'current_id': $event.current_id,
      "currentIndex": current_index_value
    }).pipe(first()).subscribe((data: any) => {

      if (data.status_code == 200 && $event.course_id == 1) {
        this.showContent($event);
      }
      else if ($event.course_id == 1) {
        this.toastrService.error('Complete previous step');
      }
      else {
        this.showContent($event);
      }
    });
  }

  showContent($event) {
    window.scroll({
      top: 10,
      left: 10,
      behavior: 'smooth'
    });





    this.dbdstepActiveMarkAs = $event.course_id;
    /* console.log($event.course_id);
    console.log($event.module_id);
    console.log('All data of assign module', this.assign_module);
  */
    let str = this.moduleSteps.indexOf($event.module_id + "-" + $event.step_id + "-" + $event.course_id);

    let str1 = this.moduleSteps[str];

    let module_ids = str1.split("-", 3);
    this.module_update_id = module_ids[0];
    this.active_step_id = $event.active_id;
    this.active_course_id = $event.course_id;
    this.active_module_id = $event.module_id
    // let current_index_value = this.index_value - 1;

    //find index value by step id
    this.index_value = this.moduleSteps.indexOf($event.module_id + "-" + $event.step_id + "-" + $event.course_id);

    //console.log(this.index_value);


    //check couseid
    if ($event.course_id == 1) {
      //console.log('test of course id 1 dbs');
      let indexReturn = this.assign_module.indexOf($event.module_id);
      //console.log(indexReturn);
      if (indexReturn == -1) {

        this.overview_id = '';
        this.module_msg = 'Error';
        this.model_id = 'custom-modal-1';
        //this.model_id =  $event.step_id + "" + $event.module_id + "" + $event.course_id;
        //alert(this.model_id);
        this.modalService.open(this.model_id);
        this.id = $event.step_id;
        this.module_id = $event.module_id;
        this.course_id = $event.course_id;
      }
      else {

        // return;
        this.module_msg = 'noError';
        //  console.log('Show all steps of data');
        this.UserServiceService.get_childcategory({
          'subcategory_id': $event.step_id, 'course_id': $event.course_id, 'module_id': $event.module_id
        }).pipe(first()).subscribe((data: any) => {
          Loading.hide();

          //return false;

          this.parentMessage = data.data.video_description;
          this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);

          this.userComments = data.data.users_comments;
          this.title = data.data.video_description.title;
          this.overview_id = '';

          //return false;
          this.description = data.data.video_description.description;
          this.text_description = this.description.replace(/<[^>]*>/g, '');
          //console.log('plain text', this.text_description);

          //Id as step id
          this.id = $event.step_id;
          this.module_id = $event.module_id;
          this.course_id = $event.course_id;
          this.subCategories = this.subCategories1;
        });
      }

    }
    else {
      this.module_msg = 'notError';

      this.UserServiceService.get_childcategory({
        'subcategory_id': $event.step_id, 'course_id': $event.course_id, 'module_id': $event.module_id
      }).pipe(first()).subscribe((data: any) => {

        Loading.hide();


        this.parentMessage = data.data.video_description;
        this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);

        this.userComments = data.data.users_comments;

        this.title = data.data.video_description.title;
        this.comment1 = data.data.video_description.comment;
        this.questions = data.data.video_description;
        this.assignment = null;
        this.is_completed = data.data.video_description.is_completed;


        //return false;
        this.description = data.data.video_description.description;


        this.overview_id = '';

        this.text_description = this.description.replace(/<[^>]*>/g, '');
        //console.log('plain text', this.text_description);

        //Id as step id
        this.id = $event.step_id;
        this.module_id = $event.module_id;
        this.course_id = $event.course_id;
        this.subCategories = this.subCategories1;

      });
    }
  }
  //function  call mark as complete api
  completestatus($event) {


    console.log('All event', $event);
    //return false;

    // console.log('All array data', this.moduleSteps_new);




    let str1_current = this.moduleSteps_new[$event.current_index_value];

    let module_current_idss = str1_current.split("-", 4);
    this.current_id = module_current_idss[3];

    this.lastindexvalue = this.moduleSteps_new.length - 1;

    //return false;


    let str1 = this.moduleSteps_new[$event.index];

    if (str1 != undefined) {
      let module_idss = str1.split("-", 4);
      this.next_id = module_idss[3];
      this.numberValue_nextid = Number(this.next_id);
    }
    else {
      this.numberValue_nextid = '';
      //console.log('id next',this.numberValue_nextid);
    }

    /*  console.log('current_id', this.current_id);
     console.log('next_id', this.next_id); */

    this.numberValue_currentid = Number(this.current_id);

    this.UserServiceService.mark_as_complete({
      'subcategory_id': $event.step_id, 'category_id': $event.module_id,
      'parents_category': $event.course_id,
      'next_id': this.numberValue_nextid, 'current_id': this.numberValue_currentid,
      "currentIndex": $event.current_index_value
    }).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        //this.alertService.success("Step completed");
        this.toastrService.success(data.message);
        //function call to get all complete steps api
        this.UserServiceService.get_complete_status().pipe(first()).subscribe((data: any) => {

          if (data.status_code == 200) {
            this.parent1 = [];
            this.parent2 = [];
            this.parent3 = [];
            this.markascompletedata = [];
            for (let item of data.data) {

              this.parent1.push(item.parents_category);
              this.parent2.push(item.category_id);
              this.parent3.push(item.subcategory_id);
              this.markascompletedata.push(`${item.parents_category}-${item.category_id}-${item.subcategory_id}`);
              this.moduleSteps_data.push(`${item.category_id}-${item.subcategory_id}-${item.parents_category}`);

            }

            this.c_module_id1 = this.moduleSteps_data;


          }

          this.UserServiceService.get_childcategory({
            'subcategory_id': $event.step_id, 'course_id': $event.course_id, 'module_id': $event.module_id
          }).pipe(first()).subscribe((data: any) => {

            Loading.hide();


            this.id = $event.step_id;
            this.module_id = $event.module_id;

            this.moduleindex_value = this.module1.indexOf(this.module_id);
            this.course_id = $event.course_id;

            this.subCategories = this.subCategories1;

            this.parentMessage = data.data.video_description;
            this.videoUrl =
              this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
            this.userComments = data.data.users_comments;
            this.title = data.data.video_description.title;
            this.comment1 = data.data.video_description.comment;
            this.questions = data.data.video_description;
            this.assignment = null;
            this.is_completed = data.data.video_description.is_completed;
            this.description = data.data.video_description.description;
            this.text_description = this.description.replace(/<[^>]*>/g, '');

            this.overview_id = '';


            // let str_new = this.moduleSteps_update.indexOf(last_steps);

            // this.index_value = str_new + 1;

            // this.active_step_id = module_updated_ids[1];
            // this.active_course_id = module_updated_ids[2];
            // this.active_module_id = module_updated_ids[0];
            //this.lastindexvalue = this.subCategories2.length - 1;
            //console.log('Last index value of step', this.lastindexvalue);

          });

        });

      }
      else {
        this.toastrService.error('Complete previous step');
      }
    });
  }


  getCompletedSteps() {
    //function call to get all complete steps api
    Loading.show();
    this.UserServiceService.get_complete_status().pipe(first()).subscribe((data: any) => {

      if (data.status_code == 200 && this.dbCatgoryName === 'DBS') {
        //get all completed steps
        this.compeleteindex = [];
        this.subCategories1 = [];
        this.subCategories_module = [];
        this.subCategories_course = [];
        this.markascompletedata = [];
        const result = data.data;

        result.map((item) => {
          this.markascompletedata.push(`${item.parents_category}-${item.category_id}-${item.subcategory_id}`);
          this.subCategories1.push(item.subcategory_id);
          this.subCategories_module.push(item.category_id);
          this.subCategories_course.push(item.parents_category);
        });

      }
      else {

      }
      this.getAllSteps();





      /* this.c_module_id=(this.subCategories1)




      this.c_module_id = this.moduleSteps;
      console.log(this.c_module_id);
 */
    });
  }
  trainerInfo() {
    let keySend = {
      'course_id': this.dbCatgoryName
    }
    this.UserServiceService.get_trainer(keySend).pipe(first()).subscribe((data: any) => {
      if (data.status_code === 200) {
        this.trainerInfoGet = data.data.trainer_name
        this.trainerEmail = data.data.email
        this.tranierImage = data.data.image
      }
    });
  }

  //function to call to get all categories and subcategotries
  getAllSteps() {
    let keySend = {
      learning_type: 'Classes',
    }
    this.UserServiceService.get_category(keySend).pipe(first()).subscribe((data: any) => {


      //return false;
      if (data.status_code !== 200) {

        this.router.navigate(['/dashboard/course']);
      }

      //Loading.hide();
      this.dbdstepActiveMarkAs = 1;
      // this.listing = data.data;

      // console.log("listing", this.listing);

      let id = this.route.snapshot.params.id;

      // data.data.map(name => {

      //   if ('Essential' == name.name) {
      //     this.listing[0] = data.data[1]

      //   }
      //   else {
      //     return
      //   }

      // })

      let categoryData = data.data

      for (let index = 0; index < categoryData.length; index++) {
        let names = categoryData[index].id;
        if (id == names) {
          this.listing[0] = categoryData[index];

        } else {

        }

      }
      this.courseName = this.listing[0].class_title;
      this.classStartDate = this.listing[0].class_start_date;
      this.classEndDate = this.listing[0].class_end_date;
      this.completeLessionPer = this.listing[0].complete_lession_per
      this.classSchedule = this.listing[0]

      this.step1 = [];
      this.step2 = [];
      this.module1 = [];
      this.parent = [];
      this.subCategories2 = [];//All step index data
      this.assign_module = [];

      for (let module_data of data.assignModuleData) {

        this.assign_module.push(module_data.moduleId);
      }

      for (let item of this.listing) {

        this.parent.push(item.id);
        for (let module1 of item.sub_categories) {
          this.module1.push(module1.cotegouries);
          for (let step of module1.child_category) {

            this.step1.push(step);
            //this.step2.push(step.contentid);
            this.moduleSteps.push(`${module1.cotegouries}-${step.id}-${item.id}`);
            this.moduleSteps_update.push(`${item.id}-${module1.cotegouries}-${step.id}`);

            this.moduleSteps_new.push(`${module1.cotegouries}-${step.id}-${item.id}-${step.contentid}`);

          }
        }
      }
      /*  console.log('Module with  step  id and parent id', this.moduleSteps)
       console.log('Module full data', this.module1);
       console.log('Module id data', this.module1[0]);
       console.log('Parent full data', this.parent[0]);
       console.log('Parent data', this.parent[0]);
       console.log('step data', this.step1[0]); */


      const result_step = this.step1;
      result_step.map((item: any) => {
        this.subCategories2.push(item.id);
      });


      if (this.subCategories1.length > 0) {


        //return false;
        this.last_index_value = this.markascompletedata.length - 1;


        //console.log('last completed data',
        let last_steps = this.markascompletedata[this.last_index_value];


        //return false;

        //return false;


        let str_new = this.moduleSteps_update.indexOf(last_steps);

        let str_newindex = this.moduleSteps.length - 1;


        if (str_new == str_newindex) {
          this.inc_value = str_new;
        }
        else {
          //my comment code
          //this.inc_value = str_new + 1;
          this.inc_value = str_new;
        }




        let value = this.moduleSteps[this.inc_value];

        let module_updated_ids = value.split("-", 3);

        this.course_id = module_updated_ids[2];
        this.module_id = module_updated_ids[0];
        if (this.course_id == 1) {

          //console.log(typeof this.module_id)

          this.numberValue = Number(this.module_id);


          let indexReturn = this.assign_module.indexOf(this.numberValue);
          // return false;

          if (indexReturn == -1) {
            this.module_msg = 'Error';
            //this.toastrService.error('This step is not available please contact admin.');
            this.model_id = 'custom-modal-1';
            this.modalService.open(this.model_id);

          }
          else {

            this.UserServiceService.get_childcategory({
              'subcategory_id': module_updated_ids[1], 'course_id': module_updated_ids[2], 'module_id': module_updated_ids[0]
            }).pipe(first()).subscribe((data: any) => {

              Loading.hide();


              this.id = module_updated_ids[1];
              this.module_id = module_updated_ids[0];

              this.moduleindex_value = this.module1.indexOf(this.module_id);
              this.course_id = module_updated_ids[2];

              this.subCategories = this.subCategories1;

              this.parentMessage = data.data.video_description;
              this.videoUrl =
                this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
              this.userComments = data.data.users_comments;
              this.title = data.data.video_description.title;
              this.comment1 = data.data.video_description.comment;
              this.questions = data.data.video_description;
              // this.assignment = null;
              this.is_completed = data.data.video_description.is_completed;
              this.description = data.data.video_description.description;
              this.text_description = this.description.replace(/<[^>]*>/g, '');


              let str_new = this.moduleSteps_update.indexOf(last_steps);

              this.index_value = str_new + 1;
              this.overview_id = '';

              this.active_step_id = module_updated_ids[1];
              this.active_course_id = module_updated_ids[2];
              this.active_module_id = module_updated_ids[0];
              //this.lastindexvalue = this.subCategories2.length - 1;
              //console.log('Last index value of step', this.lastindexvalue);

            });



          }
        }
        else {

          this.UserServiceService.get_childcategory({
            'subcategory_id': module_updated_ids[1], 'course_id': module_updated_ids[2], 'module_id': module_updated_ids[0]
          }).pipe(first()).subscribe((data: any) => {

            Loading.hide();


            this.id = module_updated_ids[1];
            this.module_id = module_updated_ids[0];

            this.moduleindex_value = this.module1.indexOf(this.module_id);
            this.course_id = module_updated_ids[2];

            this.subCategories = this.subCategories1;

            this.parentMessage = data.data.video_description;
            this.videoUrl =
              this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
            this.userComments = data.data.users_comments;
            this.title = data.data.video_description.title;
            this.comment1 = data.data.video_description.comment;
            this.questions = data.data.video_description;
            this.assignment = null;
            this.is_completed = data.data.video_description.is_completed;
            this.description = data.data.video_description.description;
            this.text_description = this.description.replace(/<[^>]*>/g, '');

            this.overview_id = '';


            let str_new = this.moduleSteps_update.indexOf(last_steps);

            this.index_value = str_new + 1;

            this.active_step_id = module_updated_ids[1];
            this.active_course_id = module_updated_ids[2];
            this.active_module_id = module_updated_ids[0];
            //this.lastindexvalue = this.subCategories2.length - 1;
            //console.log('Last index value of step', this.lastindexvalue);

          });

        }

      }

      else {

        this.numberValue = Number(this.module1[0]);
        let indexReturn = this.assign_module.indexOf(this.numberValue);

        // return false;

        if (this.over_id != '') {
          //console.log($event.overview_id);
          this.UserServiceService.get_overview_details({ id: this.parent[0] }).pipe(first()).subscribe((data: any) => {
            // if (data.data == '') {
            if (data.status_code == 200) {
              if (data.data == '') {
                this.overview_id = '';
                this.UserServiceService.get_childcategory({
                  'subcategory_id': this.subCategories2[0], 'course_id': this.parent[0], 'module_id': this.module1[0]
                }).pipe(first()).subscribe((data: any) => {

                  Loading.hide();

                  this.id = this.subCategories2[0];
                  this.module_id = this.module1[0];

                  this.moduleindex_value = this.module1.indexOf(this.module_id);
                  //First index Course id
                  this.course_id = this.parent[0];
                  this.subCategories = this.subCategories1;
                  this.parentMessage = data.data.video_description;
                  this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
                  this.userComments = data.data.users_comments;
                  this.title = data.data.video_description.title;
                  this.comment1 = data.data.video_description.comment;
                  this.questions = data.data.video_description;
                  this.assignment = null;
                  this.is_completed = data.data.video_description.is_completed;
                  this.description = data.data.video_description.description;

                  this.text_description = this.description.replace(/<[^>]*>/g, '');

                  this.overview_id = '';
                  this.index_value = this.subCategories2.indexOf(this.id);
                  this.index_value = this.subCategories2.indexOf(this.id);
                  this.lastindexvalue = this.subCategories2.length - 1;

                });

                this.questions = data.data.video_description;
                return
              }
              this.overview_data = data.data;
              this.index_value = 0;
              this.id = this.listing[0].sub_categories[0].child_category[0].id;
              this.course_id = this.listing[0].id;
              this.module_id = this.listing[0].sub_categories[0].cotegouries;
              this.moduleindex_value = -1;

              this.description_overview = data.data.description;
              this.title_overview = data.data.title;
              this.overview_id = data.data.id;
              this.videoUrl = this.sanitizer.bypassSecurityTrustHtml(data.data.overview_url);

              this.active_step_id = ''
              return false;

            }
            else {
              this.overview_id = '';
            }
            // }
            // else {
            //   this.overview_id = '';
            // }
          });

          /*  this.module_msg = 'Error';
           //this.toastrService.error('This step is not available please contact admin.');
           this.model_id = 'custom-modal-1';
           this.modalService.open(this.model_id); */
        }
        else {

          this.UserServiceService.get_childcategory({
            'subcategory_id': this.subCategories2[0], 'course_id': this.parent[0], 'module_id': this.module1[0]
          }).pipe(first()).subscribe((data: any) => {

            Loading.hide();

            //First index Step id
            this.id = this.subCategories2[0];
            //First index Module id
            this.module_id = this.module1[0];

            this.moduleindex_value = this.module1.indexOf(this.module_id);
            //First index Course id
            this.course_id = this.parent[0];
            this.subCategories = this.subCategories1;
            this.parentMessage = data.data.video_description;
            this.videoUrl =
              this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);



            this.userComments = data.data.users_comments;

            this.title = data.data.video_description.title;
            this.comment1 = data.data.video_description.comment;
            this.questions = data.data.video_description;
            this.assignment = null;
            this.is_completed = data.data.video_description.is_completed;
            //return false;
            this.description = data.data.video_description.description;

            this.text_description = this.description.replace(/<[^>]*>/g, '');

            this.overview_id = '';
            //console.log('plain text', this.text_description);
            /*console.log('Alllllll steps',this.subCategories2);
             console.log('current index Steps data', this.subCategories2.indexOf(this.id));*/
            this.index_value = this.subCategories2.indexOf(this.id);

            /*   console.log('Index value',this.index_value);


              console.log('current index Steps data', this.subCategories2.indexOf(this.id));
       */
            this.index_value = this.subCategories2.indexOf(this.id);
            this.lastindexvalue = this.subCategories2.length - 1;
            /*   console.log('Last index value of step', this.lastindexvalue); */

          });
        }
      }


      /* console.log("SubCategory data", this.subCategories1); */

      //  this.module_steps=this.moduleSteps;
      /*   this.c_module_id = this.moduleSteps;
            console.log(this.c_module_id); */
      /* this.UserServiceService.get_complete_status().pipe(first()).subscribe((data: any) => {
        if (data.status_code == 200) {
          //get all completed steps
          this.compeleteindex = [];
          this.subCategories1 = [];
          this.subCategories_module = [];
          this.subCategories_course = [];

          const result = data.data;
          result.map((item) => {
            this.subCategories1.push(item.subcategory_id);
            this.subCategories_module.push(item.category_id);
            this.subCategories_course.push(item.parents_category);

          });
        }

        this.compeleteindex_array = (this.subCategories_module + "-" + this.subCategories1 + "-" + this.subCategories_course)
        this.c_module_id = this.compeleteindex_array;



        console.log("SubCategory data", this.subCategories1);





      }); */
      Loading.hide();
      this.isLoading = false;
    });



  }

  //function to add to show comments section
  addcomment($event) {
    this.UserServiceService.add_comment({ 'subcategory_id': $event.step_id, 'category_id': $event.module_id, 'parents_category': $event.course_id, comments_description: $event.comment }).pipe(first()).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.toastrService.success('Thanks, Your Comment Has Been Submitted And It Is On It’s Way For Approval'
        );
        console.log();

        this.UserServiceService.get_childcategory({ 'subcategory_id': $event.step_id, 'course_id': $event.module_id, 'module_id': $event.module_id }).pipe(first()).subscribe((data: any) => {
          Loading.hide();
          this.userComments = data.data.users_comments;
          this.title = data.data.video_description.title;
          this.comment1 = data.data.video_description.comment;
          this.questions = data.data.video_description;
          this.assignment = null;
          this.is_completed = data.data.video_description.is_completed;
          /*    console.log('title', this.title); */
          //return false;
          this.description = data.data.video_description.description;

          this.text_description = this.description.replace(/<[^>]*>/g, '');
        });
      }
      else {
        this.toastrService.error(data.message);
      }
    });
  }

  //function to show next steps by index

  addindex($event) {



    /* console.log('All event', $event);
    console.log('All array data', this.moduleSteps_new);
    console.log('Current data', this.moduleSteps_new[$event.index]); */

    this.index_value_inc = this.moduleSteps.indexOf($event.module_id + "-" + $event.step_id + "-" + $event.course_id);

    this.index_value = this.index_value_inc + 1;


    let str = this.moduleSteps[this.index_value];

    //let content = this.moduleSteps_new[this.index_value];

    /*console.log('All content data',content);
    return false;
     */

    let module_ids = str.split("-", 3);

    /* console.log('All ids check', module_ids);
    console.log(module_ids[1]); */

    this.module_update_id = module_ids[0];
    this.course_id = module_ids[2];

    this.active_step_id = this.subCategories2[$event.index];
    let step_index_id = this.subCategories2[$event.index];

    if (this.course_id == 1) {

      this.numberValue = Number(this.module_update_id);
      let indexReturn = this.assign_module.indexOf(this.numberValue);

      if (indexReturn == -1) {
        this.overview_id = '';
        this.module_msg = 'Error';
        /*   this.toastrService.error('This step is not available please contact admin.'); */
        this.model_id = 'custom-modal-1';
        this.modalService.open(this.model_id);

        this.active_step_id = module_ids[1];
        this.active_module_id = module_ids[0];
        this.active_course_id = module_ids[2];


        this.module_id = module_ids[0];
        this.course_id = module_ids[2];
        this.id = module_ids[1];
      }
      else {
        this.module_msg = 'noError';
        this.UserServiceService.get_childcategory({ 'subcategory_id': step_index_id, 'course_id': this.course_id, 'module_id': this.module_update_id }).pipe(first()).subscribe((data: any) => {
          Loading.hide();


          this.parentMessage = data.data.video_description;
          this.videoUrl =
            this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);

          this.userComments = data.data.users_comments;

          this.title = data.data.video_description.title;
          this.comment1 = data.data.video_description.comment;
          this.questions = data.data.video_description;
          this.assignment = null;
          this.is_completed = data.data.video_description.is_completed;
          //return false;
          this.description = data.data.video_description.description;

          this.text_description = this.description.replace(/<[^>]*>/g, '');
          //console.log('plain text', this.text_description);


          this.module_id = module_ids[0];
          this.course_id = module_ids[2];

          this.id = module_ids[1];
          this.active_step_id = module_ids[1];
          this.active_module_id = module_ids[0];
          this.active_course_id = module_ids[2];
          let step_index_id = this.subCategories2[$event.index];


        });

      }

    }

    else {
      this.module_msg = 'notError';
      this.UserServiceService.get_childcategory({ 'subcategory_id': step_index_id, 'course_id': this.course_id, 'module_id': this.module_update_id }).pipe(first()).subscribe((data: any) => {
        Loading.hide();

        this.parentMessage = data.data.video_description;
        this.videoUrl =
          this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
        this.userComments = data.data.users_comments;

        this.title = data.data.video_description.title;
        this.comment1 = data.data.video_description.comment;
        this.questions = data.data.video_description;
        this.assignment = null;
        this.is_completed = data.data.video_description.is_completed;
        //return false;
        this.description = data.data.video_description.description;

        this.text_description = this.description.replace(/<[^>]*>/g, '');
        //console.log('plain text', this.text_description);


        this.module_id = module_ids[0];
        this.course_id = module_ids[2];

        this.id = module_ids[1];

        this.active_step_id = module_ids[1];
        this.active_module_id = module_ids[0];
        this.active_course_id = module_ids[2];
        let step_index_id = this.subCategories2[$event.index];


      });

    }

  }


  removeindex($event) {
    /*  console.log('Test of data', $event); */
    this.index_value_dec = this.moduleSteps.indexOf($event.module_id + "-" + $event.step_id + "-" + $event.course_id);

    /*  console.log('Index value', this.index_value_dec);
     console.log('index value dec check', this.index_value_dec - 1); */

    this.index_value = this.index_value_dec - 1;

    /*  console.log('Value', this.index_value);
     //console.log('Index value', this.index_value_inc + 1);
     console.log('All data', this.moduleSteps); */

    let str = this.moduleSteps[this.index_value];

    //console.log('test of all steps', str);

    let module_ids = str.split("-", 3);
    //console.log('All ids check', module_ids);

    //console.log(module_ids[1]);

    this.module_update_id = module_ids[0];

    this.course_id = module_ids[2];
    this.active_step_id = this.subCategories2[$event.index];
    let step_index_id = this.subCategories2[$event.index];


    if (this.course_id == 1) {
      /* console.log('test of course id 1 dbs');

      console.log('module idddd->', this.module_update_id);

      console.log(this.assign_module);

      console.log(typeof this.module_update_id) */

      this.numberValue = Number(this.module_update_id);

      let indexReturn = this.assign_module.indexOf(this.numberValue);


      if (indexReturn == -1) {
        this.overview_id = '';
        this.module_msg = 'Error';
        /* this.toastrService.error('This step is not available please contact admin.');
 */
        this.model_id = 'custom-modal-1';
        this.modalService.open(this.model_id);
        this.active_step_id = module_ids[1];
        this.active_module_id = module_ids[0];
        this.active_course_id = module_ids[2];

        this.module_id = module_ids[0];
        this.course_id = module_ids[2];
        this.id = module_ids[1];
      }
      else {
        this.module_msg = 'noError';
        this.UserServiceService.get_childcategory({ 'subcategory_id': step_index_id, 'course_id': this.course_id, 'module_id': this.module_update_id }).pipe(first()).subscribe((data: any) => {
          Loading.hide();


          this.parentMessage = data.data.video_description;
          this.videoUrl =
            this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
          this.userComments = data.data.users_comments;

          this.title = data.data.video_description.title;
          this.comment1 = data.data.video_description.comment;
          this.questions = data.data.video_description;
          this.assignment = null;
          this.is_completed = data.data.video_description.is_completed;
          //return false;
          this.description = data.data.video_description.description;

          this.text_description = this.description.replace(/<[^>]*>/g, '');
          //console.log('plain text', this.text_description);


          this.module_id = module_ids[0];
          this.course_id = module_ids[2];

          this.id = module_ids[1];
          this.active_step_id = module_ids[1];
          this.active_module_id = module_ids[0];
          this.active_course_id = module_ids[2];
          let step_index_id = this.subCategories2[$event.index];


        });

      }

    }

    else {
      this.module_msg = 'nottError';
      this.UserServiceService.get_childcategory({
        'subcategory_id': step_index_id, 'course_id': this.course_id, 'module_id': this.module_update_id
      }).pipe(first()).subscribe((data: any) => {
        Loading.hide();



        this.parentMessage = data.data.video_description;
        this.videoUrl =
          this.sanitizer.bypassSecurityTrustHtml(this.parentMessage.url);
        this.userComments = data.data.users_comments;

        this.title = data.data.video_description.title;
        this.assignment = null;
        this.comment1 = data.data.video_description.comment;
        this.questions = data.data.video_description;
        this.assignment = null;
        this.is_completed = data.data.video_description.is_completed;
        //return false;
        this.description = data.data.video_description.description;

        this.text_description = this.description.replace(/<[^>]*>/g, '');
        //console.log('plain text', this.text_description);

        this.module_id = module_ids[0];
        this.course_id = module_ids[2];

        this.id = module_ids[1];

        this.active_step_id = module_ids[1];
        this.active_module_id = module_ids[0];
        this.active_course_id = module_ids[2];
        let step_index_id = this.subCategories2[$event.index];

      });
    }

  }

  closeModal_event() {

  }
  menuOpen() {
    var moduleMenu = document.getElementById("menu-display-button-open");
    var moduleContainer = document.getElementById("module-container");
    if (moduleMenu.classList.contains("collapsed")) {
      moduleMenu.classList.remove("collapsed");
      moduleMenu.classList.add("collapse");
      moduleContainer.classList.add("expanded");
    } else {
      moduleMenu.classList.add("collapsed");
      moduleMenu.classList.remove("collapse");
      moduleContainer.classList.remove("expanded");
    }
  }
}
