import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buisness',
  templateUrl: './buisness.component.html',
  styleUrls: ['./buisness.component.scss']
})
export class BuisnessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
