import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { first } from 'rxjs/operators';
import { UserServiceService } from "../../../_service";
import { MatDialog } from "@angular/material";
import { NotesDescription } from "../NotesDescription/notes-description.component";

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})

export class NotesComponent implements OnInit {
  coach_details: any;
  to_uid: any;
  coach_id: any;
  dat: any;
  constructor(
    public UserServiceService: UserServiceService,
    private dialog: MatDialog,
  ) { }
  displayedColumns: string[] = ['No', 'Date', 'Time', 'Coach', 'Sessions', 'Status', 'action'];
  dataSource: any;
  ngOnInit() {
    let user_info: any = localStorage.getItem('user_info');
    let user_id = JSON.parse(user_info);
    let from_user_id = user_id.data.id;

    this.UserServiceService.coach_detail().pipe(first()).subscribe((data: any) => {
      this.coach_id = data.data.id
      if (data.status_code == 200) {
        this.coach_details = data.data.id;
        this.dat = {
          user_id: from_user_id,
          coach_id: this.coach_id
        }
        this.getCallData()
      }
    });

    // let coach_id = 3
  }

  getCallData() {
    this.UserServiceService.getAppointmentDetailsAll(this.dat).pipe(first()).subscribe((data: any) => {

      var getData = data.data;
      const Ar = [];
      for (let i = 0; i < getData.length; i++) {
        const element = getData[i];
        const newdt = new Date();
        const nee = new Date(element.scheduled_day);

/*        if (newdt <= nee) {
          const completed = {
            "appointment_id": getData[i].appointment_id,
            "first_name": getData[i].first_name,
            "from_user_id": getData[i].from_user_id,
            "last_name": getData[i].last_name,
            "name": getData[i].name,
            "scheduled_day": getData[i].scheduled_day,
            "timezone": getData[i].timezone,
            "to_user_id": getData[i].to_user_id,
            "type": getData[i].type,
            "notes": getData[i].notes,
            "status": "Completed"
          }
          Ar.push(completed)
        }
        else {
          const completed = {
            "appointment_id": getData[i].appointment_id,
            "first_name": getData[i].first_name,
            "from_user_id": getData[i].from_user_id,
            "last_name": getData[i].last_name,
            "name": getData[i].name,
            "scheduled_day": getData[i].scheduled_day,
            "timezone": getData[i].timezone,
            "to_user_id": getData[i].to_user_id,
            "type": getData[i].type,
            "notes": getData[i].notes,
            "status": "Scheduled"
          }
          Ar.push(completed)
        }*/
        if (element.status == 1) {
          const completed = {
            "appointment_id": getData[i].appointment_id,
            "first_name": getData[i].first_name,
            "from_user_id": getData[i].from_user_id,
            "last_name": getData[i].last_name,
            "name": getData[i].name,
            "scheduled_day": getData[i].scheduled_day,
            "timezone": getData[i].timezone,
            "to_user_id": getData[i].to_user_id,
            "type": getData[i].type,
            "notes": getData[i].notes,
            "status": "Completed"
          }
          Ar.push(completed)
        }
        else {
          const completed = {
            "appointment_id": getData[i].appointment_id,
            "first_name": getData[i].first_name,
            "from_user_id": getData[i].from_user_id,
            "last_name": getData[i].last_name,
            "name": getData[i].name,
            "scheduled_day": getData[i].scheduled_day,
            "timezone": getData[i].timezone,
            "to_user_id": getData[i].to_user_id,
            "type": getData[i].type,
            "notes": getData[i].notes,
            "status": "Scheduled"
          }
          Ar.push(completed)
        }
      }
      this.dataSource = new MatTableDataSource<PeriodicElement>(Ar);

    })
  }

  openDialog(data) {
    const dialogref = this.dialog.open(NotesDescription, {
      width: '40%',
      height: 'auto%',
      panelClass: 'myClass',

      data: data
    });
    dialogref.afterClosed().subscribe(result => {
      if (result == "YesSubmit") {
        // this.singleViewProjects();
      }
    });
  }
}

export interface PeriodicElement {
  Date: string;
  No: number;
  Time: number;
  Coach: string;
  Sessions: any;
  Status: any
  action: any;
}
