import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserServiceService } from '../_service';

@Injectable({
    providedIn: 'root'
})
export class FaviconService {

    constructor(private http: HttpClient, private UserServiceService: UserServiceService,) { }

    logoGet(): Observable<any> {
        const keySend = {
            host: window.location.href,
        };
        return this.UserServiceService.logoGet(keySend);
    }

    changeFavicon(faviconUrl: string): void {
        const link: HTMLLinkElement = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = faviconUrl;

        const existingFavicon = document.querySelector("link[rel='shortcut icon']");
        if (existingFavicon) {
            document.head.removeChild(existingFavicon);
        }
        document.head.appendChild(link);
    }
}
